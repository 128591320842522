export default {
  namespaced: true,
  state: {
    address: {} // 地址信息
  },
  mutations: {
    /**
     * 设置编辑地址
     * @param  {object} address 地址信息
     * @return {void}
     */
    setEditingAddress (state, address) {
      state.address = address
    }
  }
}
