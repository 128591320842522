/**
 * 全局性数据、需要缓存到本地的
 */
import { BRAND_NAME_MAP } from '@/constants/dead'

export default {
  namespaced: true,

  state: {
    brandId: '', // 所在品牌ID
    brandName: '',
  },

  mutations: {
    setBrandId (state, val) {
      state.brandId = val
      state.brandName = BRAND_NAME_MAP[val]
    }
  }
}
