import { isDef } from '@/utils/common.js'

export function format2Percent(value) {
  if (typeof value != "number") {
    return "--";
  }
  return (value * 100).toFixed(0) + "%";
}
const filters = {
  //将价格转化为小数
  percent: function(value) {
    if (typeof value != "number") {
      return "--";
    }
    return (value * 100).toFixed(0) + "%";
  },
  //格式化价钱
  formateMoney: function(nStr) {
    nStr += "";
    let x = nStr.split(".");
    let x1 = x[0];
    let x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "," + "$2");
    }
    return x1 + x2;
  },
  //将分转化为元
  fen2YuanWithDevide: function(nStr) {
    if (nStr === "--" || !isDef(nStr)) {
      return '--';
    }
    if (typeof nStr === "string" && Number.isNaN(Number(nStr)) || Number.isNaN(nStr)) {
      return "--";
    }
    nStr = nStr / 100;
    nStr += "";
    let x = nStr.split(".");
    let x1 = x[0];
    let x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "," + "$2");
    }
    return x1 + x2;
  },
};
export default (vm) => {
  Object.keys(filters).forEach((key) => {
    vm.filter(key, filters[key]);
  });
};
