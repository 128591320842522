import { Http, HttpV1 } from '@/request'
import Vue from 'vue'
import { getDiscountPrice } from '@/utils/common'
import { getProductLimitcount } from '@/service/lipGuoqingTemp'

function getProductPrice(product) {
    let min = Infinity, max = -Infinity
    for (const format of product.formats) {
        const userPrice = getDiscountPrice(product.discount, product.userRank, format.price)
        min = Math.min(min, userPrice)
        max = Math.max(max, userPrice)
    }

    const fen2Yuan = Vue.prototype.$fen2Yuan
    if (min === max) {
        return fen2Yuan(min)
    } else {
        return `${fen2Yuan(min)} ~ ${fen2Yuan(max)}`
    }
}

export default {
    namespaced: true,
    state: {
        products: [],
        limitProduct: {}
    },
    mutations: {
        setProducts(state, products) {
            state.products = products
        },
        setLimitProductCount(state, limitProduct) {
            state.limitProduct = limitProduct
        }
    },
    actions: {
        // 获取对应品牌的产品
        async getProducts({ commit }, { brandId, userId }) {
            if (!brandId) {
                commit('setProducts', [])
            }
            try {
                const products = await HttpV1.get('agent/product', { brandId, userId })
                for (const product of products) {
                    product.priceShown = getProductPrice(product)
                }
                commit('setProducts', products)
            } catch (e) {
                commit('setProducts', [])
            }
        },
        async getLimitProductCount({ commit }, { brandId, userId }) {
            try {
                const limitProduct = await getProductLimitcount({ brandId, userId })
                commit('setLimitProductCount', limitProduct)
            } catch (e) {
                commit('setLimitProductCount', {})
            }
        },
    }
}
