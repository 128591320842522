import { HttpV1, HttpV0 } from '@/request'

// 获取赠品数量
export async function getGiftCount ({ userId, count }) {
  return await HttpV1.get('/agent/sgs/gift/count', {
    userId,
    count
  })
}

// 获取赠品列表
export async function getGiftList ({ userId, brandId }) {
  return await HttpV0.get('/products/gift', {
    userId,
    brandId
  })
}

// 获取(可审核的)订单列表
export async function getOrders (brandId, state, offset, limit) {
  return await HttpV0.get(`/review`, {
    brandId,
    state,
    limit,
    offset,
  })
}

// 获取订单详情
export async function getOrderDetail (orderId) {
  return await HttpV0.get(`/orders/${orderId}`)
}

// 通过
export async function resolveOrders (orderIds) {
  return await HttpV0.put(`/review/approve`, {
    orderIds
  })
}

// 拒绝
export async function rejectOrders (orderIds) {
  return await HttpV0.put(`/review/reject`, {
    orderIds
  })
}

// 水光生预订单校验
export async function checkPreOrder (subOrders) {
  return await HttpV0.post(`/orders/sgs-check`, {
    subOrders
  })
}

// 能量内衣预订单校验
export async function checkUnderWearPreOrder (subOrders) {
  return await HttpV0.post(`/orders/underwear-check`, {
    subOrders
  })
}