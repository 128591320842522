<template>
  <div class="not-found">
    <p class="tip">当前所在位置：<span class="path">{{location}}</span></p>
    <p>页面尚在开发中哦～</p>
  </div>
</template>

<script>
export default {
  name: 'not-found',
  data() {
    return {
      location: null
    }
  },
  mounted() {
    this.location = document.location.href
  },
}
</script>

<style lang="less" scoped>
.not-found {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tip {
  margin-bottom: 8px;
}

.path {
  color: red;
}
</style>
