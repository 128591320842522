/**
 * 弹出式 Picker，一般用于单选
 */
export default {
  name: 'popup-picker',

  props: {
    title: {
      type: String,
      default: '请选择'
    }
  },

  data () {
    return {
      visible: false,
    }
  },

  methods: {
    // 打开
    show () {
      this.visible = true
    },

    // 关闭
    close () {
      this.visible = false
    },

    setColumnValue (...args) {
      this.$refs['picker'].setColumnValue(...args)
    },

  }
}
