import dayjs from 'dayjs'
import { POINT_OPERATE_TYPE } from '@/constants/common'
import { mapState } from 'vuex'

/**
 * 我的积分
 */
export default {
  name: 'user-point',

  data () {
    return {
      records: [], // 积分列表
      points: 0, // 总积分
      POINT_OPERATE_TYPE,
    }
  },

  computed: {
    ...mapState({
      brandId: state => state.global.brandId,
    })
  },

  methods: {
    dayjs,

    async init () {
      const { list, totalPoint } = await this.$store.dispatch('user/getUserPoint', { brandId: this.brandId })
      this.records = list
      this.points = totalPoint
    }
  },

  async created () {
    await this.init()
  }
}
