import { mapState } from 'vuex';
import { StockType } from '@/constants/common';
import CreatePreOrder from '@/mixins/create-pre-order';
import Cart from '@/components/common/cart/index.vue';
import CustomButton from '@/components/common/custom-button/index.vue';
import { Dialog, Toast } from 'vant';
import { cloneDeep, truncate } from 'lodash';

/**
 * 购物车Tab页面：
 * - 直售购物车
 * - 云仓库购物车
 */
export default {
    name: 'sell-cart',

    mixins: [CreatePreOrder],

    components: {
        Cart,
        CustomButton,
    },

    props: {
        active: Boolean,
    },

    watch: {
        active: {
            async handler() {
                if (this.active) {
                    await this.init();
                }
            },
        },
    },

    data() {
        return {
            StockType,
            products: [],
            showSelectGift: false, // 展示选择赠品的弹窗
            gifts: null,
            giftPackages: {}, // 当前礼包
            giftPackagesTem: {},
            selectGifts: {},
            giftCheckData: [
                {
                    name: '不选赠品',
                    hint: '（将转话为积分）',
                    visible: false,
                },
                {
                    name: '挑选赠品',
                    hint: '（将转话为积分）',
                    visible: true,
                },
            ],
        };
    },

    computed: {
        ...mapState({
            cart: state => state.cart.cart[StockType.Sell],
            brandId: state => state.global.brandId,
            userId: state => state.user.user.id,
            userRank: state => state.user.user.levelall && state.user.user.levelall[0].rank,
            samples: state => state.sgs.samples,
            plusProducts: state => state.sgs.plusProducts,
            limitProduct: state => state.product.limitProduct, // 限购商品map
            params: state => state.params.paramsList,
        }),

        // 赠品数量
        giftCount() {
            let num = 0;
            // 选着要赠品才计算
            // if (this.giftCheckData[1].visible) {
            Object.keys(this.giftPackages.giftObjs || {}).map(key => {
                this.giftPackages.giftObjs[key].map(d => {
                    if (d.isShow) {
                        num = num + d.count;
                    }
                });
            });
            // }
            return num;
        },

        // // 是否选着了赠品
        // chooseGift() {
        //     return this.giftCheckData[1].visible ? true : false
        // }

        // 是否开启积分赠品二选一选择
        isOpenGift() {
            const { params } = this;
            let visible = false;
            params.map(param => {
                if (param.value == 0) {
                    if (param.name === '赠品和积分并存') {
                        visible = true;
                    }
                }
            });
            return visible;
        },
    },

    methods: {
        // 初始化（获取购物车）
        async init() {
            const { userId } = this;

            this.products = [];
            await this.$store.dispatch('cart/getCart', { type: StockType.Sell, userId });
            this.products = this.cart || [];
        },

        // 更新购物车
        async updateCart(products) {
            await this.$store.dispatch('cart/updateCart', {
                brandId: this.brandId,
                products: products,
                type: StockType.Sell,
            });
            this.products = this.cart[this.brandId] || [];
        },

        // 选择赠品 - 弹窗
        async selectGift() {
            // // 选着不要赠品
            // if (!this.chooseGift) {
            //     this.giftPackages.giftObjs = {};
            //     this.giftPackages.chooseGift =  false;
            //     this.selectGifts = { ...this.giftPackages };
            //     this.showSelectGift = true;
            //     return;
            // }
            // 判断规则是否全选获取只能多选一
            let message = '';
            var isLength = Object.keys(this.giftPackages.giftObjs).every(
                key => this.giftPackages.giftObjs[key].length === 1
            );
            if (isLength) {
                // 同存
                for (let key of Object.keys(this.giftPackages.giftObjs)) {
                    if (!this.giftPackages.giftObjs[key][0].isShow) {
                        // 只要有一个没选择就提示
                        Object.keys(this.giftPackages.giftObjs).forEach(i => {
                            this.giftPackages.giftObjs[i].map(d => {
                                message += `<span>${d.productName}</span>，`;
                            });
                        });
                        message = message.substr(0, message.length - 1);
                        message = message + '<p>需要全选</p>';
                        await Dialog.alert({
                            message,
                            title: '温馨提示',
                        });
                        return;
                    }
                }
            } else {
                // 互斥
                for (let key of Object.keys(this.giftPackages.giftObjs)) {
                    // 存在互斥情况的数据
                    if (this.giftPackages.giftObjs[key].length > 1) {
                        var trueCount = this.giftPackages.giftObjs[key].reduce((res, gift) => {
                            if (gift.isShow) {
                                return (res += 1);
                            }
                            return (res += 0);
                        }, 0);
                        // 同一组的要每一组一个
                        if (trueCount === 0) {
                            message = '亲，同一组的只能多选一，不同组的必须全选~';
                            await Dialog.alert({
                                message,
                                title: '温馨提示',
                            });
                            return;
                        }
                        if (trueCount > 1) {
                            this.giftPackages.giftObjs[key].map(d => {
                                message += `<span>${d.productName}</span>；`;
                            });
                            message = message.substr(0, message.length - 1);
                            message = message + '<p>只能选一个</p>';
                            await Dialog.alert({
                                message,
                                title: '温馨提示',
                            });
                            return;
                        }
                    } else {
                        // 其他不互斥的也要求全选
                        for (let i of Object.keys(this.giftPackages.giftObjs)) {
                            if (
                                this.giftPackages.giftObjs[i].length === 1 &&
                                !this.giftPackages.giftObjs[i][0].isShow
                            ) {
                                message = '亲，同一组的只能多选一，不同组的必须全选~';
                                await Dialog.alert({
                                    message,
                                    title: '温馨提示',
                                });
                                return;
                            }
                        }
                    }
                }
            }

            this.selectGifts = { ...this.giftPackages };

            this.showSelectGift = true;
        },

        // 重构gift数组如果出现value相同的就归为一组
        formattingGift(arr) {
            var giftObj = {};
            arr.gifts.map(gift => {
                if (!giftObj[gift.value]) {
                    giftObj[gift.value] = [];
                }
                giftObj[gift.value].push({ ...gift });
            });
            return giftObj;
        },

        // 选择赠品
        async handleGiftClick(gift) {
            let giftTemp = { ...gift, giftObjs: {} };
            giftTemp.gifts.map(gift => {
                // 测试
                // gift.value = 1;

                gift.isShow = gift.isShow ? gift.isShow : false;
                gift.count = gift.quantity * giftTemp.quantity;
                if (!giftTemp.giftObjs[gift.value]) giftTemp.giftObjs[gift.value] = [];
                giftTemp.giftObjs[gift.value].push(gift);
            });
            delete giftTemp.gifts;
            this.giftPackages = { ...giftTemp };

            this.showSelectGift = true;
        },

        //  选中某个赠品组合
        onSelectGift(key, j) {
            this.giftPackages.giftObjs[key][j].isShow = !this.giftPackages.giftObjs[key][j].isShow;
            this.giftPackages = { ...this.giftPackages };
        },

        // 是否有商品超出限购数量，true:有商品超出限购数量
        checkIsOverLimit(products) {
            let isOver = false;
            const { limitProduct } = this;

            products.forEach(product => {
                product.formats.forEach(format => {
                    const limitCount = limitProduct[format.id];
                    if (limitCount === 0) {
                        Toast(`${product.name}是限购商品，您目前只能通过换购的方式加购该商品！`);
                        isOver = true;
                    } else if (limitCount && limitCount < format.count) {
                        Toast(`${product.name}是限购商品，您目前最多只能购买${limitCount}箱,请减少数量后再下单！`);
                        isOver = true;
                    }
                });
            });
            return isOver;
        },

        // 提交
        async trySubmit(products, gifts, totalPrice, chooseGift) {
            // 不提交没有选择的商品
            let list = products.filter(item => item.carts.length !== 0);

            products = cloneDeep(list);
            const name = await this.submit(products, gifts, totalPrice, chooseGift);
            if (name) {
                this.$router.push({
                    name,
                    query: {
                        from: 'cart',
                    },
                });
            }
        },

        hide() {
            this.showSelectGift = false;
        },

        onGiftCheck(i) {
            this.giftCheckData.map((d, j) => {
                if (i == j) {
                    d.visible = true;
                } else {
                    d.visible = false;
                }
            });
        },
    },

    async created() {
        await this.init();
    },
};
