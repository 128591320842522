import { cloneDeep as clone } from 'lodash';
import {
    getDiscountPrice,
    newWrapOrderProducts,
    newGetTotalPrice,
    newGetTotalAmount,
    getTotalAmount,
    isFormatInvalid,
} from '@/utils/common';
import { StockType } from '@/constants/common';
import EmptyList from '@/components/common/empty-list/index.vue';
import CustomStepper from '@/components/common/custom-stepper/index.vue';
import Rmb from '@/components/common/rmb/index.vue';
import { Dialog } from 'vant';
import { mapState } from 'vuex';
import { postGift, deleteCart, postCartValid, putUpdateCart } from '@/service/cart/index';

/**
 * 通用购物车组件，用于：
 * - 直售购物车
 * - 入库购物车
 * 等等
 *
 * CRUD => 通知父组件
 */
export default {
    name: 'cart',

    components: {
        EmptyList,
        Rmb,
        CustomStepper,
    },

    props: {
        // 商品
        products: {
            type: Array,
        },
        // 出库类型
        mode: {
            type: Number,
        },
        // 加购加商品价格，用于汇总
        plusProductAmount: {
            type: Number,
            required: false,
            default: 0,
        },
        // 选中礼包
        giftSelects: {
            type: Object,
        },
        // 是否开启需要赠品
        isOpenGift: {
            type: Boolean,
        },
    },

    data() {
        return {
            StockType,
            operating: false,
            selects: {}, // 记录format选中与否（前端字段）
            currentProducts: [],
            currentGift: {},
            currentSelects: [],
            currentSystemId: null,
            currentPackageId: null,
            giftCheckData: [
                {
                    name: '累计积分',
                    hint: '（积分可以换旅游名额）',
                    visible: false,
                },
                {
                    name: '挑选赠品',
                    hint: '',
                    visible: true,
                },
            ],
        };
    },

    watch: {
        products: 'init',
        giftSelects: {
            handler(newVal) {
                // 将选中的设置为true, giftSelects对象里面的数组都是一个
                this.currentGift[this.currentSystemId].map(d => {
                    if (d.packageId == this.currentPackageId) {
                        d.gifts.map(g => {
                            g.isShow = newVal.giftObjs[g.value].filter(l => l.formatId === g.formatId)[0].isShow;
                        });
                    }
                });
                this.currentGift = { ...this.currentGift };
            },
        },
    },

    computed: {
        ...mapState({
            userId: state => state.user.user.id,
        }),

        // 是否已全选
        all() {
            return this.currentSelects.length && !this.currentSelects.some(value => !value.select);
        },

        // 选中的 product 列表
        selectedProducts() {
            return newWrapOrderProducts(this.currentProducts, this.currentSelects);
        },

        // 总价
        totalPrice() {
            return newGetTotalPrice(this.selectedProducts);
        },

        // 商品总数
        totalAmount() {
            return newGetTotalAmount(this.selectedProducts);
        },

        // 禁用
        disabled() {
            return !this.selectedProducts.some(d => d.carts.length !== 0);
        },

        // 每个 product 中，已选的 format 数量
        selectedCount() {
            const ret = {};
            for (const product of this.currentProducts) {
                if (!ret.hasOwnProperty(product.id)) ret[product.id] = 0;
                for (const [formatId, format] of Object.entries(product.formats)) {
                    if (!isFormatInvalid(product, format) && this.selects[formatId]) {
                        ret[product.id] += format.count;
                    }
                }
            }
            return ret;
        },

        // 是否选着了赠品
        chooseGift() {
            return this.giftCheckData[1].visible ? true : false;
        },
    },

    methods: {
        getDiscountPrice,

        isFormatInvalid,

        // 初始化
        init() {
            this.currentProducts = clone(this.products); // 不影响原数据

            for (const product of this.currentProducts) {
                this.$set(this.currentSelects, product.systemId, { select: false, list: [] });
                for (const formatId of Object.keys(product.carts)) {
                    this.$set(this.currentSelects[product.systemId].list, formatId, { select: false }); // 默认全不选
                }
            }
        },

        // 清除单个无效商品
        clearInvalid(product, formatId) {
            const index = this.currentProducts.findIndex(item => item.id === product.id);
            product.count -= product.formats[formatId].count;
            delete product.formats[formatId];
            if (Object.keys(product.formats).length) {
                this.currentProducts.splice(index, 1, product);
            } else {
                this.currentProducts.splice(index, 1);
            }
            this.$emit('update', this.currentProducts);
        },

        // 反选全部
        async toggleAll(nextAll) {
            const { userId } = this;
            this.currentSelects.map(c => {
                c.select = nextAll;
                c.list.map(l => {
                    l.select = nextAll;
                });
            });
            // 获取全部商品的礼包
            if (nextAll) {
                var list = [];
                this.currentProducts.map(d => {
                    d.carts.map(c => {
                        var obj = { productId: c.productId, quantity: c.quantity };
                        if (c.state != 0) list.push(obj);
                    });
                });
                let params = { list, userId };
                const { systemIdGiftMap } = await postGift(params);
                this.currentGift = this.chooseGift ? systemIdGiftMap : {};
            } else {
                this.currentGift = [];
            }
        },

        // 反选一组
        async toggleCell(product) {
            const { userId } = this;
            let selects = this.currentSelects[product.systemId];
            selects.select = !selects.select;
            if (selects.select) {
                for (const sel of selects.list) {
                    sel.select = true;
                }
            } else {
                for (const sel of selects.list) {
                    sel.select = false;
                }
            }
            this.$set(this.currentSelects, product.systemId, selects);
            // 获取一组商品的礼包
            if (selects.select) {
                var list = [];
                product.carts.map(d => {
                    var obj = { productId: d.productId, quantity: d.quantity };
                    if (d.state != 0) list.push(obj);
                });
                if (list.length === 0) return;
                let params = { list, userId };
                const { systemIdGiftMap } = await postGift(params);

                this.currentGift = this.chooseGift ? this.addCurrentGift(systemIdGiftMap) : {};
            } else {
                this.currentGift = this.deleteCurrentGift(product.systemId);
            }
        },

        // 反选单个
        async toggleItem(systemId, formatId, carts) {
            const { userId } = this;
            this.currentSelects[systemId].list[formatId].select = !this.currentSelects[systemId].list[formatId].select;
            //  请求赠品
            var list = [];
            this.selectedProducts.map(d => {
                if (d.systemId === systemId) {
                    d.carts.map(cart => {
                        var obj = { productId: cart.productId, quantity: cart.quantity };
                        list.push(obj);
                    });
                }
            });
            if (list.length === 0) {
                this.currentGift = this.deleteCurrentGift(systemId);
            } else {
                let params = { list, userId };
                const { systemIdGiftMap } = await postGift(params);
                this.currentGift = this.chooseGift ? this.addCurrentGift(systemIdGiftMap) : {};
            }
            // 判断是否是一组中的最后一个，如果是要把全组都选上
            var allTrue = this.currentSelects[systemId].list.some(d => d.select);
            var allFalse = this.currentSelects[systemId].list.some(d => !d.select);
            if (allTrue) this.currentSelects[systemId].select = true;
            if (allFalse) this.currentSelects[systemId].select = false;
        },

        // 删除选中的所有商品
        async deleteProducts() {
            try {
                await Dialog.confirm({
                    message: '确认要删除所选商品吗？',
                });

                const products = this.currentProducts.filter(product => {
                    for (const key of Object.keys(product.formats)) {
                        if (this.selects[key]) {
                            product.count -= product.formats[key].count;
                            delete product.formats[key];
                            delete this.selects[key];
                        }
                    }
                    return Object.values(product.formats).length;
                });
                this.currentProducts = products;
                this.$emit('update', this.currentProducts);
            } catch (e) {}
        },

        // 删除商品
        async onDeleteFormat(systemId, id) {
            await Dialog.confirm({
                message: '确认要删除所选商品吗？',
            });

            let data = await deleteCart(id);
            if (data == null) {
                // this.$emit('update');
                this.currentProducts.map(p => {
                    if (p.systemId === systemId) {
                        var cart = p.carts.filter(d => d.id !== id);
                        p.carts = cart;
                    }
                });
                let tempProducts = this.currentProducts.filter(p => p.carts.length !== 0);
                this.currentProducts = [...tempProducts];
            }
        },

        // 完成编辑
        async formatChange(val, { systemId, productId, formatId, id }) {
            const { userId } = this;
            let changeProduct = null;
            this.currentProducts.map(p => {
                if (p.systemId === systemId) {
                    p.carts.map(d => {
                        if (d.formatId === formatId) {
                            changeProduct = d;
                        }
                    });
                }
            });

            if (val === 0) {
                //  意味删除
                await this.onDeleteFormat(changeFormat, name);
                const { count } = changeFormat;
                changeFormat.count = count;
                this.currentProducts = this.currentProducts.map(p => {
                    return { ...p };
                });
                this.$forceUpdate();
                return;
            }

            //  更新商品的数量和更新规格的数量
            changeProduct.quantity = val;
            this.currentProducts = [...this.currentProducts];

            //更新购物车
            await putUpdateCart({ id: id, quantity: val });

            // 更新赠品
            let list = [];
            this.selectedProducts.map(d => {
                d.carts.map(c => {
                    list.push({ productId: c.productId, quantity: c.quantity });
                });
            });
            let params = { list, userId };
            const { systemIdGiftMap } = await postGift(params);
            this.currentGift = systemIdGiftMap;
            // this.$emit('update', this.currentProducts)
        },

        // 校验是否选择了赠品，检查赠品/体验装数量
        async checkIsSelectGift() {
            let message = '';
            // let confirmButtonText = '去选择';

            if (!message && Object.keys(this.currentGift).length !== 0) {
                Object.keys(this.currentGift).forEach(key => {
                    this.currentGift[key].map(s => {
                        let isSelect = s.gifts.some(g => g.isShow);
                        if (!isSelect) {
                            var name = s.packageName;
                            message = `亲，您（${name}）还未选择赠品，赶紧去选择吧～`;
                            // confirmButtonText = '选择赠品'
                        }
                    });
                });
            }

            if (message) {
                try {
                    await Dialog.alert({
                        message,
                        // confirmButtonText
                    });
                } catch (e) {
                    return false;
                }
                return false;
            }

            return true;
        },

        // 检查购物车中商品是否失效
        async checkIsInvalid() {
            const { userId } = this;
            let list = [];
            this.selectedProducts.map(product => {
                product.carts.map(cart => {
                    list.push(cart.id);
                });
            });
            let params = {
                userId: userId,
                ids: list,
            };
            const { invalid } = await postCartValid(params);
            if (invalid.length === 0) {
                return true;
            } else {
                return false;
            }
        },

        // 获取选中的赠品
        getSelectGifts() {
            let tempCurrentGift = { ...this.currentGift };
            if (Object.keys(tempCurrentGift).length !== 0) {
                Object.keys(tempCurrentGift).forEach(key => {
                    // 删除没有选中的数据
                    tempCurrentGift[key].map((s, i) => {
                        let isSelect = s.gifts.some(g => g.isShow);
                        if (!isSelect) {
                            tempCurrentGift[key].splice(i, 1);
                        } else {
                            s.gifts = s.gifts.filter(gift => gift.isShow);
                        }
                    });
                });

                // 判断某个系统的tempCurrentGift是否为空
                Object.keys(tempCurrentGift).forEach(key => {
                    if (tempCurrentGift[key].length === 0) {
                        delete tempCurrentGift[key];
                    }
                });
            }
            return tempCurrentGift;
        },

        // 结算
        async toPay() {
            // 校验是否选择了赠品
            const isChoosed = await this.checkIsSelectGift();
            if (!isChoosed) {
                return;
            }

            // 检查购物车中商品是否失效
            const isInvalid = await this.checkIsInvalid();
            if (!isInvalid) {
                return;
            }

            let gifts = this.getSelectGifts();

            this.$emit('submit', this.selectedProducts, gifts, this.totalPrice, this.chooseGift);
        },

        // 选择赠品
        onGiftFormat(systemId, gift) {
            this.currentSystemId = systemId;
            this.currentPackageId = gift.packageId;
            this.$emit('onGiftClick', gift);
        },

        // 选中是否要赠品
        onGiftCheck(i) {
            this.giftCheckData.map((d, j) => {
                if (i == j) {
                    d.visible = true;
                } else {
                    d.visible = false;
                }
            });
            this.toggleAll(false);
        },

        addCurrentGift(obj) {
            var arr = Object.keys(this.currentGift);
            if (arr.length === 0) {
                return obj;
            } else {
                return { ...this.currentGift, ...obj };
            }
        },

        deleteCurrentGift(systemId) {
            var arr = Object.keys(this.currentGift);
            if (arr.length === 0) {
                return {};
            } else {
                delete this.currentGift[systemId];
                return { ...this.currentGift };
            }
        },
    },

    async created() {
        await this.init();
    },
};
