import { HttpV1 as HTTP } from '@/request'

/**
 * 获取当前规格可选的口红数量
 * {
    "brandId":"string: 品牌id",
    "formats":[
        {
            "id":"string: 规格id",
            "count":"number: 规格数量"
        }
    ]

 * @param {*} params 
 */
export const getLipStickCount = params => {
  return HTTP.post(`/agent/national/lipstick-count`, params)
}

/**
 * 获取口红列表
 * @param {*} params 
 */
export const getLipStickList = params => {
  return HTTP.post(`/agent/national/lipstick`, params)
}

/**
 * 获取最终赠品列表
 * {
    "brandId":"string: 品牌id",
    "formats":[
        {
            "id":"string: 规格id",
            "count":"number: 规格数量"
        }
    ],
    "experiences":[
        {
            "id":"string: 规格id",
            "count":"number: 规格数量"
        }
    ]
}
 * @param {*} params 
 */
export const getNationalExp = params => {
  return HTTP.post(`/agent/national/experience`, params)
}

/**
 * 获取该商品还能购买的数量
 * @param {*} params 
 */
export const getProductLimitcount = ({ userId, brandId }) => {
  return HTTP.post(`/agent/national/format-limit`, { userId, brandId })
}

/**
 * 插入限购商品数目
 * @param {*} params 
 */
export const addProductLimit = ({ orderId, formats }) => {
  return HTTP.post(`/agent/national/format-limit/insert`, { orderId, formats })
}