import { getOrderDetail } from '@/service/order/index.js'
import AddressDetail from '@/components/address/address-detail/index.vue'
import OrderDetail from '@/components/order/order-detail/index.vue'

/**
 * 订单详情（单个子订单，即一般情况）
 */
export default {
  name: 'order-detail-page',

  props: {
    id: {
      required: true, //订单id
      type: [String, Number]
    }
  },

  data () {
    return {
      order: null,
      rank: null
    }
  },

  components: {
    AddressDetail,
    OrderDetail
  },

  methods: {
    async init () {
      this.order = await getOrderDetail({ orderId: this.id })
    }
  },

  async created () {
    await this.init()
    this.rank = this.$store.state.user.user.levelall[0].rank

    console.log('order===',this.$store)
    console.log('order===',this.order)
  }
}
