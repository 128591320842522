import { StockType } from '@/constants/common'
import { mapState } from 'vuex'
import { Dialog } from 'vant';
// 签约弹窗
export default {
  name: 'sign-dialog',
  components: {
  },
  // 
  mounted() {
    // 暂无页面，所以先用dialog显示，等产品经理确认文案，图片
    Dialog({ message: '您需要先进行合同签约才可以进行其他内容' }).then(() => {
      this.$router.push({
        name: 'contract-guide'
      })
    });
  },
  data() {
    return {

    }
  },

  computed: {
    ...mapState({
      cart: state => state.cart.cart[StockType.Sell],
      brandId: state => state.global.brandId,
      limitProduct: state => state.product.limitProduct
    }),


  },

  methods: {}
}
