import { mapState } from "vuex";
import { BRAND, BRAND_NAME_MAP } from "@/constants/dead";
import { Toast } from "vant";
import { ISSHILISHOU } from "@/utils/common.js";
import { getRouteNameByLevel, SHILISHOU_LEVEL_MAP, REBATE_ID } from "@/constants/rebate";
import { Menus } from './config'

/**
 * 底部栏（业务组件）
 * 需要注意 iPhoneX 下的样式
 */
export default {
    name: "index-tabbar",

    data() {
        return {};
    },

    computed: {
        ...mapState({
            user: (status) => status.user.user,
            brandId: (state) => state.global.brandId,
            userId: (state) => state.user.user.id,
            tabWithPermission: (state) => state.permission.permission.tab,
            //  可能取值 'sgs-qianyang-achievement-sum','achievement-sum'
            entrance: (state) =>
                state.permission.permission.rebate &&
                state.permission.permission.rebate.entrance,
            homeEntrance: (state) =>
                state.permission.permission.app &&
                state.permission.permission.app["home-page"],
            userLevel: (state) =>
                (state.user.user.levelall && state.user.user.levelall[0]) || {},
            params: state => state.params.paramsList,
        }),

        // 当前 tab 的索引
        activeIndex() {
            const name = this.$route.name;
            return this.menus.findIndex((item) => {
                return item.to === name || (item.tos && item.tos.includes(name));
            });
        },

        menus() {
            const { tabWithPermission, entrance, homeEntrance, params } = this;
            if (homeEntrance) Menus[0].to = homeEntrance;   // 权限控制进入哪个首页
            params.map(param => {
                if (param.value == 0) {
                    Menus.map(d => {
                        if (param.name == d.label) {
                            d.to = '';
                            d.errormsg = '该功能暂未开放~';
                        }
                    })
                }
            })
            let data = [...Menus];

            //  不存在levelall的时候
            // if ((!this.user.levelall) || this.user.vaild == 0 || this.user.levelall.length == 0) {
            //   //  暂无处理，先记下来

            // }
            // // 如果levelall等级存在问题的时候，则修改tab的显示
            // else {
            //   // 部分用户的首页界面展示不一样
            //   if ((this.user.levelall[0].rank === 5 || this.user.levelall[0].rank === 6)) {
            //     allMenus = [{ // 菜单列表
            //       to: 'product-list',
            //       activeIcon: require('@/assets/images/zptab/home-active.png'),
            //       unactiveIcon: require('@/assets/images/zptab/home-unactive.png'),
            //       label: '首页',
            //     }, {
            //       to: 'my-agent',
            //       activeIcon: require('@/assets/images/zptab/achivement-active.png'),
            //       unactiveIcon: require('@/assets/images/zptab/achivement-unactive.png'),
            //       label: '授权',
            //     }, {
            //       to: 'personal-center',
            //       activeIcon: require('@/assets/images/zptab/personal-active.png'),
            //       unactiveIcon: require('@/assets/images/zptab/personal-unactive.png'),
            //       label: '我的',
            //     }]
            //   }
            // }
            /*       if (tabWithPermission) {
              return allMenus.filter(m => {
                if (m.tos) {
                  return m.tos.findIndex(t => tabWithPermission[t] === true) >= 0
                }
                return tabWithPermission[m.to] === true
              })
            } */
            return data;
        },
    },

    methods: {
        onTabChange(active) {
            const { menus, userId, entrance } = this;
            const item = menus[active];
            // 判断是否已经在管做了限制不显示
            if (item.errormsg) {
                Toast(item.errormsg);
                return;
            };
            if (!item.to) {
                return;
            }
            let routeName = item.to;
            // if (routeName === entrance) {
            //   this.$store.commit('rebate/setQueryBrandId', null)
            //   if (this.brandId === BRAND.SGS) {
            //     // 水光生业绩
            //     this.$store.commit('rebate/setRebateId', REBATE_ID.SHUI_GUANG_SHENG)
            //     this.$store.commit('rebate/setRebateName', BRAND_NAME_MAP[BRAND.SGS])
            //   } else if (this.brandId === BRAND.BX) { // 白皙业绩
            //     this.$store.commit('rebate/setRebateId', REBATE_ID.BAI_XI)
            //     this.$store.commit('rebate/setRebateName', BRAND_NAME_MAP[BRAND.BX])
            //   } else if (this.brandId === BRAND.UNDERWEAR) {
            //     this.$store.commit('rebate/setRebateId', REBATE_ID.UNDERWEAR)
            //     this.$store.commit('rebate/setRebateName', BRAND_NAME_MAP[BRAND.UNDERWEAR])
            //   } else if (this.brandId === BRAND.T || this.brandId === BRAND.UNDERWEAR) {
            //     routeName = 'achievement-ting-warn'
            //   }
            // }
            //  跳进实力瘦业绩
            if (item.to === "rebate-sum") {
                // //  业绩目前只有实力瘦有，所以在这里做一个判断
                // if (ISSHILISHOU()) {
                //   const { levelId } = this.userLevel;
                //   //  根据当前等级用户的等级，跳转到对应的页面
                //   routeName = getRouteNameByLevel(String(levelId));
                //   if (!routeName) {
                //     let levelName = SHILISHOU_LEVEL_MAP[levelId]
                //       ? SHILISHOU_LEVEL_MAP[levelId]
                //       : "无授权";
                //     Toast(`当前用户等级为${levelName}，暂不支持查看业绩～`);
                //     return;
                //   }
                // } else {
                //   Toast("功能开发中～");
                //   return;
                // }
                const { levelId } = this.userLevel;
                routeName = getRouteNameByLevel(String(levelId));
            }

            // 滚动条恢复到顶部
            window.scrollTo(0, 0);
            this.$router.replace({
                name: routeName,
                query: { agentId: userId },
            });
        },
    },
};
