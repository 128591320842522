/**
 * format项展示（下单页面、订单页面关于format的展示，可以公用）
 */
import Rmb from '@/components/common/rmb/index.vue'
import defImage from '@/assets/images/no-thumb.png'
import wx from 'weixin-js-sdk'
export default {
  name: 'format-item',

  components: {
    Rmb
  },

  props: {
    thumb: { // 缩略图
      type: String,
      required: true
    },
    title: { // 标题
      type: String,
      required: true
    },
    subTitle: { // 副标题
      type: String,
      default: ''
    },
    price: { // 价格
      type: [Number, String],
      default: ''
    },
    unit: { // 单位
      type: String,
      default: ''
    },
    count: { // 数量
      type: [Number, String],
      default: ''
    }
  },

  data() {
    return {
      defImage
    }
  },
  methods: {
    showBig(href) {
      wx.previewImage({
        current: href,// 当前显示图片的http链接
        urls: new Array(href) // 需要预览的图片http链接列表
      });
    }
  }
}
