import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import global from './modules/global'
import memory from './modules/memory'
import user from './modules/user'
import area from './modules/area'
import order from './modules/order'
import cart from './modules/cart'
import product from './modules/product'
import wechat from './modules/wechat'
import stock from './modules/stock'
import sample from './modules/sample'
import address from './modules/address'
import preorder from './modules/preorder'
import sgs from './modules/sgs'
import auth from './modules/auth'
import permission from './modules/permission'
import retail from './modules/retail'
import orderDispatch from './modules/orderDispatch'
import luckDraw from './modules/luckDraw'
import params from './modules/params'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        global,
        memory, // 不缓存
        user,
        area,
        order,
        cart,
        product,
        wechat,
        stock,
        sample,
        address, // 不缓存
        preorder,
        sgs, // 不缓存
        auth,
        permission,
        retail,
        orderDispatch,
        luckDraw,
        params,
    },
    plugins: [
        createPersistedState({
            key: process.env.VUE_APP_LS_KEY,
            paths: [
                'global',
                'user',
                'area',
                'order',
                'cart',
                'product',
                'wechat',
                'stock',
                'sample',
                'preorder',
                'achievement',
                'rebate',
                'permission',
                'retail',
                'orderDispatch',
                'luckDraw',
                'params'
            ]
        })
    ]
})

export default store