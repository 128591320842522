//折扣返利模块--rebate
export default [
  {
    path: '/rebate/rebate-sum',
    name: 'rebate-sum',
    component: () => import('@/pages/rebate/rebate-sum/index.vue'),
    meta: {
      name: '折扣首页',
      description: '折扣首页'
    }
  }
]