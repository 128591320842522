import ax from 'axios'
import { Toast, Dialog } from 'vant'
import wx from 'weixin-js-sdk'
import store from '@/store'
import { interceptRequest, getResponseInterceptor, getInterface } from './common'

const axios = ax.create({
  baseURL: process.env.VUE_APP_API_ROOT_V0,
  headers: {
    'Content-Type': 'application/json'
  }
})

interceptRequest(axios)

// v0接口返回的body结构：
//
// response = {
//   body: {
//     success: true,
//     status: 200,
//     message: 'succeed',
//     data,
//   }
// }
axios.interceptors.response.use(
  async response => {
    store.commit('memory/removeReqCount')

    const body = response.data
    const { success, status, message, data } = body
    if (!success) { // 业务逻辑失败
      if (status === 401) { // 后端返回401，要求重新登录
        await Dialog.alert({ message })
        // wx.closeWindow()
      } else { // 其它失败 => 提示
        Toast(`业务请求失败，原因：${message}`) // 请求成功但后端处理失败
      }
    } else { // 业务逻辑成功，看是否需要提示
      message && Toast(message)
    }

    return data
  },
  getResponseInterceptor()
)

export default getInterface(axios)
