import { StockType } from '@/constants/common'

/**
 * 下单成功
 */
export default {
  name: 'pay-success',

  data () {
    return {
      StockType,
      type: null
    }
  },

  methods: {
    // 查看订单
    async toOrderList () {
      switch (Number(this.type)) {
        // 库存 => 查看库存订单
        case StockType.StockIn:
        case StockType.StockOut:
          this.$router.replace({
            name: 'personal-center'
          })
          this.$router.push({
            name: 'stock-order'
          })
          break
        // 直售 => 查看直售订单
        case StockType.Sell:
          this.$router.replace({
            name: 'order-list',
            query: {
              state: -1 // 状态：全部
            }
          })
          break
        default:
          break
      }
    },

    // 返回个人库存
    back () {
      switch (this.type) {
        case StockType.StockIn:
        case StockType.StockOut:
          this.$router.replace({
            name: 'personal-center'
          })
          // 进入“库存明细”
          this.$router.push({
            name: 'my-stock'
          })
          break
        default:
          break
      }
    },

    //  返回商品首页
    toHome() {
      const homePage = this.$store.state.permission.permission.app['home-page']
      this.$router.replace({
        name: homePage || 'product-list'
      });
    }
  },
  computed: {
    showText() {
      return process.env.VUE_APP_PROJECT_NAME === 'shilishou' ? '公司' : '上级';
    }
  },
  created () {
    this.type = Number(this.$route.query.type)
  }
}
