/**
 * 单元格 + 单列Picker(点击单元格后弹出)
 * 代替[RadioGroup]而存在
 */
import { get } from 'lodash'

const UNSELECTED_VAL = -1 // 未选择

export default {
  name: 'cell-single-picker',

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    label: { // 单元格标题
      type: String,
      required: true
    },
    valueKey: { // 要辨认的字段名
      type: String,
      default: 'value'
    },
    showKey: { // 要展示的字段名
      type: String,
      default: 'key'
    },
    value: { // 绑定值
      required: true
    },
    valueList: { // 数据源
      type: Array,
      required: true
    },
    title: { // Picker标题
      type: String,
      default() {
        return this.label
      }
    },
    disabled: { // 是否禁用
      type: Boolean,
      default: false
    },
    disabledValue: { // 禁用时显示
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    required: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      show: false, // 弹出/隐藏
      localValue: null // 当前选中对象
    }
  },

  computed: {
    columns() { // 列定义
      let { valueKey, showKey } = this
      let values = [{ [showKey]: '请选择', value: UNSELECTED_VAL }].concat(this.valueList) // 增加“未选”项
      let defaultIndex
      if (this.localValue) {
        defaultIndex = values.findIndex(item => item[valueKey] === this.localValue[valueKey]) || 0
      } else {
        defaultIndex = 0
      }
      return [{ values, defaultIndex }]
    },

    showValue() { // 展示值
      const val = this.localValue
      if (!val || val.value === UNSELECTED_VAL) {
        return ''
      } else {
        return this.localValue[this.showKey]
      }
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.localValue = val || null
      }
    },

    localValue: {
      immediate: true,
      handler(val) {
        if (!val || val.value === UNSELECTED_VAL) {
          this.$emit('change', null) // 未选择时，设为null，而不是那个对象
        } else {
          this.$emit('change', val)
        }
      }
    }
  },

  methods: {
    onConfirm([item], [index]) {
      //如果存在父级存在onparentconfirm事件，则调用,加个try-catch安全点
      try {
        this.$emit('onPopupConfirm', item);
      } catch (error) {
        console.log("上级没有这个事件")
      }
      if (item.value === UNSELECTED_VAL) { return }
      this.localValue = item
      this.show = false // 关闭弹窗

    },

    onCancel() {
      this.show = false
    },

    setColumnValues(...args) {
      this.$refs['picker'].setColumnValues(...args)
    }
  }
}
