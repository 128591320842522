import { HttpV1 as HTTP } from '@/request';

// 加入购物车
export async function putAddCart(params) {
    return await HTTP.put(`/agent/cart`, params)
}

// 编辑购物车
export async function putUpdateCart(params) {
    return await HTTP.put(`/agent/cart/update`, params)
}


// 获取购物车
export async function getCart(userId) {
    return await HTTP.get(`/agent/cart`, { userId });
}

// 满赠活动返回赠品
export async function postGift({ list, userId }) {
    return await HTTP.post(`/agent/act/product/gift`, { list, userId });
}

// 删除或清除购物车记录
export async function deleteCart(id) {
    return await HTTP.delete(`/agent/cart/${id}`);
}

// 购物车商品提交校验
export async function postCartValid({ userId, ids }) {
    return await HTTP.post(`/agent/cart/valid`, { userId, ids });
}