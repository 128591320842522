import { Http,HttpV1 } from '@/request'

export default {
  namespaced: true,
  state: {
    provinces: [],
    cities: {},
    regions: {}
  },
  mutations: {
    setProvinces (state, provinces) {
      state.provinces = provinces
    },
    setCities (state, options) {
      state.cities[options.provinceId] = options.cities
    },
    setRegions (state, options) {
      state.regions[options.cityId] = options.regions
    }
  },
  actions: {
    // 获取省份信息
    async getProvinces ({ commit, state }) {
      const data = await HttpV1.get('/province')
      commit('setProvinces', data)
    },
    // 获取某个省份的城市列表
    async getProvinceCities ({ commit, state }, { provinceId }) {
      if (state.cities[provinceId] && state.cities[provinceId].length) return
      try {
        const data = await HttpV1.get(`city/${provinceId}`)
        commit('setCities', {
          provinceId,
          cities: data
        })
      } catch (e) {}
    },
    // 获取某个城市的区域列表
    async getCityRegions ({ commit, state }, { cityId }) {
      if (state.regions[cityId] && state.regions[cityId].length) return
      try {
        const data = await HttpV1.get(`region/${cityId}`)
        commit('setRegions', {
          cityId,
          regions: data
        })
      } catch (e) {}
    }
  }
}
