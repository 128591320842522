export default [
    {
        path: '/enroll-activity/add-enroll',
        name: 'add-enroll',
        component: () => import('@/pages/enroll-activity/add-enroll/index.vue'),
        meta: {
            name: '添加报名',
            description: '添加报名'
        }
    },
    {
        path: '/enroll-activity/write-enroll',
        name: 'write-enroll',
        component: () => import('@/pages/enroll-activity/write-enroll/index.vue'),
        meta: {
            name: '填写信息',
            description: '填写信息'
        }
    },
    {
        path: '/enroll-activity/agent',
        name: 'enroll-agent',
        component: () => import('@/pages/enroll-activity/agent/index.vue'),
        meta: {
            name: '选择代理',
            description: '选择代理'
        }
    },
    {
        path: '/enroll-activity/special-topic',
        name: 'enroll-agent',
        component: () => import('@/pages/enroll-activity/special-topic/index.vue'),
        meta: {
            name: '专题页',
            description: '专题页'
        }
    },
    {
        path: '/enroll-activity/success-info',
        name: 'enroll-success-info',
        component: () => import('@/pages/enroll-activity/success/index.vue'),
        meta: {
            name: '报名成功',
            description: '报名成功'
        }
    },
    {
        path: '/enroll-activity/enroll-list',
        name: 'enroll-list',
        component: () => import('@/pages/enroll-activity/enroll-list/index.vue'),
        meta: {
            name: '活动列表',
            description: '活动列表'
        }
    },
    {
        path: '/enroll-activity/wxPay',
        name: 'enroll-wxPay',
        component: () => import('@/pages/enroll-activity/wxPay/index.vue'),
        meta: {
            name: '微信支付',
            description: '微信支付'
        }
    },
    {
        path: '/enroll-activity/retail',
        name: 'enroll-retail',
        component: () => import('@/pages/enroll-activity/retail/index.vue'),
        meta: {
            name: '营销活动',
            description: '营销活动'
        }
    },
    {
        path: '/enroll-activity/retail-detail',
        name: 'enroll-retail-detail',
        component: () => import('@/pages/enroll-activity/retail/detail/index.vue'),
        meta: {
            name: '营销活动详情',
            description: '营销活动详情',
            wxShare: true
        }
    },
    {
        path: '/enroll-activity/pre-order',
        name: 'enroll-retail-pre-order',
        component: () => import('@/pages/enroll-activity/retail/pre-order/index.vue'),
        meta: {
            name: '结算',
            description: '结算'
        }
    },
    {
        path: '/enroll-activity/marketing-order-list',
        name: 'enroll-marketing-order-list',
        component: () => import('@/pages/enroll-activity/retail/marketing-order-list/index.vue'),
        meta: {
            name: '营销订单',
            description: '营销订单'
        }
    },
    {
        path: '/enroll-activity/aftermarket-order-list',
        name: 'enroll-aftermarket-order-list',
        component: () => import('@/pages/enroll-activity/retail/aftermarket-order-list/index.vue'),
        meta: {
            name: '售后单',
            description: '售后单'
        }
    },
    {
        path: '/enroll-activity/retail-success-info',
        name: 'enroll-retail-success-info',
        component: () => import('@/pages/enroll-activity/retail/success/index.vue'),
        meta: {
            name: '支付成功',
            description: '支付成功'
        }
    },
    {
        path: "/enroll-activity/retail-order/detail",
        name: "enroll-retail-order-detail",
        component: () => import("@/pages/enroll-activity/retail/retail-order-detail/index.vue"),
        meta: {
            name: "订单详情",
            description: "订单详情",
        },
    },
]