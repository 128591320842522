/*
 * @Author: fengyanjing
 * @Date: 2020-06-02 14:16:07
 * @Description:
 * @FilePath: /frontend/src/router/modules/shilishouRebate.js
 */
//  实力瘦业绩 -- shilishou-rebate
export default [
    //股东相关页面-- share-holders
    {
        path: "/shilishou-rebate/share-holders/home",
        name: "shilishou-share-holders-rebate-home",
        component: () =>
            import("@/pages/rebate/shilishou/share-holders/rebate-home/index.vue"),
        meta: {
            name: "股东折扣-首页",
            description: "折扣-首页",
        },
    },
    //  创始股东团队销售折扣明细
    {
        path: "/shilishou-rebate/share-holders/team-purch",
        name: "shilishou-share-holders-rebate-team-purch",
        component: () =>
            import("@/pages/rebate/shilishou/share-holders/team-purch/index.vue"),
        meta: {
            name: "创始股东团队销售折扣明细-首页",
            description: "创始股东团队销售折扣明细-首页",
        },
    },
    //  联合创始人相关页面-- co-founder
    {
        path: "/shilishou-rebate/co-founder/home",
        name: "shilishou-co-founder-rebate-home",
        component: () =>
            import("@/pages/rebate/shilishou/co-founder/rebate-home/index.vue"),
        meta: {
            name: "联合创始人折扣-首页",
            description: "联合创始人折扣-首页",
        },
    },
    //团队销售折扣
    {
        path: "/shilishou-rebate/co-founder/team-purch",
        name: "shilishou-co-founder-rebate-team-purch",
        component: () =>
            import("@/pages/rebate/shilishou/co-founder/team-purch/index.vue"),
        meta: {
            name: "联合创始人团队销售折扣-首页",
            description: "联合创始人团队销售折扣-首页",
        },
    },
    //  战略合伙人相关页面-- strategy
    {
        path: "/shilishou-rebate/strategy/home",
        name: "shilishou-strategy-rebate-home",
        component: () =>
            import("@/pages/rebate/shilishou/strategy/rebate-home/index.vue"),
        meta: {
            name: "战略合伙人折扣-首页",
            description: "战略合伙人折扣-首页",
        },
    },
    //  实力瘦公用------------推荐奖详情
    {
        path: "/shilishou-rebate/home/recommend-bonus",
        name: "shilishou-recommend-bonus",
        component: () =>
            import("@/pages/rebate/shilishou/common/recommend-bonus/index.vue"),
        meta: {
            name: "推荐奖详情",
            description: "推荐奖详情",
        },
    },
    //  实力瘦业绩公用--------团队销售折扣详情明细
    {
        path: "/shilishou-rebate/team-purch/detial",
        name: "shilishou-team-purch-detail",
        component: () =>
            import("@/pages/rebate/shilishou/common/team-purch-detail/index.vue"),
        meta: {
            name: "团队销售折扣明细详情",
            description: "团队销售折扣明细详情",
        },
    },

    //  实力瘦业绩公用--------折扣发放管理明细
    {
        path: "/shilishou-rebate/reabte-release-management",
        name: "shilishou-rebate-release-management",
        component: () =>
            import(
                "@/pages/rebate/shilishou/common/rebate-release-management/index.vue"
            ),
        meta: {
            name: "折扣奖励发放管理",
            description: "折扣奖励发放管理",
        },
    },

    //-- 实力瘦业绩公用----团队达标折扣
    {
        path: "/shilishou-rebate/reabte-team-standard/bonus",
        name: "shilishou-rebate-team-standard-bonus",
        component: () =>
            import("@/pages/rebate/shilishou/common/team-standard-bonus/index.vue"),
        meta: {
            name: "折扣奖励发放管理",
            description: "折扣奖励发放管理",
        },
    },


    // 11月最新业绩设计方案
    {
        path: "/nov-new-rebate/home",
        name: "nov-new-rebate-home",
        component: () =>
            import("@/pages/rebate/nov-new-rebate/home/index.vue"),
        meta: {
            name: "业绩",
            description: "业绩首页",
        },
    },
    {
        path: "/nov-new-rebate/team-purch",
        name: "nov-new-rebate-team-purch",
        component: () =>
            import("@/pages/rebate/nov-new-rebate/team-purch/index.vue"),
        meta: {
            name: "个人团队销售折扣",
            description: "业绩个人团队销售折扣",
        },
    },
    {
        path: "/nov-new-rebate/team-purch-detail",
        name: "nov-new-rebate-team-purch-detail",
        component: () =>
            import("@/pages/rebate/nov-new-rebate/team-purch-detail/index.vue"),
        meta: {
            name: "个人团队销售折扣明细",
            description: "业绩个人团队销售折扣明细",
        },
    },
    {
        path: "/nov-new-rebate/recommend-award",
        name: "nov-new-rebate-recommend-award",
        component: () =>
            import("@/pages/rebate/nov-new-rebate/recommend-award/index.vue"),
        meta: {
            name: "推荐奖",
            description: "推荐奖",
        },
    },
    {
        path: "/nov-new-rebate/reach-award",
        name: "nov-new-rebate-reach-award",
        component: () =>
            import("@/pages/rebate/nov-new-rebate/reach-award/index.vue"),
        meta: {
            name: "达标折扣",
            description: "业绩达标折扣",
        },
    },
    {
        path: "/nov-new-rebate/expend-award",
        name: "nov-new-rebate-expend-award",
        component: () =>
            import("@/pages/rebate/nov-new-rebate/expend-award/index.vue"),
        meta: {
            name: "支出奖励",
            description: "支出奖励",
        },
    },
    {
        path: "/nov-new-rebate/purch-award",
        name: "nov-new-rebate-purch-award",
        component: () =>
            import("@/pages/rebate/nov-new-rebate/purch-award/index.vue"),
        meta: {
            name: "折扣发放奖",
            description: "业绩折扣发放奖",
        },
    },
    {
        path: "/nov-new-rebate/purch-award-detail",
        name: "nov-new-rebate-purch-award-detail",
        component: () =>
            import("@/pages/rebate/nov-new-rebate/purch-award-detail/index.vue"),
        meta: {
            name: "折扣发放奖明细",
            description: "业绩折扣发放奖明细",
        },
    },
];
