import CellSinglePicker from '@/components/common/cell-single-picker/index.vue'
import AddressPicker from '@/components/common/address-picker/index.vue'
import * as Service from '@/service/auth'
import { pick, get } from 'lodash'
import { mapState } from 'vuex'
/**
 * 公共组件：个人信息表单
 */
export default {
  name: 'personal-info',

  components: {
    CellSinglePicker,
    AddressPicker
  },
  computed: {
    ...mapState({
      // true为当前需要身份证类型校验，false为当前不需要身份证校验
      showIdentityPhoto: state => state.permission.permission.auth['increment-apply'] && state.permission.permission.auth['increment-apply']['identity-photo'],
    })
  },
  props: {
    labelMap: { // 定制标签
      type: Object,
      default: () => ({
        name: '姓名',
        identity: '身份证号码',
        wechat: '微信号',
        gender: '性别',
        region: '省市区',
        profession: '职业',
        identityType: '证件类型',
        education: '学历'
      })
    },
    needsHide:{
      type: Object,
      default: {}
    }
  },

  data() {
    return {
      formData: {
        name: '',
        identity: '',
        wechat: '',
        gender: null,
        identityType: null,
        region: null,
        profession: null,
        education: null,
      },
      dataSource: {
        genders: [{
          name: '女',
          code: 0
        }, {
          name: '男',
          code: 1
        }],
        professions: [],
        identityTypes: [],
        educations: [],
      },
      fullAddress: '',
    }
  },

  methods: {
    async init() {
      const [
        professions,
        educations,
        identityTypes
      ] = await Promise.all([
        Service.getProfessionList(),
        Service.getEducationList(),
        Service.identityTypes()

      ])
      this.dataSource.professions = professions
      this.dataSource.educations = educations
      this.dataSource.identityTypes = identityTypes;
      this.$emit('loaded') // 加载基础数据完毕
    },

    // 填充表单数据
    setFormData(newData) {
      this.formData['name'] = newData['name'] || ''
      this.formData['identity'] = newData['identity'] || ''
      this.formData['wechat'] = newData['wechat'] || ''

      this.formData['gender'] = this.dataSource.genders.find(item => item.code === newData['gender'])
      this.formData['profession'] = this.dataSource.professions.find(item => item.id === newData['professionId'])
      this.formData['identityType'] = this.dataSource.professions.find(item => item.id === newData['identityType'])
      this.formData['education'] = this.dataSource.educations.find(item => item.id === newData['educationId'])

      // this.formData['region'] = this.dataSource.genders.find(item => item.id === newData['regionId'])
      this.onAddressChange(newData['region'])
    },

    // 获取表单数据
    getFormData() {
      const { gender, region, profession, education, identityType } = this.formData

      return {
        ...pick(this.formData, [
          'name',
          'identity',
          'wechat'
        ]),
        gender: get(gender, 'code'),
        regionId: get(region, 'id'),
        identityTypeName: get(identityType, 'identityTypeName'),
        identityTypeCode: get(identityType, 'identityTypeCode'),
        identityType: get(identityType, 'identityTypeCode'),
        professionId: get(profession, 'id'),
        educationId: get(education, 'id')
      }
    },

    onAddressChange(region) {
      this.formData.region = region

      if (region && region.id) {
        this.fullAddress = this.$getFullAddress(region)
      } else {
        this.fullAddress = ''
      }
    },

    onOpenAddrPicker() {
      this.$refs['addressPicker'].open()
    }
  },

  created() {
    this.init()
  }
}
