import wx from 'weixin-js-sdk'
import { Toast } from 'vant'

/**
 * 选择图片
 * @returns {String} 所选图片的本地ID
 */
function chooseImage(count = 1) {
    return new Promise((resolve, reject) => {
        wx.chooseImage({
            count: Math.min(count, 9), // 最多9张，否则会报错
            sizeType: ['compressed'], // 使用图片压缩
            sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
            success({ localIds }) {
                resolve(localIds)
            },
            fail(err) {
                reject(err)
            }
        })
    })
}
// 隐藏

function wxhideOptionMenu() {
    /* eslint-disable */
    WeixinJSBridge.call('hideOptionMenu');
}
//  显示
function showhideOptionMenu() {
    /* eslint-disable */
    WeixinJSBridge.call('showOptionMenu');
}
// 是否显示微信的options栏目
// type为true时为显示，false为隐藏
function toggleWxOptionsMenus(type) {
    try {
        if (typeof WeixinJSBridge == "undefined") {
            if (document.addEventListener) {
                document.addEventListener('WeixinJSBridgeReady', type ? showhideOptionMenu : wxhideOptionMenu, false);
            } else if (document.attachEvent) {
                document.attachEvent('WeixinJSBridgeReady', type ? showhideOptionMenu : wxhideOptionMenu);
                document.attachEvent('onWeixinJSBridgeReady', type ? showhideOptionMenu : wxhideOptionMenu);
            }
        } else {
            type ? showhideOptionMenu() : wxhideOptionMenu();
        }
    } catch (error) {
        console.log(error)
    }
}

/**
 * 获取本地图片的base64数据
 * (解决iOS可能不能直接显示localId的问题)
 */
function getLocalImgData(localId) {
    return new Promise((resolve, reject) => {
        wx.getLocalImgData({
            localId, // 图片的localID
            success({ localData }) { // localData是图片的base64数据，可以用img标签显示
                resolve(localData)
            },
            fail(e) {
                reject(e)
            }
        });
    })
}

/**
 * 预览图片
 * @param {String} localId 所点击的图片的本地ID
 * @param {String[]} localIds 照片列表
 */
function previewImage(localId, localIds) {
    localId = encodeURI(localId)
    localIds = localIds.map(lid => encodeURI(lid))
    return new Promise((resolve, reject) => {
        wx.previewImage({
            current: localId, // 当前显示图片的http链接/localId
            urls: localIds || [localId], // 需要预览的图片http链接列表/localIds
            success() {
                resolve()
            },
            fail(err) {
                reject(err)
            }
        });
    })
}

/**
 * 上传图片
 * @param {String} localId 图片的localID
 * @returns {String} 图片上传成功的serverId
 */
function uploadImage(localId) {
    return new Promise((resolve, reject) => {
        wx.uploadImage({
            localId, // 需要上传的图片的本地ID，由chooseImage接口获得
            isShowProgressTips: 1, // 默认为1，显示进度提示
            success({ serverId }) {
                resolve(serverId)
            },
            fail(err) {
                reject(err)
            }
        });
    })
}
//调用微信扫码，扫描并返回信息,不跳转链接
function scancode() {
    return new Promise((resolve, reject) => {
        wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: function (res) {
                resolve(res.resultStr)
            },
            fail(err) {
                Toast(err.errMsg);
            }
        });
    })
}

function updateAppMessageShareData({ title, desc, link, imgUrl }) {
    return new Promise((resolve, reject) => {
        wx.updateAppMessageShareData({
            title, // 分享标题
            desc, // 分享描述
            link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl, // 分享图标
            success: function (res) {
                resolve(res);
            },
            fail: function (err) {
                reject(err);
            },
        });
    });
}

// 微信支付 h5 WeixinJSBridge
function wxPay(obj) {
    return new Promise((resolve, reject) => {
        WeixinJSBridge.invoke(
            'getBrandWCPayRequest', {
            appId: obj.appId,  //公众号名称，由商户传入     
            timeStamp: obj.timeStamp,  //时间戳，自1970年以来的秒数     
            nonceStr: obj.nonceStr,  //随机串     
            package: obj.package,  //"prepay_id=u802345jgfjsdfgsdg888"
            signType: obj.signType,  //微信签名方式： MD5 
            paySign: obj.paySign  //微信签名 
        },function (res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
                resolve(res)
                // 使用以上方式判断前端返回,微信团队郑重提示：
                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            }else reject(res)
        });
    })
}

export {
    chooseImage,
    scancode,
    getLocalImgData,
    previewImage,
    uploadImage,
    toggleWxOptionsMenus,
    updateAppMessageShareData,
    wxPay
}