export default {
    name: 'address-detail',
    props: {
        address: {
            type: Object // 地址对象
        },
        readOnly: {
            type: Boolean, //  标识地址是只读的，不展示右箭头
            default: false,
        }
    },
    methods: {
        onChooseAddress() {
            this.$emit('onclick', this.address)
        },

        wrapFullAddress(address) {
            const { region, detail } = address;
            if (region && detail) {
                return region.city.province.name + region.city.name + region.name + detail;
            }
            if (region && (!detail || detail === '')) {
                return region.city.province.name + region.city.name + region.name;
            }
            if (!region && detail) {
                return detail;
            }
        }
    },
}