
import ax from 'axios'
import { Toast, Dialog } from 'vant'
import wx from 'weixin-js-sdk'
import store from '@/store'
import { interceptRequest, getResponseInterceptor, getInterface } from './common'
// 暂时不太明确为啥要分成多个文件。。。。。额。。。。。跟着写就对了
/**
 * 调用授权接口的配置文件
 * mk -2019/11/18
 * 
 */
const axios = ax.create({
  baseURL: process.env.VUE_APP_API_ROOT_VAUTH,
  headers: {
    'Content-Type': 'application/json'
  }
})

interceptRequest(axios)

// v1接口返回的body结构：
//
// response = {
//   data: {
//     data: {},
//     code: 200,
//     message: 'succeed'
//   }
// }
axios.interceptors.response.use(
  async response => {
    store.commit('memory/removeReqCount')

    const body = response.data
    const { data, message } = body
    if (message && message !== 'succeed') {
      Toast(message)
    }
    return data
  },
  getResponseInterceptor()
)

export default getInterface(axios)
