import { mapState } from 'vuex'
import { getDiscountPrice, convertUnit2LimitUnit } from '@/utils/common'
import { Toast, Dialog } from 'vant'
import { StockType } from '@/constants/common'
import UploadImage from '@/components/common/upload-image/index.vue'
import CellSinglePicker from '@/components/common/cell-single-picker/index.vue'
import FormatItem from '@/components/common/format-item/index.vue'
import Rmb from '@/components/common/rmb/index.vue'
import { pick } from 'lodash'

async function alert (message) {
  Toast(message)
}

const EXPRESS_USE = 0 // 包邮
const EXPRESS_NO_USE = 1

/**
 * 提交出库订单
 */
export default {
  name: 'stockout-preorder',

  components: {
    UploadImage,
    FormatItem,
    Rmb,
    CellSinglePicker,
  },

  data () {
    return {
      userPoints: 0,
      paidPoints: 0,
      userPointOptions: [{ value: EXPRESS_USE, key: '包邮 (积分抵扣)' }, { value: EXPRESS_NO_USE, key: '不抵扣积分' }],
      selectedAddressId: [],
      editAddressItem: {}
    }
  },
  
  computed: {
    ...mapState({
      myAddresses: state => state.user.addresses,
      brandId: state => state.global.brandId,
      order: state => state.preorder, // 预订单
      brand: state => state.stock.brand,
      subOrders: state => state.memory.subOrders, // 子订单
    })
  },

  methods: {
    getDiscountPrice,

    getUserPointOptions (message) {
      return [
        {
          value: EXPRESS_USE, 
          key: message 
        }, 
        { 
          value: EXPRESS_NO_USE, 
          key: '不抵扣积分' 
        }
      ]
    },

    // 选择地址
    onChooseAddress () {
      this.$router.push({
        name: 'address-list',
        query: {
          from: 'stockout-preorder',
          addressId: this.subOrders[0].address.id
        }
      })
    },

    // 初始化当前预订单
    async init () {
      // 积分选项
      this.userPoints = (await this.$store.dispatch('user/getUserPoint', { brandId: this.brandId })).totalPoint
      this.paidPoints = this.getPaidPoints()
      this.userPointOptions = this.getUserPointOptions(`包邮 (积分抵扣, 需 ${this.paidPoints} 积分)`)
      // this.userPointOptions[0].key = `包邮 (积分抵扣, 需 ${this.paidPoints} 积分)`
      // if (this.$refs['expressPicker']) {
      //   this.userPointOptions = this.getUserPointOptions(`包邮 (积分抵扣, 需 ${this.paidPoints} 积分)`)
      // } else {
      //   this.userPointOptions[0].key = `包邮 (积分抵扣, 需 ${this.paidPoints} 积分)`
      // }

      // 地址
      this.$store.commit('memory/setSubOrders', [{
        address: this.order.address || (this.subOrders[0] ? this.subOrders[0].address : null) || {},
        products: this.order.products || [],
        remark: this.order.remark || ''
      }])
    },

    getPaidPoints () {
      return Math.ceil(this.order.products.reduce((result, item) => {
        return result + convertUnit2LimitUnit(item) * item.pointDeductible.point / item.pointDeductible.number
      }, 0))
    },

    getPriceByProduct (product, discount, userRank) {
      return product.formats.reduce((result, item) => result + getDiscountPrice(discount, userRank, item.price) * item.count, 0)
    },

    // 封装 subOrder 对象
    _wrapSubOrders (subOrders) {
      const _subOrders = subOrders.map(item => {
        const newItem = {}
        newItem.products = []
        newItem.addressId = item.address.id
        newItem.remark = item.remark || this.order.remark
        newItem.products = item.products.map(product => pick(product, ['id', 'name', 'count', 'formats', 'isQualityGoods']))
        newItem.price = item.products.reduce((sum, product) => {
          return sum + this.getPriceByProduct(product, product.discount, product.userRank)
        }, 0)

        return newItem
      })
      return _subOrders
    },

    // 提交
    async payNow () {
      if (this.order.products.length === 0) {
        await alert('您还没有选择商品~')
        return
      }

      if (!this.subOrders[0].address.id) {
        await alert('您还没有选择下单地址~')
        return
      }

      if (this.paidPoints < this.userPoints && !this.order.userPointValue) {
        await alert('您还没有选择运费抵扣方式~')
        return
      }

      await this.$store.dispatch('stock/getBrand', { brandId: this.brandId })
      if (this.order.products.reduce((result, item) => result + convertUnit2LimitUnit(item), 0) < this.brand.minStockOut) {
        await alert('结算商品未满足最小发货量~')
        return
      }

      try {
        let message = ''
        this.subOrders[0].products.forEach(product => {
          message += `<p><span class="name">${product.name}</span><span class="value">x ${product.count}</span></p>`
        })
        await Dialog.confirm({
          message,
          title: '产品清单'
        })
        const _subOrders = this._wrapSubOrders(this.subOrders)
        const params = {
          subOrders: _subOrders,
          brandId: this.brandId,
          totalPrice: this.order.totalPrice,
          products: this.order.products
        }
        if (this.order.userPointValue && this.order.userPointValue.value === EXPRESS_USE) { // 如果能包邮
          params.point = this.paidPoints
        }

        await this.$store.dispatch('stock/placeStockOutOrder', params)

        this.$router.replace({
          name: 'pay-success',
          query: {
            type: StockType.StockOut
          }
        })
      } catch (e) {
        console.error(e)
      }
    }
  },

  async created () {
    await this.init()
  },

  async activated () {
    await this.init()
  },
}
