export default {
    namespaced: true,
    state: {
        curPrize: {}, //当前选择的奖品
        address: {},
        showReceivePrize: false
    },
    mutations: {
        setAddress(state,  payload) {
            state.address = payload.address
        },
        setCurPrize(state, payload) {
            state.curPrize = payload.prize
        }
    },
    actions: {

    },

}