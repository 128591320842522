import { mapState } from 'vuex'

/**
 * 规则说明
 */
export default {
  name: 'rule-statement',

  data () {
    return {
      content: '',
      title: ''
    }
  },

  computed: {
    ...mapState({
      brand: state => state.stock.brand,
      brandId: state => state.global.brandId
    })
  },

  async created () {
    const type = this.$route.query.type
    if (type === 'stock') {
      this.title = '出入库说明'
      this.content = this.brand.stockDescription
    } else if (type === 'point') {
      this.title = '积分规则说明'
      this.content = this.brand.pointDescription
    }
    await this.$store.dispatch('stock/getBrand', { brandId: this.brandId })
  }
}
