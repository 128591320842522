import VueRouter from 'vue-router';
import Vue from 'vue';
import store from '@/store';
import { get } from 'lodash';
import { toggleWxOptionsMenus } from '@/utils/wx-promise';
import { Dialog, Toast } from 'vant';
import { Http } from '@/request';
import wx from 'weixin-js-sdk';
import { getToken, setToken } from '@/utils/localStorage';

import UserLogin from '@/pages/login/user-login/index.vue';
import Protocol from '@/pages/personal/protocol/index.vue';
import Reminder from '@/pages/personal/reminder/index.vue';
import ProductList from '@/pages/product/product-list/index.vue';
import TwoCart from '@/pages/cart/two-cart/index.vue';
import ChooseGift from '@/pages/cart/choose-gift/index.vue';
import PersonalCenter from '@/pages/personal/personal-center/index.vue';
import AddressList from '@/pages/address/address-list/index.vue';
import AddressEdit from '@/pages/address/address-edit/index.vue';
import PreOrder from '@/pages/order/pre-order/index.vue';
import PaySuccess from '@/pages/order/pay-success/index.vue';
import OrderList from '@/pages/order/order-list/index.vue';
import OrderDetail from '@/pages/order/order-detail/index.vue';
import UseCoupon from '@/pages/coupon/use-coupon/index.vue';
import MyCoupon from '@/pages/coupon/my-coupon/index.vue';
import PersonalSetting from '@/pages/personal/personal-setting/index.vue';
import ComplementInformation from '@/pages/personal/complement-information/index.vue';
import StockOrder from '@/pages/stock/stock-order/index.vue';
import StockOrderDetail from '@/pages/stock/stock-order-detail/index.vue';
import StockinPreorder from '@/pages/stock/stockin-preorder/index.vue';
import StockoutPreorder from '@/pages/stock/stockout-preorder/index.vue';
import StockManage from '@/pages/stock/stock-manage/index.vue';
import MyStock from '@/pages/stock/my-stock/index.vue';
import UserPoint from '@/pages/personal/user-point/index.vue';
import RuleStatement from '@/pages/personal/rule-statement/index.vue';
import Guide from '@/pages/guide/index.vue';

import RegistGift from '@/pages/gift/regist_gift/index.vue';
import WalletList from '@/pages/wallet/list/index.vue';
import MyIntegral from '@/pages/personal/my-integral/index.vue';
import MyDeposit from '@/pages/personal/my-deposit/index.vue';

import Clean from '@/pages/clean/index.vue';
import NotFound from '@/pages/not-found/index.vue';

import auth from './modules/auth';
import order from './modules/order';
import luckDraw from './modules/luckDraw';
import contract from './modules/contract';
import lipGuoqingTemp from './modules/lipGuoqingTemp';
import qingbuluo from './modules/qingbuluo';
import rebate from './modules/rebate';
import security from './modules/security';
import retail from './modules/retail';
import shilishouRebate from './modules/shilishouRebate';
import cloudWarehouse from './modules/cloudWarehouse';
import enrollActivity from './modules/enroll-activity';

Vue.use(VueRouter);

function scrollBehavior(to, from, savedPosition) {
    if (!savedPosition) {
        // 前进，回到顶部
        return {
            x: 0,
            y: 0,
        };
    } else {
        // 后退，分情况
        if (to.meta && to.meta.keepAlive) {
            // 如果是keepAlive页面，则恢复位置
            return savedPosition;
        } else {
            // 否则回到顶部
            return {
                x: 0,
                y: 0,
            };
        }
    }
}

const router = new VueRouter({
    mode: 'hash',
    base: process.env.VUE_APP_BASE,
    //滚动行为
    scrollBehavior,
    routes: [
        {
            alias: '/clean',
            path: '/clean',
            name: 'clean',
            component: Clean,
            meta: {
                name: '清理',
            },
        },

        {
            alias: '/login',
            path: '/login/user-login',
            name: 'user-login',
            component: UserLogin,
            meta: {
                name: '登录',
                wxShare: true,
            },
        },

        // 4个主页
        {
            path: '/product/product-list',
            name: 'product-list',
            component: ProductList,
            meta: {
                // wxShare: true,
                name: '首页',
            },
        },
        {
            path: '/cart/two-cart',
            name: 'two-cart',
            component: TwoCart,
            meta: {
                name: '购物车',
                keepAlive: ['choose-gift'],
            },
        },
        {
            path: '/personal/personal-center',
            name: 'personal-center',
            component: PersonalCenter,
            meta: {
                name: '我的',
            },
        },
        {
            path: '/order/order-list',
            name: 'order-list',
            component: OrderList,
            meta: {
                name: '我的订单',
                keepAlive: ['order-detail'],
            },
        },
        //
        {
            path: '/cart/choose-gift',
            name: 'choose-gift',
            component: ChooseGift,
            meta: {
                name: '选择赠品',
            },
        },

        // 地址
        {
            path: '/address/address-list',
            name: 'address-list',
            component: AddressList,
            meta: {
                name: '地址列表',
            },
        },
        // 引导页
        {
            path: '/guide',
            name: 'guide',
            component: Guide,
            meta: {
                name: '商品介绍',
            },
        },

        {
            path: '/address/address-edit',
            name: 'address-edit',
            component: AddressEdit,
            meta: {
                name: '地址详情',
            },
        },

        // 下单、订单
        {
            path: '/order/pre-order',
            name: 'pre-order',
            component: PreOrder,
            meta: {
                name: '提交订单',
                keepAlive: ['use-coupon', 'address-list'],
            },
        },

        {
            path: '/order/order-detail/:id',
            name: 'order-detail',
            component: OrderDetail,
            props: true,
            meta: {
                name: '订单详情',
            },
        },
        {
            path: '/order/pay-success',
            name: 'pay-success',
            component: PaySuccess,
            meta: {
                name: '完成订单',
            },
        },

        // 优惠券
        {
            path: '/coupon/use-coupon',
            name: 'use-coupon',
            component: UseCoupon,
            meta: {
                name: '使用优惠券',
            },
        },
        {
            path: '/coupon/my-coupon',
            name: 'my-coupon',
            component: MyCoupon,
            meta: {
                name: '我的卡包',
            },
        },

        // 个人中心
        {
            path: '/personal/reminder',
            name: 'reminder',
            component: Reminder,
            meta: {
                name: '温馨提示',
            },
        },
        {
            path: '/personal/protocol',
            name: 'protocol',
            component: Protocol,
            meta: {
                name: '协议',
            },
        },
        {
            path: '/personal/personal-setting',
            name: 'personal-setting',
            component: PersonalSetting,
            meta: {
                name: '系统设置',
            },
        },
        {
            path: '/personal/user-point',
            name: 'user-point',
            component: UserPoint,
            meta: {
                name: '我的积分',
            },
        },
        {
            path: '/personal/rule-statement',
            name: 'rule-statement',
            component: RuleStatement,
            meta: {
                name: '规则说明',
            },
        },
        {
            path: '/personal/complement-information',
            name: 'complement-information',
            component: ComplementInformation,
            meta: {
                name: '完善个人资料',
            },
        },
        {
            path: '/wallet/list',
            name: 'my-wallet',
            component: WalletList,
            meta: {
                name: '我的钱包',
            },
        },
        // 库存
        {
            path: '/stock/stock-order',
            name: 'stock-order',
            component: StockOrder,
            meta: {
                name: '我要发货',
                keepAlive: ['stock-order-detail'],
            },
        },
        {
            path: '/stock/stock-order-detail',
            name: 'stock-order-detail',
            component: StockOrderDetail,
            meta: {
                name: '出库订单详情',
            },
        },
        {
            path: '/stock/stockin-preorder',
            name: 'stockin-preorder',
            component: StockinPreorder,
            meta: {
                name: '提交入库订单',
                keepAlive: ['address-list'],
            },
        },
        {
            path: '/stock/stockout-preorder',
            name: 'stockout-preorder',
            component: StockoutPreorder,
            meta: {
                name: '提交出库订单',
                keepAlive: ['address-list', 'stock-manage'],
            },
        },
        {
            path: '/stock/stock-manage',
            name: 'stock-manage',
            component: StockManage,
            meta: {
                name: '选择库存商品',
            },
        },

        {
            path: '/stock/my-stock',
            name: 'my-stock',
            component: MyStock,
            meta: {
                name: '库存明细',
            },
        },
        // 2019双十一活动
        {
            path: '/eleven2019/lottery',
            name: 'eleven_lottery_2019',
            component: () =>
                import(/* webpackChunkName: "bx-performance" */ '@/pages/eleven2019/pages/ElevenLottery2019.vue'),
            meta: {
                name: '双十一抽奖',
            },
        },
        {
            path: '/eleven2019/prize/list',
            name: 'eleven_prize_list_2019',
            component: () =>
                import(/* webpackChunkName: "bx-performance" */ '@/pages/eleven2019/pages/ElevenPrizesList2019.vue'),

            meta: {
                name: '我的奖品',
            },
        },
        // // 礼包
        {
            path: '/gift/regist-gift',
            name: 'regist-gift',
            component: RegistGift,
            meta: {
                name: '千漾礼包',
            },
        },
        // 我的积分
        {
            path: '/my-integral',
            name: 'my-integral',
            component: MyIntegral,
            meta: {
                name: '我的积分',
            },
        },
        // 保证金
        {
            path: '/my-deposit',
            name: 'my-deposit',
            component: MyDeposit,
            meta: {
                name: '保证金',
            },
        },

        // 授权模块
        ...auth,
        //订单模块
        ...order,
        //  国庆写死活动模块
        ...lipGuoqingTemp,
        //  折扣返利模块
        ...rebate,
        // 抽奖模块
        ...luckDraw,

        ...qingbuluo,
        //  合同签约
        ...contract,
        ...security, //  防伪查询相关
        ...retail, //  零售相关
        ...shilishouRebate, //  实力瘦业绩
        ...cloudWarehouse, //云仓库
        ...enrollActivity, // 报名模块
        // 其它
        {
            path: '*',
            component: NotFound,
            meta: {
                name: '页面不存在',
            },
        },
    ],
});

router.beforeEach(async (to, from, next) => {
    try {
        const target = router.options.routes.find(item => item.name === to.name);
        if (!target) {
            // @todo 未开发完的暂时方案，待删除
            Toast(`当前所在位置：${from.path},即将前往的位置${to.path}功能开发中～`);
            next(false);
            return;
        }

        if (typeof to.meta.getTitle === 'function') {
            document.title = to.meta.getTitle(to, from);
        } else {
            document.title = to.meta && to.meta.name ? to.meta.name : process.env.VUE_APP_TITLE;
        }

        // 写入品牌ID到全局（不必传来传去的）
        if (to.query.brandId) {
            store.commit('global/setBrandId', to.query.brandId);
        }

        /**
         * 处理 KeepAlive 页面
         */
        const fromKeepAlive = get(from, 'meta.keepAlive');
        if (!fromKeepAlive || !fromKeepAlive.includes(to.name)) {
            // from页面：如果不存在'keepAlive'属性或者不包括to页面，则取消其缓存
            store.commit('memory/removeKeepAlive', from.name);
        }
        const toKeepAlive = get(to, 'meta.keepAlive');
        // 注：将页面加入缓存，需要在它初始化之前，否则无效（待看源码查证）
        if (toKeepAlive && toKeepAlive.length > 0) {
            // to页面：如果存在'keepAlive'属性，则将其缓存
            store.commit('memory/addKeepAlive', to.name);
        }

        /*
         * 决定入口页面
         *
         */
        if(to.name === 'clean'){
            next();
        }
        if (to.name === 'user-login') {
            // 进入首页前（后端认为登录页为首页）
            const { token } = to.query; // 后端返回了token认定是已经登录了，更新本地的token
            if (token) {
                setToken(token);
            }
            const localToken = getToken();
            // 如果本地存放有access-token的话，直接登录
            if (localToken) {
                // 后端认为已登录，而且本地也有accessToken => 不必登录，根据参数直接跳转
                await store.dispatch('user/getUserData'); // 首次进入就请求用户信息（防止微信退出登录导致的localStorage信息丢失）
                //  重新进入新页面，重新请求配置，防止登录前后的配置不一样，（主要区分青葱系统的不同品牌的不入口） FIMEE
                await store.dispatch('permission/getPermission');
                const toRouteName = store.state.permission.permission.login.toRouteName;
                const homePage = store.state.permission.permission.app['home-page'];
                if (to.query.lottery) {
                    // 入口1：双十一抽奖
                    const { activityId } = to.query;
                    // 查询权限
                    const { permission } = await Http.get(`lottery/opp`, { activityId });
                    if (permission) {
                        next({
                            path: '/eleven2019/lottery',
                            name: `eleven_lottery_2019`,
                            replace: true,
                            query: {
                                activityId: to.query.activityId,
                            },
                        });
                    } else {
                        await Dialog.alert({ message: '当前账号未拥有抽奖资格' });
                        wx.closeWindow(); // 关闭网页(白屏不好看)
                        next(false);
                    }
                } else if (store.state.user.hasShowReminder[to.query.brandId] && to.query.brandId) {
                    // 入口2：商品列表
                    if (toRouteName) {
                        next({
                            name: toRouteName,
                            replace: true,
                        });
                    } else {
                        next({
                            name: homePage || 'product-list',
                            replace: true,
                        });
                    }
                } else if (to.query.brandId) {
                    // 入口3：温馨提示
                    if (toRouteName) {
                        next({
                            name: toRouteName,
                            replace: true,
                        });
                    } else {
                        next({
                            name: 'reminder',
                            replace: true,
                        });
                    }
                }
            } else {
                // 否则 => 需要登录
                next();
            }

            return;
        }
        // 非登录页面下
        else {
            console.log(getToken(), Object.keys(store.state.user.user).length, to.query.wechatToken);
            if (to.meta.noLogin) {
                //不需要登录，直接进入页面
                next();
            }
            // 外部跳转进来的时候携带了wecheat-token，并且此时用户的userinfo为空还有用户的token的时候，需要跳转到登录页面，并且到时候重新跳转回来
            else if (
                (!getToken() && to.query.wechatToken) ||
                (Object.keys(store.state.user.user).length === 0 && to.query.wechatToken)
            ) {
                next({
                    path: '/login/user-login',
                    name: 'user-login',
                    replace: true,
                    query: {
                        ...to.query,
                        routeUrl: to.path,
                    },
                });
            } else {
                next();
            }
        }
    } catch (error) {
        Toast({
            message: `页面跳转错误  ${JSON.stringify(error)}`,
            duration: 5000,
        });
        // next();
    }
});
// 跳转到页面之后，再去设置微信的是否分享
router.afterEach((to, from) => {
    //设置用户可否分享
    toggleWxOptionsMenus(to.meta.wxShare ? true : false);
});

export default router;
