import dayjs from 'dayjs'
import { mapState } from 'vuex'

const LIMIT = 5

/**
 * 我的卡包，公共页面：
 * - 有效
 * - 无效
 */
export default {
  name: 'use-coupon',

  data () {
    return {
      type: '', // 类型（共用区分）
      loading: false, // 加载中
      noMore: false, // 没有更多了
      count: 0, // 数量
      coupons: [], // 列表
    }
  },

  computed: {
    ...mapState({
      brandId: state => state.global.brandId
    })
  },

  watch: {
    $route: 'init'
  },

  methods: {
    // 初始化
    async init () {
      this.type = this.$route.query.type || 'valid'
      this.loading = false
      this.noMore = false
      this.count = 0
      this.coupons = []

      await this.loadCoupons()
    },

    // 加载优惠券
    async loadCoupons () {
      try {
        this.loading = true
        let { coupons, count } = await this.$http.get(`coupon/${this.type}`, {
          brandId: this.brandId,
          limit: LIMIT,
          offset: this.coupons.length
        })
        coupons = coupons.map(record => {
          record.startedAt = dayjs(record.startedAt).format('YYYY.MM.DD HH:mm')
          record.endedAt = dayjs(record.endedAt).format('YYYY.MM.DD HH:mm')
          const message = record.coupon.products.map(item => item.name).join('、')
          record.coupon.products = `仅限${message}产品使用`
          record.showDetail = false
          return record
        })
        this.count = count
        this.coupons = this.coupons.concat(coupons)
        this.loading = false
        if (coupons.length < LIMIT) this.noMore = true
      } catch (e) {
        console.error(e)
        this.loading = false
      }
    }
  },

  async created () {
    await this.init()
  }
}
