import { HttpV1 as HTTP } from '@/request'
import store from '@/store'
/**
 * 根据订单状态获取订单信息
 */
export async function getOrders({ brandId, state, limit, offset, userId }) {
    const data = await HTTP.get(`/agent/order/list`, { brandId, state, limit, offset, userId })
    return data
}

/**
 * 获取订单状态列表
 */
export async function getOrderStates() {
    const data = await HTTP.get(`/agent/order/getStates`)
    return data
}

/**
 * 获取订单状态列表
 */
export async function getOrderDetail({ orderId }) {
    const data = await HTTP.get(`/agent/order/detailPage`, { orderId })
    return data
}

//获取下级所有的审核订单
export async function orderSuperList({ limit, offset, brandId, state, userId }) {
    return HTTP.get(`/agent/order/superList`, { limit, offset, brandId, state, userId })
}

//订单审核取消下级订单
// type0表示管理端。type1表示的是代理段
export async function orderReject({ id, operateUserId }) {
    return HTTP.put(`/agent/order/reject`, { operateUserId, id, type: 1, state: 9 })
}

// 返回物流公司
export async function ExpressesEmit() {
    return HTTP.get(`/expresses/emit`)
}

// 同意一个物流
export async function affirmShipments(params) {
    return HTTP.put(`/agent/order/affirmShipments`, params);
}

//校验朔源码
export async function checkSourceCode(params) {
    return HTTP.get(`/agent/order/checkSourceCode`, params);
}

//获取经销商信息
export async function getAgencyList({ userId, systemId }) {
    return HTTP.get(`/agent/retail/getAgencyList`, { userId, systemId });
}

// 生成战略以上级别订单信息
export async function createAgencySuborder(
    {
        userId,
        juniorId,
        juniorLevelId,
        addressInfo,
        productList,
        totalPrice,
        systemId,
        brandId,
        receipts
    }
) {
    const data = await HTTP.post(`/agent/retail/createAgencySuborder`, {
        userId,
        juniorId,
        juniorLevelId,
        addressInfo,
        productList,
        totalPrice,
        systemId,
        brandId,
        receipts
    });
    return data;
}

//生成总代和VIP订单信息
export async function createRetailSuborder(
    {
        userId,
        addressInfo,
        productList,
        sourceCode,
        juniorId,
        trackCompany,
        trackingNumber
    }
) {
    return HTTP.post(`/agent/retail/createRetailSuborder`, {
        userId,
        addressInfo,
        productList,
        sourceCode,
        juniorId,
        trackCompany,
        trackingNumber
    });
}

//下单请求
export async function postOrder(params) {
    return HTTP.post(`/agent/order`, params);
}

export async function getRebateSystems() {
    const data = await HTTP.get(`/agent/new-rebate/systems`);
    return data;
}