//订单--retail-order
export default [
    {
        path: "/retail-order/home",
        name: "retail-order-home",
        component: () => import("@/pages/retail-order/retail-order-home/index.vue"),
        meta: {
            name: "零售订单-首页",
            description: "零售订单-首页",
        },
    },
    {
        path: "/retail-order/address-list",
        name: "retail-address-list",
        component: () =>
            import("@/pages/retail-order/retail-address-list/index.vue"),
        meta: {
            name: "零售订单-顾客列表",
            description: "零售订单-顾客列表",
        },
    },
    {
        path: "/retail-order/preorder",
        name: "retail-preorder",
        component: () => import("@/pages/retail-order/retail-preorder/index.vue"),
        meta: {
            name: "零售订单-确认下单",
            description: "零售订单-确认下单",
        },
    },
    {
        path: "/retail-order/delivery-success",
        name: "retail-delivery-success",
        component: () =>
            import("@/pages/retail-order/retail-delivery-success/index.vue"),
        meta: {
            name: "零售订单-发货成功",
            description: "零售订单-发货成功",
        },
    },
    {
        path: "/retail-order/list",
        name: "retail-order-list",
        component: () => import("@/pages/retail-order/retail-order-list/index.vue"),
        meta: {
            name: "零售订单-列表",
            description: "零售订单-列表",
        },
    },
    {
        path: "/retail-order/detail",
        name: "retail-order-detail",
        component: () => import("@/pages/retail-order/retail-order-detail/index.vue"),
        meta: {
            name: "零售订单-订单详情",
            description: "零售订单-订单详情",
        },
    },
    {
        path: "/retail-order/dispatch",
        name: "retail-order-dispatch",
        component: () => import("@/pages/retail-order/retail-order-dispatch/index.vue"),
        meta: {
            name: "零售发货",
            description: "零售发货",
        },
    },
    {
        path: "/retail-dispatch/terminal",
        name: "retail-dispatch-terminal",
        component: () => import("@/pages/retail-order/retail-dispatch-terminal/index.vue"),
        meta: {
            name: "零售发货",
            description: "零售发货扫描终端",
        },
    },
];
