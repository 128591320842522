import { Http, HttpV1 } from '@/request'
import {
  omit
} from 'lodash'
import { getOrders, getOrderDetail } from '@/service/order/index.js'
import { getOrderStates } from '@/service/order/index.js'

const defaultLimit = 20

export default {
  namespaced: true,
  state: {
    currentState: -1,
    express: {},
    orderStates: []
  },
  mutations: {
    setOrders (state, orders) {
      state.orders = orders
    },
    setCurrentState (state, currentState) {
      state.currentState = currentState
    },
    setCacheExpress (state, { orderId, express }) {
      state.express[orderId] = express
    },
    setOrderStates (state, { orderStates }) {
      state.orderStates = orderStates
    }
  },
  actions: {
    // 下单,非水光生
    //  国庆写死代码前experiences的值为samples （samples: experiences）
    async placeOrder ({ commit }, { userId, subOrders, brandId, totalPrice, receipts, products, payType, coupons, discount, gifts, experiences, plusProducts, expressesId, expressesName }) {
      await HttpV1.post('/agent/order', {
        userId, payType,
        brandId, totalPrice, receipts, products, coupons, discount, gifts,
        experiences,
        plusProducts,
        subOrders: subOrders.map(subOrder => ({
          ...omit(subOrder, ['samples']),
          experiences: subOrder.samples
        })),
        expressesId,
        expressesName
      })
    },
    // 下单，水光生
    async placeOrdersgs ({ commit }, { payType, userId, subOrders, brandId, totalPrice, receipts, products, coupons, discount, gifts, experiences, plusProducts, expressesId, expressesName }) {
      await HttpV1.post('/agent/order', {
        userId,
        brandId,
        totalPrice,
        receipts,
        products,
        coupons,
        discount,
        gifts,
        payType,
        experiences,
        plusProducts,
        expressesId,
        expressesName,
        subOrders: subOrders.map(subOrder => ({
          ...omit(subOrder, ['samples']),
          experiences: subOrder.samples
        }))
      })
    },
    async getOrderStats ({ commit }, { brandId }) {
      try {
        return await Http.get(`orders/stats`, { brandId })
      } catch (e) { }
    },
    // 设置当前state
    async setCurrentState ({ commit }, state) {
      commit('setCurrentState', state)
    },
    // 根据订单状态获取我的订单列表
    async loadOrders ({ commit }, { brandId, state, limit, offset, userId }) {
      try {
        limit = limit || defaultLimit
        offset = offset || 0
        const data = await getOrders({ brandId, state, limit, offset, userId })
        return data
      } catch (e) { }
    },
    // 根据订单状态获取我的订单列表------------------------新版v1
    async loadOrdersv1 ({ commit }, { brandId, state, limit, offset }) {
      try {
        const data = await HttpV1.get(`job/management/order/sold/${state}?brandId=${brandId}&state=${state}&limit=${limit || defaultLimit}&sortBy=desc&offset=${offset || 0}`)
        return data.rows
      } catch (e) { }
    },
    // 取消订单
    async cancelOrder ({ commit }, { orderId, reason }) {
      await Http.delete(`orders/${orderId}`, {
        cancelReason: reason
      })
    },
    // 获取订单详情
    async getOrderDetail ({ commit }, { orderId }) {
      try {
        const data = await getOrderDetail({ orderId })
        return data
      } catch (e) { }
    },
    // 设置物流缓存
    async setCacheExpress ({ commit }, { orderId, express }) {
      commit('setCacheExpress', { orderId, express })
    },
    // 获取订单状态列表
    async getOrderStates ({ commit }, params) {
      const orderStates = await getOrderStates() || {}
      commit('setOrderStates', { orderStates: orderStates.type })
      // return orderStates
    }
  }
}
