/**
 * 不持久化的内容
 * 相当于全局变量
 */
export default {
  namespaced: true,

  state: {
    keepAliveComponents: [], // 缓存组件/页面
    reqCount: 0, // 请求数目
    subOrders: [], // (下单页面中的)子订单列表
    gifts: [], // 赠品
  },

  mutations: {
    // 增加请求
    addReqCount (state) {
      ++state.reqCount
    },
    // 减少请求
    removeReqCount (state) {
      --state.reqCount
    },

    // 缓存
    addKeepAlive (state, val) {
      const arr = state.keepAliveComponents
      if (arr.indexOf(val) === -1) {
        arr.push(val)
      }
    },
    // 取消缓存
    removeKeepAlive (state, val) {
      const arr = state.keepAliveComponents
      const index = arr.indexOf(val)
      if (index !== -1) {
        arr.splice(index, 1)
      }
    },

    setSubOrders (state, val) {
      state.subOrders = val
    },

    setGifts (state, val) {
      state.gifts = val
    }
  },

  actions: {
    // 模拟假请求（作为反馈）
    async mockRequest ({ commit }, millisecond = 500) {
      commit('addReqCount')
      await sleep(millisecond)
      commit('removeReqCount')
    },
  }
}

function sleep (ms) {
  return new Promise(r => {
    setTimeout(() => {
      r()
    }, ms)
  })
}
