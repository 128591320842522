/**
 * 权限控制
 */
import {
    getPermission,
} from '@/service/permission'


export default {
    namespaced: true,

    state: {
        permission: {
            login: {},
            auth: {},
            tab: {},
            "personal-center": {
                "my-order": {},
                "my-tool": {},
                "my-warehouse": {},
                "manager-tool": {}
            }
        }
    },

    mutations: {
        setPermission(state, val) {
            state.permission = val
        },
    },

    actions: {
        async getPermission({ commit }) {
            await getPermission().then(res => {
                commit('setPermission', res)
            })
        },
    }
}
