import { mapState } from 'vuex'

const DEFAULT_VAL = '-1'

/**
 * 地址选择器
 */
export default {
  name: 'address-picker',
  props:{
    columnsNum:{
      type: String,
      default: () => '3'
    }
  },
  computed: {
    ...mapState({
      provinces: state => state.area.provinces,
      cities: state => state.area.cities,
      regions: state => state.area.regions
    }),
    columnsFn(){
      let { columnsNum, columns } = this
      return columns.slice(0, columnsNum)
    }
  },

  data () {
    return {
      show: false,
      showValue: '请选择',
      loading: false,
      columns: [{ // 列定义
        values: [{ name: '请选择', id: '-1' }],
        defaultIndex: 0,
        className: 'province'
      }, {
        values: [{ name: '请选择', id: '-1' }],
        defaultIndex: 0,
        className: 'city'
      }, {
        values: [{ name: '请选择', id: '-1' }],
        defaultIndex: 0,
        className: 'region'
      }],
      lastIndexList: [0, 0, 0],
      address: [],
      province: {},
      city: {},
      region: {}
    }
  },

  methods: {
    open () {
      this.show = true
    },

    close () {
      this.show = false
    },

    /**
     * 确认
     */
    onConfirm (valueList, indexList) {
      if (valueList.some(item => item.id === DEFAULT_VAL)) {
        // do nothing
      } else {
        this.showValue = valueList.map(item => item.name).join(' ')
        this.region.city = this.city
        this.region.city.province = this.province
        this.$emit('change', this.region)
        this.close()
      }
    },

    /**
     * 改变
     */
    async onChange (picker, valueList, i) {
      if (i === 0) { // 省改变 => 请求市
        const provinceId = valueList[0].id
        if (provinceId === DEFAULT_VAL) {
          this.setList(1, [])
          picker.setColumnIndex(1, 0)
          this.setList(2, [])
          picker.setColumnIndex(2, 0)
        } else {
          // 更新市列表
          await this.$store.dispatch('area/getProvinceCities', { provinceId: provinceId })
          this.province = this.provinces.find(i => i.id === provinceId)
          this.setList(1, this.cities[provinceId])
          picker.setColumnIndex(1, 0)
          // 清空区列表
          this.setList(2, [])
          picker.setColumnIndex(2, 0)
        }
      } else if (i === 1) { // 市改变 => 请求区
        const cityId = valueList[1].id
        if (cityId === DEFAULT_VAL) {
          this.setList(2, [])
          picker.setColumnIndex(2, 0)
        } else {
          // this.loading = true
          const provinceId = valueList[0].id
          await this.$store.dispatch('area/getCityRegions', { cityId: cityId })
          this.city = this.cities[provinceId].find(i => i.id === cityId)
          this.setList(2, this.regions[cityId])
          picker.setColumnIndex(2, 0)
        }
      } else if (i === 2) {
        const cityId = valueList[1].id
        const regionId = valueList[2].id
        this.region = this.regions[cityId].find(i => i.id === regionId)
      }
      this.address = [...valueList]
    },

    /**
     * 设置某一列的可选列表(用于初始化时)
     */
    setInitList (index, list = []) {
      let result = [
        { name: '请选择', id: DEFAULT_VAL },
        ...list,
      ]
      this.columns[index].values = result
    },

    /**
     * 设置某一列的可选列表
     */
    setList (index, list = []) {
      let result = [
        { name: '请选择', id: DEFAULT_VAL },
        ...list,
      ]
      let picker = this.$refs['picker']
      if (picker) { // 若可以取到picker，则调用其方法
        picker.setColumnValues(index, result)
      } else { // 否则说明还没到那一步，就初始化配置
        this.columns[index].values = result
      }
    },

    /**
     * 设置某一列的选中
     */
    setIndex (index, selectedIndex = -1) {
      let picker = this.$refs['picker']
      ++selectedIndex // 因为首项是“请选择”
      if (picker) {
        picker.setColumnIndex(index, selectedIndex)
      } else {
        this.columns[index].defaultIndex = selectedIndex
      }
    },

  },

  async created () {
    await this.$store.dispatch('area/getProvinces')
    this.setInitList(0, this.provinces)
  }
}
