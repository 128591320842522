export default [
    {
        path: '/luck-draw/index',
        name: 'luck-draw-index',
        component: () => import('@/pages/luck-draw/ld-index/index.vue'),
        meta: {
            name: '抽奖',
            description: '抽奖-首页',

        }
    }
]