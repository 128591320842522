import { getDiscountPrice, wrapOrderProducts, convertUnit2LimitUnit, getProductStock } from '@/utils/common'
import { StockType, ProductType } from '@/constants/common'
import { mapState } from 'vuex'
import { sumBy, pick, omit, cloneDeep } from 'lodash'
import { Toast } from 'vant'
import EmptyList from '@/components/common/empty-list/index.vue'

async function alert(message) {
  Toast(message)
}

/**
 * 选择库存商品(提货下单时，可进入该页面)
 */
export default {
  name: 'stock-manage',

  components: {
    EmptyList
  },

  data () {
    return {
      StockType,
      type: StockType.StockOut, // 类型：出库
      totalPrice: 0,
      products: [],
      targetProduct: {},
      isSelected: {},
      formatState: {}, // 规格的(外部)状态：[formatId] => {count, selected}
    }
  },

  computed: {
    ...mapState({
      cart: state => state.cart.cart,
      brand: state => state.stock.brand,
      brandId: state => state.global.brandId
    }),
    canSubmit () {
      return this.products.length > 0 && Object.values(this.formatState).some(({ selected, count }) => selected && count > 0)
    }
  },

  methods: {
    // 反选
    toggle (format) {
      this.formatState[format.id].selected = !this.formatState[format.id].selected
    },

    getProductStock,

    // 将 formats 转化为 formatObj 格式
    transferProduct (product) {
      product = cloneDeep(product)

      const formats = {}
      let productCount = 0
      for (const format of product.formats) {
        const { id, name, price } = format
        const count = this.formatState[id].count
        if (count > 0 && this.formatState[id].selected) { // 选中、且数量大于0
          formats[id] = {
            name,
            price,
            count
          }
          productCount += count
        }
      }

      const necessaryInfo = ['id', 'type', 'name', 'thumb', 'discount', 'unit', 'userRank', 'minpurchase', 'consumers', 'minstep', 'unitNumber', 'limitUnit', 'pointDeductible']
      const newProduct = {
        ...pick(product, necessaryInfo),
        formats,
        count: productCount
      }
      newProduct.systems = product.systems.map(m => {
        return omit(m, ['createdAt', 'updatedAt'])
      })

      return newProduct
    },

    // 提交
    async toPay () {
      // 转化商品
      let products = this.products.map(this.transferProduct)
      products = wrapOrderProducts(products)
      // 计算总价
      let totalPrice = 0
      for (const product of products) {
        for (const format of Object.values(product.formats)) {
          if (!format.changed && format.inventory >= format.count) {
            totalPrice += format.count * getDiscountPrice(product.discount, product.userRank, format.price)
          }
        }
      }
      // 获取该品牌信息
      await this.$store.dispatch('stock/getBrand', { brandId: this.brandId })
      
      // 赠品不能单独发货
      if (products.length === 1 && products[0].type === ProductType.Present) {
        await alert('赠品不能单独发货~')
        return
      }

      // todo 检查库存数量
      for (const product of products) {
        if (product.type === ProductType.Present) {
          if (product.count < product.minpurchase[product.userRank]) {
            await alert(`赠品 ${product.name} 未达到最小出库量 ${product.minpurchase[product.userRank]}~`)
            return
          }
        }
        const personalProduct = this.products.find(item => item.id === product.id)
        for (const format of product.formats) {
          const personalFormat = personalProduct.formats.find(item => item.id === format.id)
          if (format.count > personalFormat.stockCount) {
            await alert(`${product.name}(${format.name})库存数量不足~`)
            return
          }
        }
      }

      // 检查最小发货量
      const totalCount = products.reduce((result, p) => result + convertUnit2LimitUnit(p), 0)
      if (this.brand.minStockOut > totalCount) {
        await alert(`结算商品不满足最小发货量（${this.brand.minStockOut}）！`)
        return
      }

      // 修改预订单
      this.$store.commit('preorder/setField', {
        products,
        totalPrice,
        userPointValue: null,
      })

      this.$router.back()
    },

    // 初始化
    async init () {
      const stockProducts = await this.$store.dispatch('stock/getStocks', {
        brandId: this.brandId
      })
      this.products = stockProducts.filter(product => product.authed && this.getProductStock(product) > 0)

      // 初始化外部状态
      for (const product of this.products) {
        for (const format of product.formats) {
          this.$set(this.formatState, format.id, {
            count: format.stockCount, // 选中数量
            selected: false // 是否选中
          })
        }
      }
    }
  },

  async created () {
    await this.init()
  },
}
