// 免身份校验的接口
const ROUTES_NO_NEED_TOKEN = [
    'login',
    'wechat',
    'sample-sack',
    'addresses',
    'elevenact',
    'show',
    // 引导页
    '/agent/guide',
    //  业绩相关
    '/agent/user/by-date',
    '/agent/new-rebate',
    '/agent/level/by-user',
    '/agent/rebate/baixi/collect',
    '/agent/rebate/strategy',
    '/agent/rebate/director',
    '/agent/rebate/audit',

    // 扫码进来的 新增代理
    '/agent/profession',
    '/agent/education',
    '/agent/auth/identity-types',
    '/province',
    'city',
    'region',
    '/agent/auth/increment/levels',
    '/agent/user',
    '/agent/auth/increment/from-junior',
];
// 需要判断合同签名的页面:path
const ROUTER_NEED_SIGN = ['/product/product-list'];

export { ROUTES_NO_NEED_TOKEN, ROUTER_NEED_SIGN };
