import Vue from 'vue'
import Big from 'big.js'
import dayjs from 'dayjs'
import { BRAND } from '@/constants/dead'

/**
 * 小数 => 百分数
 * 用于展示
 */
const $decimal2Percent = (decimal, defaultVal = 0) => {
  let ret
  try {
    ret = Number(Big(decimal).times(100))
  } catch (e) {
    ret = defaultVal
  }
  return ret
}

/**
 * 百分数 => 小数
 * 用于传递给后端
 */
const $percent2Decimal = (percent) => {
  return Number(Big(percent).div(100))
}

/**
 * 分 => 元
 * 用于展示
 */
const $fen2Yuan = (fen, defaultVal = 0) => {
  let ret
  try {
    ret = Number(Big(fen).div(100))
  } catch (e) {
    ret = defaultVal
  }
  return ret
}

/**
 * 元 => 分
 * 用于传递给后端
 */
const $yuan2Fen = (yuan) => {
  return Number(Big(yuan).times(100))
}

/**
* 获取占比
*/
const $getPercent = (a, b) => {
  return Big(a).div(b).times(100).toFixed(3) // 保留3位小数
}

/**
 * 判断手机号码输入是否合法
 */
function $isPhoneValid (value, code) {
  if (code === '+852') {
    return /^([6|9])\d{7}$/.test(value)
  }
  if (code === '+853') {
    return /^6[6|8]\d{5}$/.test(value)
  }
  if (code === '+886') {
    return /^[0][9]\d{8}$/.test(value)
  }
  return /^[1][3-9]\d{9}$/.test(value)
}

/**
 * 获取七牛缩略图（小图loading更快）
 */
function $getThumb (imageURL, size = 400) {
  if (!imageURL) return ''
  return `${imageURL}?imageView2/0/w/${size}/h/${size}`
}

/**
 * 格式化货币：倒数起每3位加一个逗号
 * @todo 用正则更爽，待学
 */
function $formatCurrency (value, every = 3) {
  value = String(value)
  let arr = []

  let tailIndex = value.lastIndexOf('.')
  if (tailIndex === -1) { // 整数
    tailIndex = value.length
  } else {
    arr.unshift(value.slice(tailIndex))
  }

  let count = 0
  for (let i = tailIndex - 1; i >= 0; --i) {
    ++count
    if (count > every) {
      arr.unshift(',')
      count = 1
    }
    arr.unshift(value[i])
  }
  return arr.join('')
}

/**
 * 获取正负号
 */
function $getSign (value) {
  value = Number(value)
  if (Number.isNaN(value) || value === 0) {
    return ''
  } else if (value > 0) {
    return '+'
  } else {
    return '-'
  }
}

/**
 * 是否 水光生 平台
 * [临时方法]
 */
function $isSGS () {
  return BRAND.SGS === this.$store.state.global.brandId
}

/**
 * 是否 内衣 平台
 * [临时方法]
 */
function $isUnderWear () {
  return BRAND.UNDERWEAR === this.$store.state.global.brandId
}

/**
 * String => Date
 */
function $str2Date (s, format = 'YYYY-MM-DD HH:mm:ss') {
  return dayjs(s, format).toDate()
}

/**
 * Date => String
 */
function $date2Str (d, format = 'YYYY-MM-DD HH:mm:ss') {
  if (!d) {
    return ''
  }
  return dayjs(d).format(format)
}

/**
 * 格式化手机号
 */
function $formatPhone (phone, delim = ' ') {
  phone = phone.trim()
  if (phone.length !== 11) return phone
  return [
    phone.slice(0, 3),
    phone.slice(3, 7),
    phone.slice(7, 11),
  ].join(delim)
}

// 获取完整地址
function $getFullAddress (region) {
  return region.city.province.name + ' ' + region.city.name + ' ' + region.name
}

// 挂在 Vue 原型上
Object.assign(Vue.prototype, {
  $decimal2Percent,
  $percent2Decimal,
  $fen2Yuan,
  $yuan2Fen,
  $getPercent,
  $isPhoneValid,
  $getThumb,
  $formatCurrency,
  $getSign,
  $isSGS,
  $str2Date,
  $date2Str,
  $formatPhone,
  $getFullAddress,
  $isUnderWear,
})
