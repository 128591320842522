import { mapState } from 'vuex'
import { getProductStock } from '@/utils/common'
import EmptyList from '@/components/common/empty-list/index.vue'

/**
 * 库存明细
 */
export default {
  name: 'my-stock',

  components: {
    EmptyList
  },

  data () {
    return {
      stockCount: 0,
      monthStockCount: 0,
      weekStockCount: 0,
      products: [],
    }
  },

  computed: {
    ...mapState({
      brandId: state => state.global.brandId
    })
  },
  
  methods: {
    // 初始化
    async init () {
      const products = await this.$store.dispatch('stock/getStocks', {
        brandId: this.brandId
      })
      this.products = products.map(product => {
        return {
          ...product,
          stockCount: getProductStock(product)
        }
      }).filter(product => {
        return product.authed && product.stockCount > 0
      })
    },

    // 查看某商品的库存明细
    toDetail (product) {
      this.$router.push({
        name: 'my-stock-detail',
        query: {
          productId: product.id
        }
      })
    }
  },

  async created () {
    await this.init()
  }
}
