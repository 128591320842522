import { StockType } from '@/constants/common'
import { mapState } from 'vuex'
import { getDiscountPrice, getTotalPrice, wrapOrderProducts, getCheckProductStuct } from '@/utils/common'
import { cloneDeep as clone } from 'lodash'
import { pick, omit } from 'lodash'
import rmb from '@/components/common/rmb/index.vue'
import CreatePreOrder from '@/mixins/create-pre-order'
import { Toast } from 'vant'
import { checkUnderWearPreOrder } from '@/service/sgs-performance/order'
import CustomStepper from '@/components/common/custom-stepper/index.vue'

/**
 * 弹窗：选择SKU
 */
export default {
    name: 'product-select',

    components: {
        rmb,
        CustomStepper
    },

    mixins: [CreatePreOrder],

    data() {
        return {
            visible: false,
            product: null, // 当前product
            type: null, // 购买类型
            minCount: null,
            valid: true,
            counts: {},
        }
    },

    computed: {
        ...mapState({
            cart: state => state.cart.cart[StockType.Sell],
            brandId: state => state.global.brandId,
            limitProduct: state => state.product.limitProduct,
            userId: state => state.user.user.id,
        }),
        thumbURL() {
            if (this.product && this.product.thumb) {
                return this.$getThumb(this.product.thumb)
            } else {
                return null
            }
        },
        min() {
            //  还原代码，this.product.formats === 1,走第一条分支会有bug
            if (this.product.formats && this.product.formats === 1) {
                return this.valid ? (this.minCount || 1) : 1
            } else {
                return this.valid ? (this.minCount || 0) : 0
            }
        },
        totalPrice() {
            return Object.values(this.counts).reduce((result, item) => {
                return result + getDiscountPrice(this.product.discount, this.product.userRank, item.price) * item.count
            }, 0)
        },
        totalCount() {
            return Object.values(this.counts).reduce((result, item) => result + item.count, 0)
        },
        disabled() {
            return this.totalCount >= (this.minCount || 1)
        },
        minstep() {
            return JSON.parse(this.product.minstep)[this.product.userRank] || 1;
        },
        minusStep() {
            if (JSON.parse(this.product.decMinStep)) {
                if (!Object.keys(JSON.parse(this.product.decMinStep)).length) {
                    return this.minstep
                }
            }
            return JSON.parse(this.product.decMinStep)[this.product.userRank] || 1
        }
    },

    methods: {
        getDiscountPrice,

        /**
         * 打开
         * @param {object} product 展示商品
         * @param {boolean} type 立即购买/加入购物车
         */
        show(product, type = 'cart') {
            this.product = clone(product) // 深拷贝
            this.type = type
            this.initCounts()
            this.visible = true
            this.$nextTick(() => {
                // 重置滚动条到顶部
                this.$refs['scrollParent'].scrollTop = 0
            })
        },

        close() {
            this.visible = false
        },

        // 初始化
        initCounts() {
            this.counts = {}
            this.product.formats.forEach(item => {
                this.$set(this.counts, item.id, {
                    name: item.name,
                    count: this.min,
                    price: item.price,
                    convertCount: item.convertCount,
                    showUnit: item.showUnit
                })
            })
        },

        getCountByFormat() {
            const countByFormat = clone(this.counts)
            for (const key of Object.keys(countByFormat)) {
                if (countByFormat[key].count === 0) {
                    delete countByFormat[key] // 选出那些数量不为0的format
                }
            }
            return countByFormat
        },

        // 封装 product 结构（立即购买/加入购物车）
        getWrappedProduct() {
            return {
                ...this.product,
                // ...pick(this.product, ['id', 'name', 'thumb', 'minpurchase', 'discount', 'minstep', 'unit', 'userRank', 'consumers']),
                formats: this.getCountByFormat(),
                count: this.totalCount,
                levelId: this.product.levelId,//需要把levelid添加进去
                // systems: this.product.systems.map(m => {
                //   return omit(m, ['createdAt', 'updatedAt'])
                // })
            }
        },

        /**
         * 封装 stock product 结构（加入入库购物车）
         */
        getWrappedStockProduct() {
            return {
                ...this.product,
                // ...pick(this.product, ['id', 'type', 'name', 'thumb', 'discount', 'unit', 'userRank', 'minpurchase', 'consumers', 'minstep', 'unitNumber', 'limitUnit', 'pointDeductible']),
                formats: this.getCountByFormat(),
                count: this.totalCount,
                systems: this.product.systems.map(m => {
                    return omit(m, ['createdAt', 'updatedAt'])
                })
            }
        },

        /**
         * 立即购买
         * 重点注意！处理数据的过程，需要跟购物车下单前的处理保持一致！
         */
        async buyAtOnce() {
            let products = [this.getWrappedProduct()]
            products = wrapOrderProducts(products)
            const totalPrice = getTotalPrice(products)

            products = await this.$store.dispatch('cart/checkValid', products)

            //  内衣检查购买数量是否正确
            if (this.$isUnderWear()) {
                try {
                    await checkUnderWearPreOrder(getCheckProductStuct(products))
                } catch (error) {
                    return
                }
            }

            const name = await this.submit(products, totalPrice)
            if (name) {
                this.$router.push({
                    name,
                    query: {
                        from: 'buy'
                    }
                })
            }
        },

        // 加入[购物车]
        async addToCart() {
            const { userId } = this; 
            await this.$store.dispatch('cart/addToCart', {
                userId: userId,
                type: StockType.Sell,
                brandId: this.brandId,
                product: this.getWrappedProduct()
            })
            this.visible = false
            this.$emit('after-cart', this.product.id)
        },



        isLimit(format) {
            const { limitProduct } = this
            const isLimitProduct = limitProduct[format.id]
            if (isLimitProduct === 0) {
                return false
            }
            return format.inventory > 0
        },

        onOverLimitProduct(format) {
            const { limitProduct } = this
            const limit = limitProduct[format.id]
            if (!limit) {
                return
            }
            Toast(`亲，限购商品最多只能选${limit}箱哦～`)
        },

    }
}
