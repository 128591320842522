/**
 * 可拖拽的盒子
 */
import { throttle } from 'lodash'

export default {
  name: 'dragable-box',

  props: {
    //  初始化的定位样式
    initalPosition: {
      type: Object,
      default: () => {
        return {
          position: 'fixed',
          'z-index': 1000,
          top: '330px',
          right: '12px',
        }
      }
    },

    //  悬浮的clientX和clientY，暂时不知道放在app.vue里面使用，整个盒子的clientHeight变成和可视区域的clientHeight
    clientXY: {
      required: false,
      default: () => {
        return {
          clientHeight: 50,
          clientWidth: 50
        }
      }
    }
  },

  data () {
    return {
      isTouch: false, //  是否触摸
      touchStartPos: { x: 0, y: 0 },  //  记录手指点击的位置
      divStartPos: { x: '', y: '' }, //  记录盒子位置，计算的是盒子的左上角的位置，指的是offset的值
      touchChanged: { x: '', y: '' }, //  手指移动的变化量
      divEndPos: { x: '', y: '' },  //  移动后，盒子应该在的位置
    }
  },

  methods: {
    onClick (e) {
      this.$emit('onclick', e)
    },

    getTouch (e) {
      this.isTouch = true
      return e.touches ? e.touches[0] : e  //  判断是鼠标事件还是触摸事件
    },

    onTouchStart (e) {
      const touch = this.getTouch(e)
      this.touchStartPos.x = touch.clientX
      this.touchStartPos.y = touch.clientY  // 手指开始点击的位置
      this.divStartPos.x = this.$refs.moveDiv.offsetLeft
      this.divStartPos.y = this.$refs.moveDiv.offsetTop //  盒子被拖动前的位置
      //  防止页面的滑动的默认事件，为了只是让悬浮球滑动，其它不滑动，请注意是否获取到 touchmove, 系统默认passive: true，无法使用preventDefault
      document.addEventListener('touchmove', this.preventDefault, { passive: false })
      document.addEventListener('mousemove', this.preventDefault, { passive: false })
    },

    /**
     * 处理移动事件
     * @param {*} e 
     */
    handleTouchMove (e) {
      if (this.isTouch) {
        const touch = this.getTouch(e)
        this.touchChanged.x = touch.clientX - this.touchStartPos.x
        this.touchChanged.y = touch.clientY - this.touchStartPos.y  //  移动后触摸点之间的变化量
        this.divEndPos.x = this.touchChanged.x + this.divStartPos.x
        this.divEndPos.y = this.touchChanged.y + this.divStartPos.y   //  移动后盒子位置

        const windowWidth = document.documentElement.clientWidth
        const windowHeight = document.documentElement.clientHeight

        let finalX = this.divEndPos.x
        let finalY = this.divEndPos.y
        const { clientHeight: boxClientHeight, clientWidth: boxClientWidth } = this.clientXY
        //  判断盒子是否出界的情况
        if (this.divEndPos.x <= 0) {
          finalX = 0
        } else if (this.divEndPos.x + boxClientWidth >= windowWidth) {
          finalX = (windowWidth - boxClientWidth)
        } else {
          finalX = this.divEndPos.x
        }
        if (this.divEndPos.y <= 0) {
          finalY = 0
        } else if (this.divEndPos.y + boxClientHeight >= windowHeight) {
          finalY = windowHeight - boxClientHeight
        } else {
          finalY = this.divEndPos.y
        }

        //  去掉right的值，防止right，left配合导致盒子撑大
        this.$refs.moveDiv.style.position = 'fixed'
        this.$refs.moveDiv.style.top = finalY + 'px'
        this.$refs.moveDiv.style.left = finalX + 'px'
        this.$refs.moveDiv.style.right = null
      }
    },

    onTouchMove: throttle(function (e) { this.handleTouchMove(e) }, 150),

    onTouchEnd (e) {
      this.isTouch = false
      document.removeEventListener('touchmove', this.preventDefault, false)
      document.removeEventListener('mousemove', this.preventDefault, false)
      // 下面两句是保证在移除监听事件后，除了悬浮球的部分还能够滑动，如果不添加，则无法滑动
      document.addEventListener("touchmove", function (e) {
        window.event.returnValue = true
      })
      document.addEventListener("mousemove", function (e) {
        window.event.returnValue = true
      })
    },
    preventDefault (e) {
      e.preventDefault()
    }
  },

  mounted () {
    const { initalPosition, clientXY } = this
    this.$refs.moveDiv.style.position = initalPosition.position
    this.$refs.moveDiv.style.top = initalPosition.top
    this.$refs.moveDiv.style.right = initalPosition.right
    this.$refs.moveDiv.style.left = initalPosition.left
    this.$refs.moveDiv.style['z-index'] = initalPosition['z-index']
    this.$refs.moveDiv.style.width = clientXY.clientWidth
    this.$refs.moveDiv.style.height = clientXY.clientHeight
  },
}
