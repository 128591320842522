import { HttpV1 as HTTP } from "@/request";

// 查询我的总积分
export async function userPoint(id) {
  return await HTTP.get(`/agent/user/${id}/point`);
}

// 查询我的积分详情
export async function pointRecord(params) {
    return await HTTP.get('/agent/act/point/record/list',params );
  }