import IndexTabbar from '@/components/common/index-tabbar/index.vue'
import SellCart from '@/pages/order/sell-cart/index.vue'
import InstockCart from '@/pages/stock/instock-cart/index.vue'

import { mapState } from 'vuex';

/**
 * 购物车Tab页面，包含2个业务页面：
 * - sell-cart，直售购物车
 * - instock-cart，入库购物车
 */
export default {
  name: 'two-cart',

  components: {
    IndexTabbar,
    SellCart,
    InstockCart,
  },

  computed: {
    ...mapState({
      brandId: state => state.global.brandId
    })
  },

  data () {
    return {
      currentIndex: 0, // 当前Tab索引
    }
  },

  methods: {
    onChangeTab (newIndex) {
      this.$router.replace({
        name: this.$route.name,
        query: {
          index: newIndex
        }
      })
    },

    init () {
      const { index = 0 } = this.$route.query
      this.currentIndex = Number(index)
    }
  },

  created () {
    this.init()
  }
}
