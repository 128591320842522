import { mapState } from 'vuex';

/**
 * 温馨提示
 */
export default {
  name: 'reminder',

  data() {
    return {
      showBottom: false,
      isRead: false,
      remitDesription: {
        accounts: '[]',
        description: ''
      }
    }
  },

  computed: {
    ...mapState({
      brandId: state => state.global.brandId
    })
  },

  methods: {
    async init() {
      const data = await this.$http.get(`remit/${this.brandId}`)
      this.remitDesription = data
      document.title = this.remitDesription.title
      if (this.$route.query.position === 'cart') {
        this.showBottom = false
      } else {
        this.showBottom = true
      }
      this.$store.dispatch('user/lookReminder', this.brandId)
    },

    forward() {
      if (this.isRead) {
        const homePage = this.$store.state.permission.permission.app['home-page']
        this.$router.replace({
          name: homePage || 'product-list'
        });
      }
    }
  },

  created() {
    this.init()
  }
}
