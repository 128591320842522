import wx from 'weixin-js-sdk'
import { Dialog, Toast } from 'vant'
import { removeToken, removePersisternStorage } from '@/utils/localStorage'
import { mapState } from 'vuex'
import { sleep } from '@/utils/common'
import * as UserService from '@/service/user/user'
/**
 * 个人设置
 */
export default {
    name: 'personal-setting',
    computed: {
        ...mapState({
            user: state => state.user.user,//用户的信息
        }),
    },
    data() {
        return {
            resendSmsCodeTimeleft: 0,
            userInfo: {},//用户信息拷贝下来，避免受到污染
        }
    },
    mounted() {
        this.userInfo = Object.assign(this.user)
    },
    methods: {
        async sure() {
            try {
                await Dialog.confirm({ message: '确认修改用户信息吗？' })
                Dialog.confirm({ message: '修改成功，请退出并使用新手机号重新登录' })
            } catch (e) {
                return false
            }
        },
        // 退出登录
        async logout() {
            try {
                await Dialog.confirm({ message: '确认要退出当前账号吗？' })
            } catch (e) {
                return false
            }
            // await this.$store.dispatch('user/logout')
            removeToken()
            removePersisternStorage()
            location.reload()
            wx.closeWindow()
        },
        // 发送验证码
        async sendSmsCode(captcha) {
            const { region, phone } = this
            if (!this.userInfo.phone) {
                Toast("手机号码不能为空");
                return;
            }
            if (this.resendSmsCodeTimeleft != 0) {
                return;
            }
            const requestBody = {
                phone: this.phone
            }
            if (this.$route.query.brandId) requestBody.brandId = this.$route.query.brandId
            if (captcha && typeof captcha === 'string') {
                requestBody['captcha'] = captcha
            }
            try {
                if (region.ptype != '1') {
                    const areacode = region.value.slice(1)
                    await UserService.sendSms({ phone, ptype: region.ptype, areacode })
                } else {
                    await this.$store.dispatch('user/getCode', requestBody)
                }
                this.captchaCode.visible = false
                this.resendSmsCodeTimeleft = 60
                for (let i = 59; i >= 0; --i) {
                    await sleep(1000)
                    this.resendSmsCodeTimeleft = i
                }
            } catch (e) {
                console.log(e);
                if (e.status === 403) {
                    this.captchaCode.visible = true
                    this.captchaCode.url = `${process.env.API_ROOT}/login/captcha?phone=${this.phone}&random=${Math.random() * 10000000}` // 添加随机数使验证码图片刷新
                }
            }
        },
    }
}
