import { getguide } from '@/service/other/index.js'
/**
 * 商品列表
 */
export default {
  name: 'guide',
  data() {
    return {
      guideList:null,//图片列表
      stopmovie: true,//移动无效
      startpoint: {},//开始点击的点
      templatIndex: 0,//当前属于哪个template
    }
  },
  // 隐藏默认的touch事件
  async mounted() {
    console.log("123");
    const {guideList} = await getguide();
    this.guideList=guideList;
  },

  methods: {

    touchstart(e) {
      // touch
      if (!this.isToNext(e)) {
        this.stopmovie = true
        this.startpoint = e.touches[0];
        e.stopPropagation();
        e.preventDefault();
      }
    },

    touchmove(e) {
      // touch
      if (!this.isToNext(e) && this.stopmovie) {
        const { pageY } = e.touches[0];
        const { pageY: startpageY } = this.startpoint;
        const moveY = pageY - startpageY;
        // 如果移动的超过50像素，则滚动
        if (Math.abs(moveY) > 100) {
          this.stopmovie = false;//停止计算
          //要上移
          if (moveY > 100) {
            if (this.templatIndex > 0) {
              this.templatIndex--;
            }
          }
          // 下移
          else {
            if (this.templatIndex < 2) {
              this.templatIndex++;
            }
          }
        }
        // e.stopPropagation();
        // e.preventDefault();
      }
    },
    // 到下一个template
    toNext(e) {
      if (this.templatIndex < 2) {
        this.templatIndex++;
      }
    },
    //判定事件的触发源是不是跳转到下一页的
    isToNext(e) {
      if (e.target.className.split(/\s+/).indexOf("toclick") > -1) {
        console.log("true点击事件");
        return true
      }
    },
    //去首页
    toIndex(e) {
      // this.$route.push({})
    }
  }
}
