import Vue from 'vue'
import HttpV0 from './v0'
import HttpV1 from './v1'
import HttpVauth from './vauth'

// 挂到Vue实例上，方便使用
Vue.prototype.$http = HttpV0
Vue.prototype.$http0 = HttpV0
Vue.prototype.$http1 = HttpV1
Vue.prototype.$httpauth=HttpVauth

export {
  HttpV0 as Http,
  HttpV0,
  HttpV1 as HttpV1,
  HttpVauth
}
