/**
 * 钱包为空时候显示的列表
 */
export default {
  name: 'empty-list',
  data() {
    return {
      text: '数据暂时不提供查询'
    }
  }
}
