//轻部落定制页面--qingbuluo
export default [
  {
    path: '/qingbuluo/home/index',
    name: 'home-index',
    component: () => import('@/pages/home/index.vue'),
    meta: {
      name: '首页',
      description: '首页'
    }
  },
  {
    path: '/qingbuluo/product/list',
    name: 'qingbuluo-product-list',
    component: () => import('@/pages/qingbuluo/product/index.vue'),
    meta: {
      name: '商品',
      description: '商品'
    }
  },
  {
    path: '/qingbuluo/guide',
    name: 'qingbuluo-guide',
    component: () => import('@/pages/qingbuluo/guide-page/index.vue'),
    meta: {
      name: '开始',
      description: '开始',
      noLogin: true,//  不需要登录
    }
  }
]