/**
 * 按钮
 */
export default {
  name: 'cash',
  props: {
    size: { // 按钮大小（选择）
      type: String,
      default: 'normal',
      validator (size) {
        return ['big', 'normal', 'small'].includes(size)
      }
    },
    type: { // 字体大小（选择）
      type: String,
      validator (type) {
        return ['primary', 'plain'].includes(type)
      }
    }
  },
  methods: {
    onClick (e) {
      this.$emit('onclick', e)
    }
  },
}
