export default [{
  path: '/contract/guide',
  name: 'contract-guide',
  component: () => import( /* webpackChunkName: "contract" */ '@/pages/contract/guide/index.vue'),
  meta: {
    name: '签约',
    description: '签约-合同',

  }
}, {
  path: '/contract/sign',
  name: 'contract-sign',
  component: () => import( /* webpackChunkName: "contract" */ '@/pages/contract/sign/index.vue'),
  meta: {
    name: '签约',
    description: '签约-签约',
    keepAlive: [
      'electronic-sign'
    ]
  }
}, {
  path: '/contract/electronic/sign',
  name: 'electronic-sign',
  component: () => import( /* webpackChunkName: "contract" */ '@/pages/contract/electronic-sign/index.vue'),
  meta: {
    name: '签约',
    description: '签约-电子签名',
  }
},
// 签约结果
{
  path: '/contract/result',
  name: 'contract-result',
  component: () => import( /* webpackChunkName: "contract" */ '@/pages/contract/result/index.vue'),
  meta: {
    name: '签约结果',
    description: '签约-签约结果',
  }
}
]