/*
 * @Author: yanjing.feng 
 * @Date: 2020-05-12 14:34:08 
 * @Last Modified by:   yanjing.feng 
 * @Last Modified time: 2020-05-12 14:34:08 
 * //防伪模块--security
 */
export default [
  {
    path: '/security/search',
    name: 'security-search',
    component: () => import('@/pages/security/security-search/index.vue'),
    meta: {
      name: '防伪查询',
      description: '防伪查询'
    }
  }
]