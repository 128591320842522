export const functionKey = 'HAD_SHOWED_GUIDE';
export const tokenKey = 'access-token';
export const brandIdKey = 'brand-id';

export const getFunctionKey = () => {
    return window.localStorage.getItem(functionKey);
};

export const setFunctionKey = value => {
    localStorage.setItem(functionKey, value);
};

export const setToken = value => {
    if (typeof value != 'string') {
        localStorage.setItem(tokenKey, null);
    }
    localStorage.setItem(tokenKey, value);
};

export const getToken = () => {
    return localStorage.getItem(tokenKey);
};

export const removeToken = () => {
    localStorage.removeItem(tokenKey);
};

export const setBrandId = value => {
    localStorage.setItem(brandIdKey, value);
};

export const getBrandId = () => {
    return localStorage.getItem(brandIdKey);
};

export const removeBrandId = () => {
    localStorage.removeItem(brandIdKey);
};

export const removePersisternStorage = () => {
    localStorage.removeItem(process.env.VUE_APP_LS_KEY);
};
