<template>
  <div  class="clean">
     清除缓存
  </div>
</template>

<script>
export default {
    name: 'clean',
    mounted() {
        this.cleanmsg();
    },
    methods: {
        cleanmsg: function () {
            localStorage.removeItem("access-token");
            localStorage.removeItem("zhuipeng-system-frontend");
        }
    }

}
</script>
<style lang="less" scoped>
.clean {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
