<template>
    <div id="app" class="app">
        <keep-alive :include="keepAliveComponents">
            <router-view />
        </keep-alive>

        <!-- Loading组件 -->
        <transition name="van-fade">
            <div class="mask" v-show="reqCount > 0">
                <van-loading color="white" />
            </div>
        </transition>

        <dragable-box v-show="show" style="min-height:0">
            <!-- 购物车图标 -->
            <div class="cart-entrance" @click="onHomeCartClick">
                <i class="icongouwuchetubiao iconfont"></i>
            </div>
        </dragable-box>
        <dragable-box
            :initalPosition="{
                position: 'fixed',
                'z-index': 1000,
                top: '390px',
                right: '12px'
            }"
            v-show="show"
            style="min-height:0"
            >
            <!-- 首页图标 -->
            <div class="cart-entrance home" @click="onHomeClick">
                <i class="iconshouye iconfont"></i>
            </div>
        </dragable-box>
        <!-- 是否需要签约 -->
        <signDailog v-if="needSign" />
    </div>
</template>

<script>
import DragableBox from "@/components/common/dragable-box/index.vue";
import { ROUTER_NEED_SIGN } from "@/constants/route.js";
import { isNeedSign } from "@/service/contract/index.js";
import { mapState } from "vuex";
import signDailog from "@/components/common/sign-dialog/index.vue";
import { updateAppMessageShareData } from "@/utils/wx-promise";

import wx from "weixin-js-sdk";

export default {
    name: "app",

    components: {
        DragableBox,
        signDailog
    },
    data() {
        return {
            // 是否需要签约的
            needSign: false
        };
    },
    computed: {
        ...mapState("memory", {
            keepAliveComponents: state => state.keepAliveComponents,
            reqCount: state => state.reqCount
        }),

        ...mapState({
            homeEntrance: state =>
                state.permission &&
                state.permission.permission &&
                state.permission.permission.app &&
                state.permission.permission.app["home-page"]
        }),

        show() {
            //  不在登录，指引页面的展示悬浮图标
            const { name } = this.$route;
            if (name === "user-login") {
                return false;
            }
            if (name === "qingbuluo-guide") {
                return false;
            }

            return true;
        }
    },

    methods: {
        /**
         * 设置REM
         */
        configREM() {
            // 基准大小
            const baseSize = 32;
            function setRem() {
                // 当前页面宽度相对于 375 宽的缩放比例，可根据自己需要修改。
                const scale = document.documentElement.clientWidth / 375;
                // 设置页面根节点字体大小
                document.documentElement.style.fontSize =
                baseSize * Math.min(scale, 2) + "px";
            }
            setRem();
            window.addEventListener("resize", setRem);
        },

        /**
         * 解决iOS12布局错位问题
         */
        solveLayout() {
            if (/iphone|ipad|ipod/i.test(navigator.appVersion)) {
                // 针对iOS系统，Android不必
                document.addEventListener(
                "blur",
                event => {
                    // 在document上，监听blur事件
                    if (["input", "textarea"].includes(event.target.localName)) {
                    // eslint-disable-next-line
                    document.body.scrollTop = document.body.scrollTop;
                    }
                },
                true
                );
            }
        },
        // 是否要显示合同的页面
        async isShowSign(routerPath) {
            if (
                ROUTER_NEED_SIGN.find(ele => {
                    return ele === routerPath;
                })
            ) {
                // this.needSign = true;
                //需要的时候再去调用接口，因为无法保证幂等性（多端登录的时候，可能在别的手机已经校验了）
                const { needSigned, content, systemIds } = await isNeedSign();
                console.log(needSigned);
                // 将合同保存在sessionstorage中
                sessionStorage.setItem("signContent", content);
                sessionStorage.setItem("signsystemIds", JSON.stringify(systemIds));
                this.needSign = needSigned;
            } else {
                this.needSign = false;
            }
        },
        /**
         * 配置微信
         */
        async configWeixin() {
            if (process.env.NODE_ENV === "dev") return;
            const configParams = await this.$store.dispatch("wechat/getJsConfig");
            wx.config(configParams);
            wx.error((err) =>{
                console.error('微信sdk配置错误：', err)
            })
        },

        /**
         * 点击购物车图标
         */
        onHomeCartClick() {
            //跳转到购物车页面
            this.$router.push({
                name: "two-cart"
            });
            // 滚动条恢复到顶部
            window.scrollTo(0, 0);
        },

        /**
         * 点击回首页
         */
        onHomeClick() {
            const { homeEntrance = "product-list" } = this;
            this.$router.push({ name: homeEntrance });
            // 滚动条恢复到顶部
            window.scrollTo(0, 0);
        }
    },

    async created() {
        await this.$store.dispatch("permission/getPermission");
    },

    mounted() {
        // this.configREM();
        this.solveLayout();
        this.configWeixin();
        this.isShowSign(this.$route.path);
    },
    watch: {
        $route: {
            handler(newRoute, oldRoute) {
                this.isShowSign(newRoute.path);
            }
        }
    }
};
</script>

<style lang="less">
@import "./assets/less/reset.less";
@import "./assets/less/vant-reset.less";
@import "./assets/less/mixin.less";
@import "./assets/less/common.css";

#app {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    user-select: none;
    -webkit-user-select: none;
    font-family: PingFang SC;

    .mask {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 2005; // 遮挡万物

        .van-loading {
            position: absolute;
            top: 45%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 10px;
            border-radius: 3px;
            background-color: rgba(0, 0, 0, 0.5);
        }
    }
}

// 根页面适配iPhoneX
// 降低样式优先级
.app > div {
    box-sizing: border-box;
    min-height: 100vh;
    .safe-padding-bottom(
        1px
    ); // @todo 这里应该是0的，但是转化后的calc(x + 0)不合法
}

.cart-entrance {
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: -1px 1px 12px 1px rgba(32, 32, 32, 0.07);
    border-radius: 50%;
    box-sizing: border-box;
    color: @primary-color;

    .iconfont {
        font-size: 24px;
        color:@primary-color;
    }
    /*   background-image: url("./assets/images/common/home-cart.png");
    background-repeat: no-repeat;
    background-size: 28px 28px;
    background-position: center center; */

    /*   &.home {
        background-image: url("./assets/images/common/home.png");
        background-size: 30px 30px;
    } */
}
</style>