import { mapState } from 'vuex';
import { userPoint, pointRecord } from '@/service/integral';

export default{
    data(){
        return{
            nav:[
                { 
                    name: '我的积分', 
                    icon: 'icon1-1.png', 
                    icon2: 'icon1-2.png', 
                    bg: 'integral-bg.png',
                    flag: 'POINT',
                    tab:[
                        { name: '全部', value: null },
                        { name: '收入', value: 'INCREASE' },
                        { name: '支出', value: 'REDUCE' }
                    ],
                },
                { 
                    name: '我的名额', 
                    icon: 'icon2-1.png', 
                    icon2: 'icon2-2.png', 
                    bg: 'integral-bg2.png',
                    flag: 'QUOTA',
                    tab:[
                        { name: '全部', value: null },
                        { name: '增加', value: 'INCREASE' },
                        { name: '消耗', value: 'REDUCE' }
                    ],
                }
            ],
            showTip: false, //显示提示
            curNav: 0,
            curActive: 0,
            totalPoint: {
                point: 0,
                quota: 0
            },
            curPage: 1,
            finished: false, // 是否已经加载完成
            loading: true, // 是否处于加载状态
            orderData: []
        }
    },
    computed:{
        ...mapState({
            userId: state => state.user.user.id
        }),
    },
    mounted(){
        this.init()
    },
    methods:{
        init(){
            this.curActive = 0;
            this.orderData = [];
            this.curPage = 1;
            this.loading = true;
            this.finished = false;
            this.getUserPoint();
            this.getPointRecordList();
        },
        // 获取总积分
        async getUserPoint(){
            let { userId } = this;
            const res = await userPoint(userId)
            this.totalPoint = res
        },

        // 获取列表
        async getPointRecordList(){
            let { orderData, curPage, curActive, nav, curNav, userId } = this;
            let params = {
                curPage: curPage,
                limit: 10,
                type: nav[curNav].tab[curActive].value,
                flag: nav[curNav].flag,
                userId
            }

            const res = await pointRecord(params)
            this.loading = false
            this.orderData = [...orderData, ...res.records]

            if (res.records.length < 10) this.finished = true
            else this.finished = false
        },

        onLoad(){
            console.log(2121212)
            this.curPage ++
            this.getPointRecordList()
        },

        // 切换nav
        onNav(index){
            this.curNav = index
            this.init()
        },

        // 点击tab
        onTab(index){
            this.curActive = index
            this.curPage = 1
            this.orderData = []
            this.getPointRecordList()
        }
    }
}