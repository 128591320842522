export default [
    {
      path: '/auth/my-agents',
      name: 'my-agents',
      component: () => import( /* webpackChunkName: "auth" */ '@/pages/auth/my-agents/index.vue'),
      meta: {
        name: '我的团队',
        description: '授权-我的代理',
        keepAlive: [
          'agent-detail',
          'add-agent',
          'agent-search',
          'add-invitation'
        ]
      }
    },
    {
      path: '/auth/add-agent',
      name: 'add-agent',
      component: () => import( /* webpackChunkName: "auth" */ '@/pages/auth/add-agent/index.vue'),
      meta: {
        name: '新增代理',
        description: '授权-新增代理'
      }
    },
    {
      path: '/auth/upgrade-agent',
      name: 'upgrade-agent',
      component: () => import( /* webpackChunkName: "auth" */ '@/pages/auth/upgrade-agent/index.vue'),
      meta: {
        name: '升级代理',
        description: '授权-升级代理'
      }
    },
    {
      path: '/auth/agent-home',
      name: 'agent-home',
      component: () => import( /* webpackChunkName: "auth" */ '@/pages/auth/agent-home/index.vue'),
      meta: {
        name: '我的授权',
        description: '授权-代理详情',
        keepAlive: [
          // 'upgrade-agent'
        ]
      }
    },
    {
      path: '/auth/agent-detail',
      name: 'agent-detail',
      component: () => import( /* webpackChunkName: "auth" */ '@/pages/auth/agent-detail/index.vue'),
      meta: {
        name: '代理详情',
        description: '授权-代理详情',
        keepAlive: [
          // 'upgrade-agent'
        ]
      }
    },
    {
      path: '/auth/add-agent/result',
      name: 'add-agent-result',
      component: () => import( /* webpackChunkName: "auth" */ '@/pages/auth/add-agent/result/index.vue'),
      meta: {
        name: '添加代理结果',
        description: '授权-添加代理结果',
      }
    },
    // 升级授权结果
    {
      path: '/auth/upgrade-agent/result',
      name: 'upgrade-agent-result',
      component: () => import( /* webpackChunkName: "auth" */ '@/pages/auth/upgrade-agent/result/index.vue'),
      meta: {
        name: '升级代理',
        description: '升级代理结果',
      }
    },
    {
      path: '/auth/my-application',
      name: 'my-application',
      component: () => import( /* webpackChunkName: "auth" */ '@/pages/auth/my-application/index.vue'),
      meta: {
        name: '我的申请',
        description: '我的申请情况',
      }
    },
    {
      path: '/auth/my-review',
      name: 'my-review',
      component: () => import( /* webpackChunkName: "auth" */ '@/pages/auth/my-review/index.vue'),
      meta: {
        name: '我的审核',
        description: '我的审核列表',
      }
    },
  
    {
      path: '/auth/my-application-detail',
      name: 'my-application-detail',
      component: () => import( /* webpackChunkName: "auth" */ '@/pages/auth/my-application/detail/index.vue'),
      meta: {
        name: '我的申请详情',
        description: '我的申请详情'
      }
    },
    {
      path: '/auth/agent-invite',
      name: 'agent-invite',
      component: () => import( /* webpackChunkName: "auth" */ '@/pages/auth/agent-invite/index.vue'),
      meta: {
        name: '二维码分享邀请代理',
        description: '二维码分享邀请代理',
        wxShare: true
      }
    },
    {
      path: '/auth/agent-search',
      name: 'agent-search',
      component: () => import( /* webpackChunkName: "auth" */ '@/pages/auth/agent-search/index.vue'),
      meta: {
        name: '授权查询',
        description: '授权查询',
      }
    },
    {
      path: '/auth/my-invitation',
      name: 'my-invitation',
      component: () => import( /* webpackChunkName: "auth" */ '@/pages/auth/my-invitation/index.vue'),
      meta: {
        name: '我的邀请',
        description: '我的团队-邀请列表',
        keepAlive: [
          'agent-detail',
          'add-agent',
          'agent-search',
        ]
      }
    },
    {
      path: '/auth/add-invitation',
      name: 'add-invitation',
      component: () => import( /* webpackChunkName: "auth" */ '@/pages/auth/add-invitation/index.vue'),
      meta: {
        name: '新增推荐',
        description: '我的团队-邀请列表',
      }
    },
    {
      path: '/auth/redirect-agent-invite',
      name: 'redirect-agent-invite',
      component: () => import( /* webpackChunkName: "auth" */ '@/pages/auth/redirect-agent-invite/index.vue'),
      meta: {
        name: '邀请经销商跳转中',
        description: '邀请经销商跳转中',
      }
    },
    {
      path: '/auth/my-team',
      name: 'my-team',
      component: () => import( /* webpackChunkName: "auth" */ '@/pages/auth/my-team/index.vue'),
      meta: {
        name: '我的团队',
        description: '我的团队',
      }
    },
    {
      path: '/auth/my-apply-new',
      name: 'my-apply-new',
      component: () => import( /* webpackChunkName: "auth" */ '@/pages/auth/my-apply-new/index.vue'),
      meta: {
        name: '我的申请',
        description: '我的申请',
      }
    }
  ]