/***
 * 国庆促销暂时的工具类
 *
 */
export const getFormats = products => {
    if (!products) {
        return [];
    }

    let formats = [];

    for (let i = 0; i < products.length; i++) {
        const p = products[i];
        if (p.formats) {
            const pFormates = p.formats;
            for (let i = 0; i < pFormates.length; i++) {
                const pf = pFormates[i];
                const { id, count } = pf;
                formats.push({ id, count });
            }
        }
    }

    return formats;
};

export const getFormatsCount = products => {
    if (!products) {
        return 0;
    }

    let sum = 0;

    for (let i = 0; i < products.length; i++) {
        const p = products[i];
        if (p.formats) {
            const pFormates = p.formats;
            for (let i = 0; i < pFormates.length; i++) {
                const pf = pFormates[i];
                const { count } = pf;
                sum += count;
            }
        }
    }

    return sum;
};

export const setFormats = products => {
    if (!products) {
        return [];
    }

    for (let i = 0; i < products.length; i++) {
        const p = products[i];
        if (p.formats) {
            const pFormates = p.formats;
            for (let i = 0; i < pFormates.length; i++) {
                const pf = pFormates[i];
                pf.count = 0;
            }
        }
    }
};

