import { Toast, Dialog } from "vant";
import AddressPicker from "@/components/common/address-picker/index.vue";
import {
  addRetailAddress,
  updateRetailAddress,
} from "@/service/retail-order/index.js";
import { getToken } from "@/utils/localStorage.js";

export default {
  name: "address-edit",

  components: {
    AddressPicker,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    region: {
      handler() {
        if (!this.fromRetail) {
          this.provinceCityRegion = this.region.id
            ? `${this.region.city.province.name} / ${this.region.city.name} / ${this.region.name}`
            : "";
        } else {
          this.provinceCityRegion = this.region.id
            ? `${this.region.city.province.name}${this.region.city.name}${this.region.name}`
            : this.provinceCityRegion;
        }
        this.address.regionId = this.region.id;
      },
      deep: true,
    },
  },

  computed: {
    validate() {
      return Object.values(this.address).every((item) => {
        return item !== "" && item !== null && item !== undefined;
      });
    },
  },

  data() {
    return {
      disabled: false,
      fromRetail: false, //  从零售页面过来的
      address: {
        id: "",
        name: "",
        phone: "",
        region: [],
        regionId: "",
        detail: "",
        isDefault: 0, //  非默认
        fullAddress: "",
      },
      region: {},
      provinceCityRegion: "",
      rules: {
        name: {
          required: true,
          message: "请输入姓名",
          validator: (value) => !!value,
        },
        phone: {
          required: true,
          message: "请输入合法的手机号",
          validator: (value) => !!value && value.length === 11,
        },
        regionId: {
          required: true,
          message: "请选择地区",
          validator: (value) => {
            if (!this.fromRetail) {
              return value !== null && value !== undefined && value !== "";
            } else {
              return (
                (value !== null && value !== undefined && value !== "") ||
                this.provinceCityRegion
              );
            }
          },
        },
        detail: {
          required: true,
          message: "请输入详细地址",
          validator: (value) => !!value,
        },
      },
    };
  },

  methods: {
    showAreaPopup() {
      this.$refs["addressPicker"].open();
    },

    getErrorMessage(key) {
      const value = String(this.address[key] || "").trim();

      if (this.validator) {
        const message = this.validator(key, value);
        if (message) {
          return message;
        }
      }
    },

    setAddressRegion(address) {
      this.address.region = address;
      this.region = address;
    },

    cancel() {
      this.$router.go(-1);
    },

    async submit() {
      // 校验
      for (const key in this.rules) {
        const rule = this.rules[key];
        if (rule.required && !rule.validator(this.address[key])) {
          Toast({ message: rule.message });
          return;
        }
      }

      const { type } = this.$route.query;
      const { name, phone, detail, id, factorId } = this.address;
      const { provinceCityRegion } = this;

      if (type === "retail-add") {
        //  零售页面的增加地址
        await addRetailAddress({
          id,
          token: getToken(),
          name,
          phone,
          addressInfo: {
            address: provinceCityRegion,
            detailedAddress: detail,
          },
        });
      } else if (type === "retail-update") {
        //  零售页面的修改地址
        await updateRetailAddress({
          id,
          token: getToken(),
          name,
          phone,
          factorId,
          addressInfo: {
            address: provinceCityRegion,
            detailedAddress: detail,
          },
        });
      } else if (this.$route.query.type === "edit") {
        // 编辑
        await this.$store.dispatch("user/setAddress", {
          addressId: this.address.id,
          name: this.address.name,
          phone: this.address.phone,
          regionId: this.address.regionId,
          detail: this.address.detail,
          isDefault: this.address.isDefault,
        });
      } else {
        await this.$store.dispatch("user/addAddress", {
          // 新增
          name: this.address.name,
          phone: this.address.phone,
          regionId: this.address.regionId,
          detail: this.address.detail,
          isDefault: this.address.isDefault,
        });
      }

      this.$router.go(-1);
    },

    // 删除地址
    async deleteAddress() {
      const addressId = this.address.id;
      try {
        await Dialog.confirm({
          message: "确认要删除这个地址吗？",
        });
        await this.$store.dispatch("user/deleteAddress", { addressId });
        this.$router.go(-1);
      } catch (e) {}
    },

    // 设为默认地址
    async setAsDefault() {
      const { address } = this;
      if (address.isDefault) return;
      try {
        await Dialog.confirm({ message: "确认将此地址设置为默认地址？" });
        // await this.$store.dispatch('user/setDefaultAddress', { addressId: address.id })
        this.$set(address, "isDefault", 1);
      } catch (e) {
        this.$set(address, "isDefault", 0);
      }
    },
  },

  async created() {
    if (this.$route.query.type === "edit") {
      this.address = this.$store.state.address.address;
      this.region = this.address.region;
    } else if (this.$route.query.type === "retail-update") {
      const {
        name,
        phone,
        provinceCityRegion,
        detail,
        id,
        factorId,
      } = this.$route.query;
      this.address = { name, phone, detail, id, factorId };
      this.provinceCityRegion = provinceCityRegion;
    }
    const { type } = this.$route.query;
    this.fromRetail = type ? type.indexOf("retail") >= 0 : false;
  },
};
