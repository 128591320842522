/**
 * 列表为空时的提示
 */
export default {
    name: 'empty-list',
    props: {
        title: {
            // 提示语
            type: String,
            default: '暂无数据',
        },
    },
};
