import { mapState } from 'vuex'
import { getDiscountPrice, checkMessMinimum, convertUnit2LimitUnit } from '@/utils/common'
import { StockType } from '@/constants/common'
import UploadImage from '@/components/common/upload-image/index.vue'
import FormatItem from '@/components/common/format-item/index.vue'
import Rmb from '@/components/common/rmb/index.vue'
import { Dialog, Toast } from 'vant'

async function alert (message) {
  // await Dialog.alert({ message })
  Toast(message)
}

/**
 * 提交入库单页面
 */
export default {
  name: 'stockin-preorder',

  components: {
    UploadImage,
    FormatItem,
    Rmb,
  },

  data () {
    return {
      validCouponCount: 0
    }
  },

  computed: {
    ...mapState({
      order: state => state.preorder, // 预订单
      cart: state => state.cart.cart[StockType.StockIn],
      brandId: state => state.global.brandId,
      brand: state => state.stock.brand
    }),
    totalCouponsPrice () {
      if (this.order.coupons) {
        return this.order.coupons.reduce((total, item) => total + item.coupon.discount, 0)
      }
      return 0
    }
  },

  methods: {
    getDiscountPrice,

    async init () {
      await this.getValidCoupons()
    },

    // 获取有效优惠券
    async getValidCoupons () {
      try {
        const data = await this.$http.post(`orders/coupon/valid`, {
          brandId: this.brandId,
          products: this.order.products.map(item => {
            return {
              id: item.id,
              price: this.getPriceByProduct(item, item.discount, item.userRank)
            }
          })
        })
        this.validCouponCount = data.count
      } catch (e) { }
    },

    getPriceByProduct (product, discount, userRank) {
      return product.formats.reduce((result, item) => result + getDiscountPrice(discount, userRank, item.price) * item.count, 0)
    },

    // 更新购物车
    async updateCart () {
      const cartProducts = this.cart[this.brandId] || []
      const products = cartProducts.filter(product => {
        for (const item of this.order.products) {
          if (item.id === product.id) {
            for (const key in product.formats) {
              const index = item.formats.findIndex(format => format.id === key)
              if (index !== -1) {
                product.count -= product.formats[key].count
                delete product.formats[key]
              }
            }
          }
        }
        return Object.values(product.formats).length
      })
      await this.$store.dispatch('cart/updateCart', {
        type: StockType.StockIn,
        brandId: this.brandId,
        products
      })
    },

    // 提交
    async payNow () {
      if (this.order.images.length === 0) {
        await alert('请先上传打款凭证~')
        return
      }

      await this.$store.dispatch('stock/getBrand', { brandId: this.brandId })
      if (this.order.products.reduce((result, item) => result + convertUnit2LimitUnit(item), 0) < this.brand.minStockIn) {
        await alert('结算商品未满足最小进货量~')
        return
      }

      const validProducts = await this.$store.dispatch('cart/checkValid', this.order.products)
      try {
        if (validProducts.some(product => product.changed || product.formats.some(format => format.changed))) {
          await alert('支付商品中包含已失效的商品，请返回重新下单!')
          await this.$store.dispatch('cart/getCart', { type: StockType.StockIn })
          return
        }

        let message = ''
        this.order.products.forEach(product => {
          message += `<p><span class="name">${product.name}</span><span class="value">x ${product.count}</span></p>`
        })
        message += `<p><span class="name">总计</span><span class="value">￥${this.$fen2Yuan(this.order.totalPrice - this.totalCouponsPrice)}</span></p>`
        await Dialog.confirm({
          message,
          title: '产品清单'
        })

        // 调接口
        await this.$store.dispatch('stock/placeStockInOrder', {
          brandId: this.brandId,
          totalPrice: this.order.totalPrice,
          products: this.order.products,
          gifts: this.order.gifts,
          experiences: this.order.samples,
          receipts: this.order.images.map(val => val.serverId),
          remark: this.remark,
          coupons: this.order.coupons ? this.order.coupons.map(item => item.id) : [],
          discount: this.totalCouponsPrice
        })
        // 更新购物车
        await this.updateCart()
        // 重置预订单
        await this.$store.dispatch('preorder/reset')
        // 跳转下单成功
        this.$router.replace({
          name: 'pay-success',
          query: {
            type: StockType.StockIn
          }
        })
      } catch (e) {
        console.error(e)
      }
    },

  },

  async created () {
    await this.init()
  },

  async activated () {
    await this.init()
  }
}
