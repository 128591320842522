/**
 * vant删减版步进器，支持加减步数不一致
 * reference: https://github.com/youzan/vant/blob/dev/src/stepper/index.js
 */
import { isDef } from '@/utils/common.js'

function equal(value1, value2) {
    return String(value1) === String(value2);
}

// add num and avoid float number
function add(num1, num2) {
    const cardinal = 10 ** 10;
    return Math.round((num1 + num2) * cardinal) / cardinal;
}

export default {
    name: 'custom-stepper',

    model: {
        prop: 'value',
        event: 'change'
    },

    props: {
        value: null,
        integer: Boolean,
        disabled: Boolean,
        disablePlus: Boolean,
        disableMinus: Boolean,
        disableInput: Boolean,
        id: {
            type: [Number, String],
            default: '',
        },
        systemId: {
            type: [Number, String],
            default: '',
        },
        productId: {
            type: [Number, String],
            default: '',
        },
        formatId: {
            type: [Number, String],
            default: '',
        },
        min: {
            type: [Number, String],
            default: 1,
        },
        max: {
            type: [Number, String],
            default: Infinity,
        },
        minusStep: {
            type: [Number, String],
            default: 1,
        },
        plusStep: {
            type: [Number, String],
            default: 1,
        },
        defaultValue: {
            type: [Number, String],
            default: 0,
        },
        bigSize: {
            required: false, // 是否加大按钮
            type: Boolean,
            default: false
        }
    },

    data() {
        const defaultValue = isDef(this.value) ? this.value : this.defaultValue;
        const value = this.format(defaultValue);

        if (!equal(value, this.value)) {
            this.$emit('input', value);
        }

        return {
            currentValue: value,
        };
    },

    computed: {
        minusDisabled() {
            return (
                this.disabled || this.disableMinus || this.currentValue <= this.min
            );
        },

        plusDisabled() {
            return this.disabled || this.disablePlus || this.currentValue >= this.max;
        },
    },

    watch: {
        max: 'check',
        min: 'check',
        integer: 'check',

        value(val) {
            if (!equal(val, this.currentValue)) {
                this.currentValue = this.format(val);
            }
        },

        currentValue(val) {
            this.$emit('input', val);
            this.$emit('change', val, { systemId: this.systemId, productId: this.productId, formatId: this.formatId, id: this.id });
        },
    },

    methods: {
        check() {
            const val = this.format(this.currentValue);
            if (!equal(val, this.currentValue)) {
                this.currentValue = val;
            }
        },
        // filter illegal characters
        filter(value) {
            value = String(value).replace(/[^0-9.-]/g, '');

            if (this.integer && value.indexOf('.') !== -1) {
                value = value.split('.')[0];
            }

            return value;
        },

        format(value) {
            value = this.filter(value);
            // format range
            value = value === '' ? 0 : +value;
            value = Math.max(Math.min(this.max, value), this.min);
            return value;
        },

        onFocus(event) {
            this.$emit('focus', event);
        },

        onBlur(event) {
            const value = this.format(event.target.value);
            event.target.value = value;
            this.currentValue = value;
            this.$emit('blur', event);
        },

        onInput(event) {
            const { value } = event.target;

            // allow input to be empty
            if (value === '') {
                return;
            }

            let formatted = this.filter(value);

            // limit max decimal length
            if (isDef(this.decimalLength) && formatted.indexOf('.') !== -1) {
                const pair = formatted.split('.');
                formatted = `${pair[0]}.${pair[1].slice(0, this.decimalLength)}`;
            }

            if (!equal(value, formatted)) {
                event.target.value = formatted;
            }

            this.emitChange(formatted);
        },

        emitChange(value) {  //  砍掉了asyn-change
            this.currentValue = value;
        },

        onChange() {
            const { type } = this;

            if (this[`${type}Disabled`]) {
                this.$emit('overlimit', type);
                return;
            }

            const diff = type === 'minus' ? -this.minusStep : +this.plusStep;

            const value = this.format(add(+this.currentValue, diff));

            this.emitChange(value);
            this.$emit(type);
        },

        onClickPlus() {
            this.type = 'plus';
            this.onChange();
        },

        onClickMinus() {
            this.type = 'minus';
            this.onChange();
        }
    }
}
