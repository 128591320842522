import { Http, HttpV1 } from '@/request'
import { removeToken, removePersisternStorage ,  getToken } from '@/utils/localStorage'
import wx from 'weixin-js-sdk';
export default {
    namespaced: true,
    state: {
        user: {},
        addresses: [],
        hasShowReminder: {}
    },
    mutations: {
        setUser(state, user) {
            state.user = user
        },
        lookReminder(state, brandId) {
            state.hasShowReminder[brandId] = true
        },
        setAddresses(state, addresses) {
            state.addresses = addresses
        },
        addAddress(state, address) {
            state.addresses.unshift(address)
        },
        deleteAddress(state, addressId) {
            const index = state.addresses.findIndex(item => item.id === addressId)
            state.addresses.splice(index, 1)
            if (state.lastAddress.id === addressId) {
                state.lastAddress = {}
            }
        }
    },
    getters: {
        userinfo(state) {
            return state.user;
        }
    },
    actions: {
        async getUserPoint({ commit }, { brandId }) {
            try {
                const res = await Http.get('stock/point/record', { brandId })
                return res
            } catch (e) { }
        },
        // 获取图形验证码
        async getCaptcha({ commit }, { phone }) {
            try {
                await Http.get(`login/captcha?phone=${phone}`)
            } catch (e) { }
        },
        // 获取短信验证码
        async getCode({ commit }, body) {
            await HttpV1.post('/agent/login/sms', body)
        },
        // 登录
        async login({ commit }, { brandId, phone, code, wechatToken, lottery }) {
            localStorage.setItem('wechatToken', wechatToken) //缓存wechatToken
            const data = await HttpV1.post('/agent/login', {
                brandId,
                phone,
                code,
                wechatToken,
                lottery
            })
            commit('setUser', data.user)
            return { token: data.token, user: data.user }
        },
        lookReminder({ commit }, brandId) {
            commit('lookReminder', brandId)
        },
        // 获取我的基本资料
        async getUserData({ commit }) {
            try {
                // get请求会被截断，所以使用post
                const data = await HttpV1.post(`/agent/user/token`, { token: getToken() })
                //  有数据的时候才更新本地数据
                if (data) {
                    commit('setUser', data)
                }else{
                    removeToken()
                    removePersisternStorage()
                    location.reload()
                    wx.closeWindow()
                }
            } catch (e) { }
        },
        // 获取我的地址
        async getAddresses({ commit }) {
            try {
                // 抽奖时候会把useId存放在sessionstorgae，而vuex中不会有userid
                // 如果sessionstorage中有userid，则从sessionstorage中获取，否则从vuex中获取
                const data = await HttpV1.get('user/addresses', { userId: sessionStorage.getItem("userId") || this.state.user.user.id })
                commit('setAddresses', data)
            } catch (e) { }
        },
        // 修改地址
        async setAddress({ commit }, { addressId, phone, name, regionId, detail, isDefault }) {
            try {

                await HttpV1.put(`user/addresses/update`, {
                    phone,
                    isDefault,
                    name,
                    id: addressId,
                    regionId,
                    detail, userId: sessionStorage.getItem("userId") || this.state.user.user.id
                })
                const data = await HttpV1.get('user/addresses', { isDefault, userId: sessionStorage.getItem("userId") || this.state.user.user.id })
                commit('setAddresses', data)
            } catch (e) { }
        },
        // 设置地址为默认地址
        async setDefaultAddress({ commit }, { addressId }) {
            try {
                // await HttpV1.put(`user/addresses/setDefault`, { userId: sessionStorage.getItem("userId") || this.state.user.user.id, addressId })
                await HttpV1.put(`user/addresses/setDefault?userId=${sessionStorage.getItem("userId") || this.state.user.user.id}&addressId=${addressId}`)
                const data = await HttpV1.get('user/addresses', { userId: sessionStorage.getItem("userId") || this.state.user.user.id })
                commit('setAddresses', data)
            } catch (e) { }
        },
        // 添加地址
        async addAddress({ commit }, { phone, name, regionId, detail, isDefault }) {
            try {
                const data = await HttpV1.post(`user/addresses`, {
                    phone,
                    name,
                    regionId,
                    isDefault,
                    detail, userId: sessionStorage.getItem("userId") || this.state.user.user.id
                })
                commit('addAddress', data)
            } catch (e) { }
        },
        // 删除地址
        async deleteAddress({ commit, state }, { addressId }) {
            try {
                await HttpV1.delete(`user/addresses/${addressId}`, { userId: sessionStorage.getItem("userId") || this.state.user.user.id })
                commit('deleteAddress', addressId)
            } catch (e) { }
        },
        // 退出登录
        async logout({ commit }) {
            try {
                await Http.put('user/logout')
            } catch (e) { }
        }
    }
}
