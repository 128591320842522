import { ROUTES_NO_NEED_TOKEN } from '@/constants/route';
import { Dialog, Toast } from 'vant';
import wx from 'weixin-js-sdk';
import store from '@/store';
import { getToken, removeToken, getBrandId } from '@/utils/localStorage';
import { toLogin } from '@/utils/common';

const WECHAT_URL = process.env.VUE_APP_WECHAT_URL;

/**
 * 拦截请求
 */
function interceptRequest(axiosInstance) {
    axiosInstance.interceptors.request.use(
        config => {
            store.commit('memory/addReqCount');
            const token = getToken();
            if (token) {
                config.headers.common['Authorization'] = `Bearer ${token}`;
            } else {
                // 没有token
                if (ROUTES_NO_NEED_TOKEN.every(path => config.url.indexOf(path) === -1)) {
                    // 而且不是免token接口
                    Dialog.alert({
                        message: '页面已失效，请重新登录',
                    }).then(() => {
                        // 跳转到登录页
                        // let brandId1 = getQueryVariable('brandId');
                        // let brandId2 = getBrandId();
                        // let brandId = brandId1 ? brandId1 : brandId2;
                        // toLogin(brandId);
                        window.location.href = WECHAT_URL;
                        // 就要关闭App
                        // wx.closeWindow();
                        // 如果是抽奖的页面,且无用户的信息，则需要跳转到登录页面
                    });
                }
            }
            return config;
        },
        error => {
            Toast('请求失败，请检查网络');
            return Promise.reject(error);
        }
    );
}

/**
 * 响应 - 错误处理
 */
function getResponseInterceptor() {
    return async error => {
        store.commit('memory/removeReqCount');
        const { data: body, status } = error.response || {};
        const { message } = body || {};
        // 判断状态码
        switch (status) {
            case 401:
                removeToken();
                //  todo,根据brandid和wetchatToken拼接新的login url
                await Dialog.alert({ message: '身份过期，请重新登录～' });
                wx.closeWindow();
                break;
            default:
                Toast(message || `请求失败（${status}）`);
                break;
        }
        return Promise.reject(error);
    };
}

/**
 * 输出统一接口
 */
function getInterface(axiosInstance) {
    return {
        get(url, query) {
            return axiosInstance.get(url, {
                params: query,
            });
        },
        post: axiosInstance.post.bind(axiosInstance),
        put: axiosInstance.put.bind(axiosInstance),
        patch: axiosInstance.patch.bind(axiosInstance),
        delete(url, query) {
            return axiosInstance.delete(url, {
                params: query,
            });
        },
        deleteWithBody(url, data) {
            return axiosInstance.delete(url, { data });
        },
    };
}

// 获取url参数
function getQueryVariable(qname) {
    var name,
        value,
        params = {};
    var str = location.href; //取得整个地址栏
    var num = str.indexOf('?');
    str = str.substr(num + 1); //取得所有参数   stringvar.substr(start [, length ]

    var arr = str.split('&'); //各个参数放到数组里
    console.log(arr);
    for (var i = 0; i < arr.length; i++) {
        num = arr[i].indexOf('=');
        if (num > 0) {
            name = arr[i].substring(0, num);
            value = arr[i].substr(num + 1);
            params[name] = value;
        }
    }
    return params[qname];
}

export { interceptRequest, getResponseInterceptor, getInterface };
