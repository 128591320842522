import dayjs from 'dayjs'
import { STOCK_ORDER_TYPE_VALUE, StockOrderType, StockOrderState, StockType } from '@/constants/common'
import { getDiscountPrice } from '@/utils/common'
import { pickBy, keyBy, flatten } from 'lodash'
import { mapState } from 'vuex'
import FormatItem from '@/components/common/format-item/index.vue'

const LIMIT = 10

/**
 * (出库)订单列表
 */
export default {
  name: 'stock-order',

  components: {
    FormatItem,
  },

  data () {
    const currentType = STOCK_ORDER_TYPE_VALUE.OUT // 出库

    return {
      StockType,
      currentType, // 当前类型（全部/出库/入库，这里是出库）
      stateList: StockOrderState[currentType], // 状态tab列表
      typeMap: {},
      stateMap: {},
      currentIndex: 0, // 当前Tab索引
      orderList: [], // 订单列表
      offset: 0, // 偏移量
      loading: false, // 加载中
      hasNoMore: false, // 全部加载完毕
    }
  },

  computed: {
    ...mapState({
      brandId: state => state.global.brandId
    })
  },
  
  methods: {
    getDiscountPrice,

    // 刷新列表
    async onRefresh () {
      this.hasNoMore = this.loading = false
      this.orderList = []
      this.offset = 0
      await this.getPage(this.offset)
    },

    // 加载更多
    async onLoadMore () {
      this.offset += LIMIT
      await this.getPage(this.offset)
    },

    // 请求数据总方法
    async getPage (offset) {
      const params = {
        brandId: this.brandId,
        type: Number(this.currentType),
        state: this.stateList[this.currentIndex].code,
        limit: LIMIT,
        offset
      }
      let orders = await this.$store.dispatch('stock/loadOrders', pickBy(params, value => value !== -1))
      orders = orders.map(item => {
        return {
          ...item,
          createdAt: dayjs(item.createdAt).format('YYYY-MM-DD HH:mm')
        }
      })
      this.hasNoMore = orders.length < LIMIT
      this.loading = false
      this.orderList.push(...orders)
    },

    // 跳转详情
    showOrder (order) {
      this.$router.push({
        name: 'stock-order-detail',
        query: {
          orderId: order.id
        }
      })
    },
    
    // 切换Tab
    async onChangeTab (newIndex) {
      this.currentIndex = newIndex
      await this.onRefresh()
    },

    // 跳转到“出库下单”页面
    async toStockout () {
      this.$store.commit('memory/setSubOrders', [])
      await this.$store.dispatch('preorder/reset')
      this.$router.push({ name: 'stockout-preorder' })
    }
  },

  async created () {
    this.stateMap = keyBy(flatten(Object.values(StockOrderState)), item => item.code)
    this.typeMap = keyBy(StockOrderType, item => item.code)
    await this.onRefresh()
  }
}
