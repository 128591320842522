// 合同签约模块的接口
import {
  HttpV1 as HTTP
} from '@/request'
import store from '@/store'
// 判断用户是否需要签约
export async function isNeedSign() {
  return await HTTP.get(`/job/agent/contract/need-sign`, {
    userId: store.state.user.user.id,
  })
}

