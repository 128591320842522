/*
 * @Author: fengyanjing
 * @Date: 2020-05-16 13:44:57
 * @Description:
 * @FilePath: /frontend/src/constants/rebate.js
 */
/**
 * 业绩
 */

// 折扣映射类型
export const REBATE_ID = {
    SHUI_GUANG_SHENG: 1, // 水光生
    YAN_XIAN_AN: 2, // 烟酰胺
    BAI_XI: 3, // 白皙
    UNDERWEAR: 4, // 内衣
};

export const REBATE_ID_MAP = {
    [REBATE_ID.YAN_XIAN_AN]: '烟酰胺', // 烟酰胺
    [REBATE_ID.SHUI_GUANG_SHENG]: '水光生', // 水光生
    [REBATE_ID.BAI_XI]: '白皙', // 白皙
    [REBATE_ID.UNDERWEAR]: '能量内衣', // 内衣
};

export const defaultValue = '--';

// 实力瘦折扣映射类型
export const SHILISHOU_REBATE_MAP = {
    gs: { level: '1', levelText: '青葱公司' },
    gd: { level: '2', levelText: '股东' },
    lhcsr: { level: '3', levelText: '联合创始人' },
    zlhhr: { level: '4', levelText: '战略合伙人' },
    qgzd: { level: '5', levelText: '全国总代' },
    bjvip: { level: '6', levelText: '铂金VIP' },
    tgz: { level: '7', levelText: '团购长' },
};

//  实力瘦等级字典
export const SHILISHOU_LEVEL_MAP = {
    '1': '青葱公司',
    '2': '创始股东',
    '3': '联合创始人',
    '4': '战略合伙人',
    '5': '团购长',
    '6': '铂金VIP',
};

//  根据等级跳转到对应的一级页面
export function getRouteNameByLevel(levelId) {
    switch (levelId) {
        case '1':
            return 'shilishou-share-holders-rebate-home';
        case '2':
            return 'shilishou-share-holders-rebate-home';
        case '3':
            return 'shilishou-co-founder-rebate-home';
        case '4':
            return 'shilishou-strategy-rebate-home';
        default:
            return 'shilishou-share-holders-rebate-home';
    }
}
