import { mapState } from 'vuex'
import { Dialog } from 'vant'
import GeneralScroll from '@/mixins/general-scroll'

/**
 * 地址列表页面。说明：
 * 来源页面有：
 * - 提交订单
 * - 个人中心
 * 暂以`route.query.from`区分。
 */
export default {
  name: 'address-list',

  mixins: [GeneralScroll],

  data() {
    return {
      from: null, // 来源页面
      addressId: null, // 来源addressId
    }
  },

  computed: {
    userinfo() {
      return this.$store.state.user.user;
    },
    ...mapState({
      myAddresses: state => state.user.addresses,
      subOrders: state => state.memory.subOrders
    }),
  },

  methods: {
    wrapRegion(region) {
      return this.$getFullAddress(region)
    },

    // 选中地址
    async selectAddress(address) {
      this.addressId = address.id

      if (this.from) {
        const { from } = this.$route.query
        // 如果是双十一抽奖等过来的
        if (['eleven_lottery_2019', 'eleven_prize_list_2019'].includes(from)) {
          const { record } = this.$route.query
          try {
            await this.$messagebox.confirm(
              `是否确定收货地址？（确定后将不可修改）`
            )
            await this.$http1.put(`/elevenact/prize-address`, {
              recordId: record,
              addressId: address.id
            })
            await this.$messagebox.alert('选择地址成功！')
            // 回到上一个页面
            this.$router.go(-1)
          } catch (e) {
            // 这里是点击了取消
          }
        }
        else if (['gift/regist_gift'].includes(from)) {
          try {
            await this.$messagebox.confirm(
              `是否确定收货地址？`
            )
            this.$router.replace({
              path: '/gift/regist-gift',
              query: {
                address: encodeURI(JSON.stringify(address))
              }
            })
          } catch (e) {
            console.error(e);
            // 这里是点击了取消
          }
        }
        // 从抽奖页面过来 2020 10 新抽奖页面
        else if(['luck-draw-2020-10'].includes(from)){
          this.$store.commit('luckDraw/setAddress', { address })
          this.$router.go(-1)
        }
        // 云仓库页面过来
        else if(['cloudAddress'].includes(from)){
          this.$router.replace({
            path: '/cloudWarehouse/cloudAddress',
            query: {
              order: this.$route.query.order,
              address: encodeURI(JSON.stringify(address))
            }
          })
          this.$router.go(-1)
        }
        // 其他的情况
        else {
          this.$store.commit('preorder/setField', { address })
          const newSubOrders = [{
            ...this.subOrders,
            address
          }]
          this.$store.commit('memory/setSubOrders', newSubOrders)
          await this.$store.dispatch('memory/mockRequest')

          this.$router.back()
        }
      }
    },

    go2Detail(type) {
      const query = {
        type
      }
      if (this.from === 'pre-order') {
        query.from = this.from
      }
      this.$router.push({
        name: 'address-edit',
        query
      })
    },

    // 编辑地址
    editAddress(address) {
      this.$store.commit('address/setEditingAddress', address)
      this.go2Detail('edit')
    },

    // 新增地址
    createAddress() {
      this.go2Detail('create')
    },

    // 删除地址
    async deleteAddress(address) {
      const addressId = address.id
      await Dialog.confirm({
        message: '确认要删除这个地址吗？'
      })
      await this.$store.dispatch('user/deleteAddress', { addressId })
    },

    // 设为默认地址
    async setAsDefault(address) {
      if (address.isDefault) return

      if (this.selectable) {
        await Dialog.confirm({ message: '确认将此地址设置为默认地址并使用？' })
        await this.selectAddress(address)
      } else {
        await Dialog.confirm({ message: '确认将此地址设置为默认地址？' })
      }

      await this.$store.dispatch('user/setDefaultAddress', { addressId: address.id })
    },
  },

  async created() {
    this.from = this.$route.query.from
    this.addressId = this.$route.query.addressId

    await this.$store.dispatch('user/getAddresses')
  }
}