import dayjs from 'dayjs'
import { getDiscountPrice } from '@/utils/common'
import FormatItem from '@/components/common/format-item/index.vue'
import Rmb from '@/components/common/rmb/index.vue'
import CustomButton from '@/components/common/custom-button/index.vue'
import UploadImage from '@/components/common/upload-image/index.vue'
import { mapState } from 'vuex'

export default {
  name: 'order-detail',
  components: {
    FormatItem,
    Rmb,
    CustomButton,
    UploadImage
  },

  props: {
    order: {
      type: Object,
      default: () => { return {} }
    },
    showExpress: {
      type: Boolean,
      default: true
    },
    showSum: {
      type: Boolean,
      default: true
    },
    showDetail: {
      type: Boolean,
      default: false
    }
  },

  filters: {
    getStateText: function (state, states) {
      const stateObj = states.find(s => s.state === state)
      return stateObj ? stateObj.name : ''
    }
  },

  computed: {
    ...mapState({
      showReceipt: state => state.permission.permission.order.receipt,
      orderStates: state => state.order.orderStates
    }),
  },

  data () {
    return {}
  },

  watch: {
    orderStates: {
      async handler (newStates, old) {
        if (!newStates.length) {
          await this.$store.dispatch('order/getOrderStates')
        }
      },
      immediate: true
    }
  },

  methods: {
    getDiscountPrice,

    onClick (order) {
      this.$emit('onclick', order)
    },

    formatDate (date) {
      if (!date) {
        return '暂无'
      }
      return dayjs(date).format('YYYY-MM-DD HH:mm')
    },

    //  点击跳转到快递查询单号
    toExpress (item, e) {
      e.preventDefault();
      window.location.href = `https://m.kuaidi100.com/result.jsp?nu=${item.trackingNumber}&com=${item.com}`;
    },

    onClickLink (item, e) {
      e.preventDefault()
    }
  },
}