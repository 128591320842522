import { mapState } from 'vuex';
import Rmb from '@/components/common/rmb/index.vue';
import { userDeposit } from '@/service/deposit';

export default {
    name: 'my-deposit',

    components: {
        Rmb
    },

    data() {
        return {
            totalRmb: 0,
        };
    },

    computed: {
        ...mapState({
            userId: state => state.user.user.id,
        }),
    },

    mounted() {
        this.init();
    },

    methods: {
        init() {
            this.getUserDeposit();
        },

        // 获取总积分
        async getUserDeposit() {
            let { userId } = this;
            const res = await userDeposit(userId);
            this.totalRmb = res;
        },
    },
};
