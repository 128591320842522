import walletEmpty from '@/components/common/wallet-empty/index.vue'
import { getDeposit } from '@/service/user/wallet.js'
export default {
  name: 'walletList',
  data() {
    return {
      total: 0,//用户预存金额
      showData: null,//详情
      active: 0//当前选中的tab
    }
  },
  components: {
    walletEmpty
  },
  async mounted() {
    this.total = await getDeposit();

  },
  methods: {
    onChange(event) {
    }
  }
}