/**
 * 金额展示
 */
export default {
    name: 'rmb',
    props: {
        value: { // 值
            type: [Number, String],
            default: 0
        },
        extra: { // 额外内容（后面）
            type: String,
            default: ''
        },
        needFen2Yuan: { //是否需要将分转换为元
            type: Boolean,
            default: false
        },
        isNegative: { //  是否是负数
            type: Boolean,
            default: false
        }
    }
}
