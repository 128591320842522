import { sumBy } from 'lodash'
import { Http } from '@/request'
import { mapState } from 'vuex';
import { Toast } from 'vant';
import { getPriceByProduct } from '@/utils/common'
import rmb from '@/components/common/rmb/index.vue'
import CustomStepper from '@/components/common/custom-stepper/index.vue'

function scroll (selector) {
  const el = document.querySelector(selector)
  if (el) {
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    })
  }
}

/**
 * 选择赠品
 */
export default {
  name: 'choose-gift',

  components: {
    rmb,
    CustomStepper
  },

  filters: {
    getMinStep: function (product) {
      if (product.minStep) {
        return product.minstep[product.userRank]
      }
      if (product.formats) {
        const format = product.formats[0]
        return Math.min(JSON.parse(format.minStep)[format.userRank], format.inventory)
      }
      return 1
    },

    getMinusStep: function (product) {
      if (product.decMinStep && Object.keys(product.decMinStep).length) {
        return product.decMinStep[product.userRank]
      }
      if (product.minStep) {
        return product.minstep[product.userRank]
      }
      if (product.formats) {
        const format = product.formats[0]
        return Math.min(JSON.parse(format.minStep)[format.userRank], format.inventory)
      }
      return 1
    }
  },

  data () {
    return {
      products: [],
      demandGiftCount: 0, // 赠品要选的数量
      demandSampleCount: 0, // 体验装要选的数量
      demandPlusProductCount: 0, // 体验装要选的数量
      customStep: 1, // 选择赠品的步长
    }
  },

  computed: {
    ...mapState({
      brandId: state => state.global.brandId,
      gifts: state => state.sgs.gifts,
      samples: state => state.sgs.samples,
      plusProducts: state => state.sgs.plusProducts,
    }),

    totalGiftCount () {
      return this.$store.getters['sgs/totalGiftCount']
    },

    totalSampleCount () {
      return this.$store.getters['sgs/totalSampleCount']
    },

    totalPlusProductCount () {
      return this.$store.getters['sgs/totalPlusProductCount']
    },

    giftOK () {
      return this.totalGiftCount === this.demandGiftCount
    },

    sampleOK () {
      return this.totalSampleCount === this.demandSampleCount
    },

    plusProductOK () {
      return this.totalPlusProductCount === this.demandPlusProductCount
    },

    done () {
      return this.giftOK && this.sampleOK
    }
  },

  methods: {
    onConfirm () {
      if (!this.giftOK) {
        Toast('亲，您还有赠品没挑选完哦～')
        scroll('.gift-wrapper')
        return
      }

      if (!this.sampleOK && this.samples && this.samples.length) {
        Toast('亲，您还有体验装没挑选完哦～')
        scroll('.sample-wrapper')
        return
      }

      const tip = this.samples && this.samples.length ? '成功选择赠品与体验装！' : '成功选择赠品！'
      Toast(tip)
      this.$router.back()
    },

    onOverGift () {
      if (this.giftOK) {
        Toast(`亲，赠品最多只能选${this.demandGiftCount}套哦～`)
      }
    },

    onOverSample () {
      if (this.sampleOK) {
        Toast(`亲，体验装最多只能选${this.demandSampleCount}套哦～`)
      }
    },

    onOverPlusProduct () {
      if (this.plusProductOK) {
        Toast(`亲，加购装最多只能选${this.demandPlusProductCount}套哦～`)
      }
    },

    getPriceByProduct (product, discount, userRank) {
      return getPriceByProduct(product, discount, userRank)
    },

    getSumPlusProductsPrice () {
      const { plusProducts } = this
      return plusProducts.reduce((prev, product) => {
        return prev + getPriceByProduct(product, product.discount, product.userRank || 0)
      }, 0);
    }
  },

  async created () {
    const {
      demandGiftCount,
      demandSampleCount,
      demandPlusProductCount,
    } = this.$route.query
    this.demandGiftCount = Number(demandGiftCount)
    this.demandSampleCount = Number(demandSampleCount)
    this.demandPlusProductCount = Number(demandPlusProductCount)
  },

  mounted () {
  },
}
