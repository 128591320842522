var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('van-tabbar',{staticClass:"index-tabbar",attrs:{"active-color":"#FF5656","value":_vm.activeIndex,"safe-area-inset-bottom":""},on:{"change":_vm.onTabChange}},_vm._l((_vm.menus),function(item,index){return _c('van-tabbar-item',{key:index,class:[`tab-${index}`],scopedSlots:_vm._u([{key:"icon",fn:function({ active }){return _c('i',{class:[
        `${item.icon}`,
        'iconfont',
        'no-rem',
        'tab-bar-icon-font',
        { active: active }
      ]})}}],null,true)},[_c('span',{staticClass:"texta no-rem"},[_vm._v(_vm._s(item.label))])])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }