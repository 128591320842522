import { mapState } from 'vuex';
import { Dialog, Toast } from 'vant';
import PopupPicker from '@/components/common/popup-picker/index.vue';
import { sleep } from '@/utils/common';
import { setToken, setBrandId } from '@/utils/localStorage';
import * as UserService from '@/service/user/user';

/**
 * 用户登录
 */
export default {
    name: 'user-login',

    components: {
        PopupPicker,
    },

    data() {
        return {
            phone: '',
            code: '',
            resendSmsCodeTimeleft: 0,
            captchaCode: {
                visible: false,
                url: '',
            },
            region: {
                text: '中国大陆（+86）',
                value: '+86',
                ptype: '1',
            },
            regionList: [
                {
                    text: '中国大陆（+86）',
                    value: '+86',
                    ptype: '1',
                },
                {
                    text: '中国香港（+852）',
                    value: '+852',
                    ptype: '2',
                },
                {
                    text: '中国澳门（+853）',
                    value: '+853',
                    ptype: '2',
                },
                {
                    text: '中国台湾（+886）',
                    value: '+886',
                    ptype: '2',
                },
            ],
        };
    },

    computed: {
        phoneValid() {
            return this.$isPhoneValid(this.phone, this.region.value);
        },
        ...mapState({
            loginBanner: state => state.permission.permission.login.banner,
            backgroundImage: state => state.permission.permission.login.backgroundImage,
            toRouteName: state => state.permission.permission.login.toRouteName,
            redirectUrl: state => state.global.redirectUrl,
            homePage: state => state.permission.permission.app['home-page'],
        }),
    },

    methods: {
        // 登录
        async login() {
            const { redirectUrl } = this;
            const { lottery, routeUrl, brandId } = this.$route.query;
            // if (!this.$route.query.wechatToken) {
            //     await Dialog.alert({
            //         message: '登录状态已失效，请退出页面重新登录'
            //     })
            //     return
            // }

            try {
                const params = {
                    phone: this.phone,
                    code: this.code,
                    wechatToken: this.$route.query.wechatToken,
                    lottery: this.$route.query.lottery,
                };
                if (this.$route.query.brandId) params.brandId = this.$route.query.brandId;
                const { token, user } = await this.$store.dispatch('user/login', params);
                setToken(token);
                setBrandId(brandId);
                await this.$store.dispatch('user/getAddresses');
                const { toRouteName, homePage } = this;
                // 跳转到2019-抽奖的页面
                if (lottery) {
                    // 直接跳转到抽奖的页面
                    this.$router.push({
                        name: 'eleven_lottery_2019',
                        query: {
                            ...this.$route.query,
                            activityId: this.$route.query.activityId,
                            userId: user.id,
                        },
                    });
                }
                // 如果戴routerName参数，那么跳转到routerName所代表的页面
                else if (routeUrl) {
                    this.$router.replace({
                        // 新增代理的时候会添加seniorId，所以带过去
                        path: routeUrl,
                        query: {
                            ...this.$route.query,
                        },
                    });
                }
                // 如果有重定向的路径，则跳转到重定向的路径上去
                else if (redirectUrl) {
                    window.location.href = redirectUrl;
                    return;
                } else if (this.$store.state.user.hasShowReminder[this.$route.query.brandId]) {
                    if (toRouteName) {
                        this.$router.replace({
                            name: toRouteName,
                        });
                    } else {
                        this.$router.replace({
                            name: homePage || 'product-list',
                        });
                    }
                } else {
                    //  之所以加这么多个if 是因为深迷品牌只有授权页和个人中心
                    if (toRouteName) {
                        this.$router.replace({
                            name: toRouteName,
                        });
                    } else {
                        this.$router.replace({
                            name: 'reminder',
                        });
                    }
                }
            } catch (e) {}
        },

        // 发送验证码
        async sendSmsCode(captcha) {
            const { region, phone } = this;
            if (!this.phone) {
                Toast('手机号码不能为空');
                return;
            }
            if (this.resendSmsCodeTimeleft != 0) {
                return;
            }
            const requestBody = {
                phone: this.phone,
            };
            if (this.$route.query.brandId) requestBody.brandId = this.$route.query.brandId;
            if (captcha && typeof captcha === 'string') {
                requestBody['captcha'] = captcha;
            }
            try {
                if (region.ptype != '1') {
                    const areacode = region.value.slice(1);
                    UserService.sendSms({ phone, ptype: region.ptype, areacode });
                } else {
                    this.$store.dispatch('user/getCode', requestBody);
                }
                this.captchaCode.visible = false;
                this.resendSmsCodeTimeleft = 60;
                for (let i = 59; i >= 0; --i) {
                    await sleep(1000);
                    this.resendSmsCodeTimeleft = i;
                }
            } catch (e) {
                if (e.status === 403) {
                    this.captchaCode.visible = true;
                    this.captchaCode.url = `${process.env.API_ROOT}/login/captcha?phone=${
                        this.phone
                    }&random=${Math.random() * 10000000}`; // 添加随机数使验证码图片刷新
                }
            }
        },

        chooseRegion() {
            this.$refs.regionPicker.show();
        },

        comfirmRegion(value) {
            this.region = value;
            this.$refs.regionPicker.close();
        },

        mounted() {
            this.$refs.regionPicker.setColumnValue(this.regionList[0].text);
        },
    },
};
