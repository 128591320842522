/**
 * 弹窗：取消订单
 */
export default {
  name: 'cancel-order',

  data () {
    const reasons = [
      '收货信息填写错了',
      '商品选错啦',
      '打款错误',
      '已下过单',
      '其它原因'
    ]

    return {
      visible: false, // 显示/隐藏
      reasons, // 可选理由列表
      currentIndex: 0 // 当前所选索引
    }
  },

  methods: {
    show () {
      this.currentIndex = 0
      this.visible = true
    },
    
    close () {
      this.visible = false
    },

    // 选择
    onChange (picker, value, index) {
      this.currentIndex = index
    },

    // 确认
    confirm () {
      this.$emit('result', this.reasons[this.currentIndex])
    }
  }
}
