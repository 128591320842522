//订单--order
export default [
  {
    path: '/order/order-review',
    name: 'order-review',
    component: () => import('@/pages/order/order-review/index.vue'),
    meta: {
      name: '订单审核',
      description: '订单审核'
    }
  },
  {
    path: '/order/order-review-detail',
    name: 'order-review-detail',
    component: () => import('@/pages/order/order-review/detail/index.vue'),
    meta: {
      name: '订单审核详情',
      description: '订单审核详情'
    }
  },
  {
    path: '/order/order-logistics',
    name: 'order-logistics',
    component: () => import('@/pages/order/order-logistics/index.vue'),
    meta: {
      name: '物流单号',
      description: '物流单号'
    }
  },
  {
    path: '/order/order-dispatch',
    name: 'order-dispatch',
    component: () => import('@/pages/order/order-dispatch/index.vue'),
    meta: {
      name: '订单发货',
      description: '订单发货'
    }
  },
  {
    path: '/order/order-dispatch-terminal',
    name: 'order-dispatch-terminal',
    component: () => import('@/pages/order/order-dispatch-terminal/index.vue'),
    meta: {
      name: '扫码发货',
      description: '订单发货'
    }
  },
  {
    path: "/order/delivery-success",
    name: "order-delivery-success",
    component: () =>
      import("@/pages/order/order-delivery-success/index.vue"),
    meta: {
      name: "订单提交成功",
      description: "订单提交成功",
    },
  },
]