export default [
  {
    path: '/cloudWarehouse/warehouse',
    name: 'warehouse',
    component: () => import('@/pages/cloudWarehouse/warehouse/index.vue'),
    meta: {
      name: '云仓库',
      description: '我的云仓库',
      keepAlive: []
    }
  },
  {
    path: '/cloudWarehouse/outOrder',
    name: 'outOrder',
    component: () => import('@/pages/cloudWarehouse/outOrder/index.vue'),
    meta: {
      name: '订单详情',
      description: '我的订单详情',
      keepAlive: []
    }
  },
  {
    path: '/cloudWarehouse/cloudAddress',
    name: 'cloudAddress',
    component: () => import('@/pages/cloudWarehouse/cloudAddress/index.vue'),
    meta: {
      name: '云仓库地址',
      description: '我的云仓库地址',
      keepAlive: []
    }
  },
  {
    path: '/cloudWarehouse/order-list',
    name: 'order-list',
    component: () => import('@/pages/cloudWarehouse/orderList/index.vue'),
    meta: {
      name: '云订单',
      description: '我的云订单',
      keepAlive: []
    }
  },
  {
    path: '/cloudWarehouse/success',
    name: 'cloudOutSuccess',
    component: () => import('@/pages/cloudWarehouse/success/index.vue'),
    meta: {
      name: '出库成功',
      description: '我的云订单出库成功',
      keepAlive: []
    }
  },
]