import {
    getLevels
} from '@/service/auth/index'

export default {
    namespaced: true,
    state: {
        //登录用户拥有的新增代理的可选等级
        levelList: []
    },
    mutations: {
        setLevelList(state, levelList) {
            state.levelList = levelList
        },
    },
    actions: {
        // 获取省份信息
        async getLevelList({
            commit,
            state
        }, {
            userId
        }) {
            getLevels(userId).then((res) => {
                commit('setLevelList', res)
            });

        }
    },

}