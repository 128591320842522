import { mapState } from 'vuex';
import { getDiscountPrice } from '@/utils/common';
import { StockType } from '@/constants/common';
import UploadImage from '@/components/common/upload-image/index.vue';
import FormatItem from '@/components/common/format-item/index.vue';
import Rmb from '@/components/common/rmb/index.vue';
import { Dialog, Toast } from 'vant';
import { pick } from 'lodash';
import { getNationalExp, addProductLimit } from '@/service/lipGuoqingTemp';
import { getFormats } from '@/utils/lipGuoqingTemp';
import AddressDetail from '@/components/address/address-detail/index.vue';
import { postOrder } from '@/service/order/index';
import CustomStepper from '@/components/common/custom-stepper/index.vue';
import { getAgentSystemAuthList } from '@/service/auth/index';

async function alert({ message }) {
    Toast(message);
}

/**
 * 下单页面
 */
export default {
    name: 'pre-order',

    components: {
        UploadImage,
        FormatItem,
        Rmb,
        AddressDetail,
        CustomStepper,
    },

    data() {
        const ISQINGBULUO = process.env.VUE_APP_PROJECT_NAME === 'qingbuluo'; //是否轻部落

        return {
            ISQINGBULUO, // 是否轻部落
            lips: [], // 赠品口红
            express: {},
            showExpressPicker: false,
            showOrderPicker: false,
            from: null, // 来源页面
            validCouponCount: 0,
            editAddressItem: {},
            expresslist: [],
            orderTypes: [
                { text: '普通订单', value: 0 },
                { text: '云仓库订单', value: 1 },
                { text: '混合订单', value: 2 },
            ],
            choseType: 0, // 选择支付的类型,0为打款凭证，1为余额支付
            systemImages: {}, // 凭证信息
            showSelectCloudOrder: false, // 云订单选择框
            cloudOrderProduct: {}, // 将要放入云仓库产品
            currentOrderType: 0, // 当前订单类型
            userArr: [], // 用户等级信息
            currentSystemId: '-1',
        };
    },

    computed: {
        ...mapState({
            myAddresses: state => state.user.addresses,
            user: state => state.user.user,
            userId: state => state.user.user.id,
            userRank: state =>
                state.user.user.levelall && state.user.user.levelall[0] && state.user.user.levelall[0].rank,
            order: state => state.preorder, // 预订单
            cart: state => state.cart.cart[StockType.Sell],
            brandId: state => state.global.brandId,
            subOrders: state => state.memory.subOrders, // 子订单
            showReceipt: state => state.permission.permission.order.receipt,
        }),

        // 订单类型信息
        systemOrderType() {
            if (this.order.products.length !== 0) {
                let exp = {};
                this.order.products.map(p => {
                    if (this.getOrderTypePower(p.systemId)) {
                        exp[p.systemId] = {};
                    } else {
                        exp[p.systemId] = { text: '授权订单', value: 0 };
                    }
                });
                return exp;
            }
        },

        // 物流信息
        systemExpress() {
            if (this.order.products.length !== 0) {
                let exp = {};
                this.order.products.map(p => {
                    exp[p.systemId] = {};
                });
                return exp;
            }
        },

        // 备注信息
        systemRemark() {
            if (this.order.products.length !== 0) {
                let exp = {};
                this.order.products.map(p => {
                    exp[p.systemId] = '';
                });
                return exp;
            }
        },

        // 总价格（分）
        systemTotalPrice() {
            if (this.order.products.length !== 0) {
                let exp = {};
                this.order.products.map(p => {
                    exp[p.systemId] = 0;
                    p.carts.map(cart => {
                        exp[p.systemId] =
                            exp[p.systemId] +
                            getDiscountPrice(parseFloat(cart.discount), cart.userRank, cart.price) * cart.quantity;
                    });
                });
                return exp;
            }
        },

        // 凭证信息
        systemImagesTemp() {
            if (this.order.products.length !== 0) {
                let exp = {};
                this.order.products.map(p => {
                    exp[p.systemId] = [];
                });
                this.systemImages = exp;
                return exp;
            }
        },

        // 优惠券总价
        totalCouponsPrice() {
            let money = 0;
            if (this.order.coupons) {
                this.order.coupons.forEach(item => {
                    money += item.coupon.discount;
                });
            }
            return money;
        },

        userInfo() {
            return this.user.name + ' ' + this.user.phone;
        },
    },

    methods: {
        getDiscountPrice,

        orderClick(systemId) {
            this.showOrderPicker = true;
            this.currentSystemId = systemId;
        },

        expressClick(systemId) {
            this.showExpressPicker = true;
            this.currentSystemId = systemId;
        },

        // 选择订单类型
        onOrderConfirm(data) {
            let systemId = this.currentSystemId;
            let { order } = this;
            this.currentOrderType = data.value;

            this.systemOrderType[systemId] = data;
            this.showOrderPicker = false;
            if (data.value === 2) {
                // this.cloudOrderProduct = JSON.parse(JSON.stringify(this.order.products.filter(d => d.systemId === systemId)[0]));

                // 遍历获取全部商品
                let arr = [];
                order.products.forEach(item => {
                    if (item.systemId == systemId) {
                        arr.push(...item.carts);
                        if (order.gifts[item.systemId] !== null && order.gifts[item.systemId] !== undefined) {
                            order.gifts[item.systemId].forEach(itemGift => {
                                itemGift.gifts.forEach(i => (i.gitfQuantity = itemGift.quantity * i.quantity));
                                arr.push(...itemGift.gifts);
                            });
                        }
                    }
                });

                let obj = JSON.parse(JSON.stringify(this.order.products.filter(d => d.systemId === systemId)[0]));
                this.cloudOrderProduct = { ...obj, carts: arr };

                this.showSelectCloudOrder = true;
            } else {
                // 清除之前放入云仓库的数据
                this.order.products.map(d => {
                    if (d.systemId == systemId) {
                        delete d.cloudStorageCount;
                        d.carts.map(c => {
                            delete c.cloudStorage;
                        });
                    }
                });
                this.order.gifts[systemId].map(d => {
                    if (d.systemId == systemId) {
                        delete d.cloudStorageCount;
                        d.gifts.map(c => {
                            delete c.cloudStorage;
                        });
                    }
                });
            }
        },

        handleCloudOrder(systemId) {
            if (this.currentOrderType === 2) {
                this.cloudOrderProduct = JSON.parse(
                    JSON.stringify(this.order.products.filter(d => d.systemId === systemId)[0])
                );
                this.showSelectCloudOrder = true;
            }
        },

        // 选择快递
        onExpressConfirm(data) {
            let systemId = this.currentSystemId;
            this.systemExpress[systemId] = data;
            this.showExpressPicker = false;
        },

        // 选择地址
        onChooseAddress() {
            this.$router.push({
                name: 'address-list',
                query: {
                    from: 'pre-order',
                    addressId: this.subOrders[0].address.id,
                },
            });
        },

        // 放入云仓库编辑
        formatChange(val, formatId) {
            val = parseInt(val);

            this.cloudOrderProduct.carts.map(product => {
                if (product.formatId === formatId) {
                    product.cloudStorage = val;
                }
            });
            this.cloudOrderProduct.cloudStorageCount = 0;
            this.cloudOrderProduct.carts.map(product => {
                this.cloudOrderProduct.cloudStorageCount =
                    this.cloudOrderProduct.cloudStorageCount + (product.cloudStorage ? product.cloudStorage : 0);
            });

            this.cloudOrderProduct = { ...this.cloudOrderProduct };
        },

        // 初始化
        async init() {
            //-------------------2019-10-1 国庆写死补货口红 start---------------------------------
            const { order, brandId, userRank } = this;
            const { lipExperienceList } = order;
            const params = {
                formats: getFormats(order.products),
                experiences: lipExperienceList,
                brandId,
                userRank,
            };
            getNationalExp(params).then(res => {
                this.lips = res.products || [];
            });
            //-------------------2019-10-1 国庆写死补货口红 end-----------------------------------

            // 设置优惠券
            if (!this.order.coupons || !this.order.coupons.length) {
                this.$store.commit('preorder/setField', {
                    coupons: [],
                });
                // await this.getValidCoupons()
            }

            // 设置地址
            let selectedAddress = this.order.address;
            await this.$store.dispatch('user/getAddresses');
            if (selectedAddress && selectedAddress.id) {
                selectedAddress = this.myAddresses.find(item => item.id === selectedAddress.id); // 更新。因为有可能删除/修改了
            }
            if (!selectedAddress || !selectedAddress.id) {
                selectedAddress = this.myAddresses.find(item => item.isDefault); // 如果没有，就拿默认地址
            }
            this.$store.commit('memory/setSubOrders', [
                {
                    address: selectedAddress || {},
                    products: this.order.products,
                    gifts: this.order.gifts,
                    samples: this.order.samples,
                    plusProducts: this.order.plusProducts,
                    chooseGift: this.order.chooseGift,
                    remark: '',
                },
            ]);

            // 获取用户等级信息
            this.userArr = (await getAgentSystemAuthList({ userId: this.userId })) || [];
        },

        // 获取有效优惠券
        async getValidCoupons() {
            try {
                const data = await this.$http.post(`orders/coupon/valid`, {
                    brandId: this.brandId,
                    products: this.order.products.map(item => {
                        return {
                            id: item.id,
                            price: this.getPriceByProduct(item, item.discount, item.userRank),
                        };
                    }),
                });
                this.validCouponCount = data.count;
            } catch (e) {}
        },

        // 获取所有的物流信息
        async getexpresslist() {
            try {
                let _expresslist = await this.$http1.get(`expresses/sold`);
                _expresslist = _expresslist.filter(ex => ex.name === '德邦物流');
                _expresslist.forEach(elment => {
                    elment.text = elment.name;
                    elment.value = elment.com;
                });
                this.expresslist = _expresslist;
            } catch (e) {}
        },

        // 获取每种 product 的总价格
        getPriceByProduct(product, discount, userRank) {
            return product.formats.reduce(
                (result, item) => result + getDiscountPrice(discount, userRank, item.price) * item.count,
                0
            );
        },

        // 封装 subOrder 对象
        _wrapSubOrders(subOrders) {
            let subOrdersTemp = [];
            subOrders.map(item => {
                item.products.map(system => {
                    let newItem = {};
                    newItem.receipts = this.systemImages[system.systemId].map(val => val.serverId);
                    newItem.products = [];
                    newItem.products = system.carts.map(cart => {
                        return {
                            productId: cart.productId,
                            productName: cart.productName,
                            formatId: cart.formatId,
                            formatName: cart.formatName,
                            systemId: cart.systemId,
                            unit: cart.unit,
                            thumb: cart.thumb,
                            quantity: cart.quantity,
                            warehouseQuantity:
                                this.systemOrderType[system.systemId].value === 0
                                    ? 0
                                    : this.systemOrderType[system.systemId].value === 1
                                    ? cart.quantity
                                    : cart.cloudStorage,
                            systemId: cart.systemId,
                        };
                    });
                    let giftsTemp = [];
                    if (item.gifts[system.systemId]) {
                        item.gifts[system.systemId].map(item => {
                            item.gifts.map(gift => {
                                var obj = {
                                    productId: gift.productId,
                                    productName: gift.productName,
                                    formatId: gift.formatId,
                                    formatName: gift.formatName,
                                    systemId: item.systemId,
                                    unit: gift.unit,
                                    thumb: gift.thumb,
                                    quantity: gift.quantity * item.quantity,
                                    packageId: item.packageId,
                                    mainId: item.mainId,
                                    warehouseQuantity:
                                        this.systemOrderType[system.systemId].value === 0
                                            ? 0
                                            : this.systemOrderType[system.systemId].value === 1
                                            ? gift.quantity * item.quantity
                                            : gift.cloudStorage || 0,
                                    systemId: item.systemId,
                                };
                                giftsTemp.push(obj);
                            });
                        });
                    }
                    newItem.gifts = giftsTemp;
                    newItem.type = this.systemOrderType[system.systemId].value;
                    newItem.expressesId = this.systemExpress[system.systemId].value || '';
                    newItem.expressesName = this.systemExpress[system.systemId].text || '';
                    newItem.remark = this.systemRemark[system.systemId];
                    newItem.totalPrice = this.systemTotalPrice[system.systemId];
                    newItem.systemId = system.systemId;
                    newItem.chooseGift = item.chooseGift;
                    subOrdersTemp.push(newItem);
                });
                // newItem.samples = item.samples.map(product => pick(product, ['id', 'name', 'count', 'formats']))
                // newItem.plusProducts = item.plusProducts.map(product => pick(product, ['id', 'name', 'count', 'formats']))
                // newItem.price = item.products.concat(item.plusProducts).reduce((sum, product) => {
                //     return sum + this.getPriceByProduct(product, product.discount, product.userRank)
                // }, 0)
            });
            return subOrdersTemp;
        },

        // 确认下单
        async payNow() {
            // 参数校验
            // 下单地址
            if (this.subOrders[0] && this.subOrders[0].address && !this.subOrders[0].address.id) {
                await alert({ message: '您还没有选择下单地址' });
                return;
            }
            // 订单类型
            for (let key of Object.keys(this.systemOrderType)) {
                if (Object.keys(this.systemOrderType[key]).length === 0) {
                    await alert({ message: '您还没有选择订单类型' });
                    return;
                }
            }
            // 物流单号
            for (let key of Object.keys(this.systemExpress)) {
                if (Object.keys(this.systemExpress[key]).length === 0) {
                    await alert({ message: '您还没有选择快递物流' });
                    return;
                }
            }
            // 打款凭证
            for (let key of Object.keys(this.systemImages)) {
                if (this.systemImages[key].length === 0) {
                    await alert({ message: '您还没有上传打款凭证' });
                    return;
                }
            }

            try {
                // if (validProducts.some(product => product.changed || product.formats.some(format => format.changed))) {
                //     alert({ message: '支付商品中包含已失效的商品，请返回重新下单!' })
                //     await this.$store.dispatch('cart/getCart', { type: StockType.Sell })
                //     return
                // }

                /** 检查商品最少下单数量和库存 */
                // for (const item of validProducts) {
                //     for (const format of item.formats) {
                //         if (format.inventory < format.count) {
                //             await alert({ message: `${item.name}(${format.name}) 库存不足` })
                //             return
                //         }
                //     }
                //     if (messChecked.indexOf(item.id) >= 0) continue
                //     if (item.minpurchase[item.userRank] && item.count < item.minpurchase[item.userRank]) {
                //         await alert({
                //             message: `亲，${item.name} 的下单数量最少为 ${item.minpurchase[item.userRank]} ${item.unit}，请返回重新添加商品`
                //         })
                //         return
                //     }
                // }
                /**当选择余额支付时，判断余额是否足够支付本次订单，不足则显示余额不足，请选择打款凭证支付 */

                let message = '';
                this.subOrders[0].products.forEach(product => {
                    product.carts.map(cart => {
                        message += `<p><span class="name">${cart.productName}</span><span class="value">x ${cart.quantity}</span></p>`;
                    });
                });
                Object.keys(this.subOrders[0].gifts).map(key => {
                    this.subOrders[0].gifts[key].map(g => {
                        g.gifts.map(gift => {
                            message += `<p><span class="name">${
                                gift.productName
                            }</span><span class="value">x ${gift.quantity * g.quantity}</span></p>`;
                        });
                    });
                });
                // this.subOrders[0].samples.forEach(product => {
                //     message += `<p><span class="name">${product.name}</span><span class="value">x ${product.count}</span></p>`;
                // })
                // this.subOrders[0].plusProducts.forEach(product => {
                //     message += `<p><span class="name">${product.name}</span><span class="value">x ${product.count}</span></p>`;
                // })
                // this.lips.forEach(product => {
                //     message += `<p><span class="name">${product.name}</span><span class="value">x ${product.count}</span></p>`;
                // })
                message += `<p><span class="name">总计</span><span class="value">￥${this.$fen2Yuan(
                    this.order.totalPrice
                )}</span></p>`;
                await Dialog.confirm({
                    message,
                    title: '产品清单',
                });
                const _subOrders = this._wrapSubOrders(this.subOrders);
                // 下单
                let _params = {
                    userId: this.user.id,
                    brandId: this.brandId,
                    totalPrice: this.order.totalPrice,
                    payType: 'RECEIPT',
                    addressId: this.subOrders[0].address.id,
                    subOrders: _subOrders,
                    // products: this.order.products,
                    // gifts: this.order.gifts,
                    // samples: this.order.samples,
                    // plusProducts: this.order.plusProducts,
                    // coupons: this.order.coupons.map(item => item.id),
                    // discount: this.totalCouponsPrice,
                    // expressesId: this.express.value || '',
                    // expressesName: this.express.text || '',
                    // experiences: this.lips
                };

                // if (this.showReceipt) {
                //     _params = {
                //         ..._params,
                //         payType: this.choseType === 0 ? 'RECEIPT' : 'DEPOSIT',
                //         receipts: this.choseType === 0 ? this.order.images.map(val => val.serverId) : [],
                //     }
                // }

                // return console.log('_subOrders===', _subOrders)

                await postOrder(_params);

                // //  限购商品，在清空前获取
                // const { order: { products } } = this
                // const formats = getFormats(products)
                // _params.callback = orderId => { addProductLimit({ formats, orderId: orderId }) }

                // // 水光生下单地址
                // if (this.$isSGS()) {
                //     await this.$store.dispatch('order/placeOrdersgs', _params)
                // }
                // // 非水光生下单地址
                // else {
                //     await this.$store.dispatch('order/placeOrder', _params)
                // }

                // // 如果是从购物车过来，就需要更新购物车
                // if (this.from === 'cart') {
                //     const cartProducts = this.cart[this.brandId] || []
                //     const products = cartProducts.filter(product => {
                //         for (const item of this.order.products) {
                //             if (item.id === product.id) {
                //                 for (const key in product.formats) {
                //                     const index = item.formats.findIndex(format => format.id === key)
                //                     if (index !== -1) {
                //                         product.count -= product.formats[key].count
                //                         delete product.formats[key]
                //                     }
                //                 }
                //             }
                //         }
                //         return Object.values(product.formats).length
                //     })
                //     await this.$store.dispatch('cart/updateCart', {
                //         type: StockType.Sell,
                //         brandId: this.brandId,
                //         products,
                //     })
                // }

                // 重置预订单
                await this.$store.dispatch('preorder/reset');

                this.$router.replace({
                    name: 'pay-success',
                    query: {
                        type: StockType.Sell,
                    },
                });
            } catch (e) {
                console.error(e);
            }
        },

        async hide() {
            let systemId = this.currentSystemId;
            if (!this.cloudOrderProduct.cloudStorageCount) {
                await Dialog.alert({
                    message: '亲，您还存放商品哦~',
                    title: '温馨提示',
                });
                return;
            }

            this.order.products.map((d, i) => {
                if (d.systemId === systemId) {
                    let productList = {},
                        giftList = {};
                    for (let i in this.cloudOrderProduct) {
                        if (i === 'carts') {
                            productList.carts = this.cloudOrderProduct.carts.filter(i => i.isShow == undefined);
                            giftList[systemId] = this.cloudOrderProduct.carts.filter(i => i.isShow != undefined);
                        } else productList[i] = this.cloudOrderProduct[i];
                    }

                    this.order.products[i] = productList;
                }
            });
            this.showSelectCloudOrder = false;
        },

        // 判断是否显示订单类型（团购长以下只有授权订单）
        getOrderTypePower(systemId) {
            for (let item of this.userArr) {
                if (item.systemId === systemId && item.valid === 1) {
                    if (item.levelValue === 'QGZDL' || item.levelValue === 'BJVIP') {
                        return false;
                    } else {
                        return true;
                    }
                }
            }
        },
    },

    async created() {
        this.from = this.$route.query.from;
        await this.init();
        await this.getexpresslist();
    },

    async activated() {
        await this.init();
    },
};
