import IDValidator from 'id-validator'

const idValidator = new IDValidator()

export default {
  name: {
    required: true,
    message: '姓名不能为空',
    transform (value) {
      return value.trim()
    }
  },
  identity: {
    validator (rule, value) {
      value = value.trim()
      const errors = []

      if (!value) {
        errors.push(new Error('身份证号码不能为空'))
      } else if (value.length < 18) {
        errors.push(new Error('身份证号码不足18位'))
      }

      if (process.env.NODE_ENV === 'production') { // 生产环境需要校验身份证合法性
        if (!idValidator.isValid(value)) {
          errors.push(new Error('身份证输入不合法'))
        }
      }

      return errors
    }
  },
  wechat: [
    {
      required: true,
      message: '微信号不能为空',
      transform (value) {
        return value.trim()
      }
    },
    {
      pattern: /^(\w|-){5,40}$/,
      message: '微信号格式错误'
    }
  ],
  gender: {
    required: true,
    message: '性别不能为空'
  },
  regionId: {
    required: true,
    message: '省市区不能为空'
  },
  professionId: {
    required: true,
    message: '职业不能为空'
  },
  educationId: {
    required: true,
    message: '学历不能为空'
  },
}
