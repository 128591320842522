import { mapState } from 'vuex'
import { StockType } from '@/constants/common'
import CreatePreOrder from '@/mixins/create-pre-order'
import Cart from '@/components/common/cart/index.vue'
import { Dialog } from 'vant'
import { convertUnit2LimitUnit } from '@/utils/common'

async function alert (message) {
  await Dialog.alert({
    message
  })
}

/**
 * 入库购物车
 */
export default {
  name: 'instock-cart',

  mixins: [CreatePreOrder],

  components: {
    Cart,
  },

  props: {
    active: Boolean,
  },
  
  watch: {
    active: {
      async handler () {
        if (this.active) {
          await this.init()
        }
      }
    }
  },

  data () {
    return {
      StockType,
      type: StockType.StockIn, // 类型：入库
      products: []
    }
  },

  computed: {
    ...mapState({
      cart: state => state.cart.cart,
      brand: state => state.stock.brand,
      brandId: state => state.global.brandId
    })
  },
  
  methods: {
    // 初始化（获取购物车）
    async init () {
      this.products = []
      await this.$store.dispatch('cart/getCart', { type: this.type })
      this.products = this.cart[this.type][this.brandId] || []
    },

    // 更新购物车
    async updateCart (products) {
      await this.$store.dispatch('cart/updateCart', {
        brandId: this.brandId,
        products: products,
        type: this.type
      })
      this.products = this.cart[this.type][this.brandId] || []
    },

    // 提交
    async trySubmit (products, totalPrice) {
      await this.$store.dispatch('stock/getBrand', { brandId: this.brandId })
      const totalCount = products.reduce((result, item) => result + convertUnit2LimitUnit(item), 0)

      // 检查购物车中商品是否失效
      products = await this.$store.dispatch('cart/checkValid', products)
      if (products.some(product => product.changed || product.formats.some(format => format.changed || format.inventory < format.count))) {
        await alert('结算商品中包含已失效的商品！请到购物车中清空商品再重新下单')
        await this.$store.dispatch('cart/getCart', { type: this.type })
        this.$set(this, 'products', this.cart[this.type][this.brandId])
        return
      }

      // 检查商品最少下单数量
      for (const item of products) {
        if (item.minpurchase[item.userRank] && item.count < item.minpurchase[item.userRank]) {
          await alert(`亲，${item.name} 的下单数量最少为 ${item.minpurchase[item.userRank]} ${item.unit}~`)
          return
        }
      }

      // 检查最小进货量
      if (this.brand.minStockIn > totalCount) {
        await alert(`结算商品不满足最小进货量（${this.brand.minStockIn}）！`)
        return
      }

      for (const product of products) {
        for (const format of product.formats) {
          delete format.inventory // 删除'公司库存'字段
        }
      }

      // 设置预订单
      await this.$store.dispatch('preorder/reset')
      this.$store.commit('preorder/setField', {
        products,
        totalPrice,
        gifts: [], // @todo：非水光生平台，有入库购物车，但无赠品
        samples: [] // @todo：非水光生平台，有入库购物车，但无体验装
      })
      // 导引到下单页
      this.$router.push({
        name: 'stockin-preorder'
      })
    }
  },

  async created () {
    await this.init()
  },
}
