/**
 * 弹窗：催促完善个人信息
 */
export default {
  name: 'prompt-information',

  data () {
    return {
      visible: false
    }
  },

  methods: {
    show () {
      this.visible = true
    },

    go2Page () {
      this.$router.push({
        name: 'complement-information'
      })
    }
  }
}
