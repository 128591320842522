export const TeamMages = [
    {
        key: "auth",
        name: "我的团队",
        to: "my-team",
        icon: require("@/assets/images/profile/my-team.png"),
    },
    {
        key: "achievement",
        name: "我的业绩",
        to: "rebate-sum",
        routeNames: [
            "shilishou-share-holders-rebate-home",
            "shilishou-strategy-rebate-home",
            "shilishou-co-founder-rebate-home",
        ],
        icon: require("@/assets/images/profile/achievement_sum.png"),
    },
    {
        key: "orderReview",
        name: "订单审核",
        to: "order-review",
        icon: require("@/assets/images/profile/order-review.png"),
    },
    {
        key: "retail-order",
        name: "零售管理",
        to: "retail-order-home",
        icon: require("@/assets/images/profile/retail.png"),
    },
];

export const Tools = [
    {
        key: "auth",
        name: "我的授权",
        icon: require("@/assets/images/profile/my-auth.png"),
        to: "agent-home",
    },
    {
        key: "address",
        name: "地址管理",
        icon: require("@/assets/images/profile/address_manage.png"),
        to: "address-list",
    },
    {
        key: "retailAddressList",
        name: "零售地址",
        icon: require("@/assets/images/profile/address-entrance.png"),
        to: "retail-address-list",
        query: {
            type: "retailList",
            from: "personal-center"
        }
    },
    {
        key: "warehouse",
        name: "云仓库",
        routeName: "warehouse",
        icon: require("@/assets/images/profile/cwarehouse.png"),
        to: "warehouse",
    },
    {
        key: "my-integral",
        name: "我的积分",
        icon: require("@/assets/images/profile/integral.png"),
        to: "my-integral",
    },
    {
        key: "my-deposit",
        name: "保证金",
        icon: require("@/assets/images/profile/order-review.png"),
        to: "my-deposit",
    }
];