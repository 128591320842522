import { HttpV1 as HTTP } from '@/request';
import store from '@/store';

// 获取下级列表
export async function getJuniors(queryUserId, userId, seniorId, systemId) {
    return await HTTP.get(`/agent/auth/juniors`, {
        queryUserId, //查询的那个人的userid
        brandId: store.state.global.brandId,
        userId,
        seniorId,
        systemId,
    });
}

// 获取上级
export async function getSenior(userId) {
    return await HTTP.get(`/agent/auth/senior`, {
        userId,
    });
}
//获取推荐人的信息
export async function getseniorInfo(userId, brandId) {
    return await HTTP.get(`/agent/auth/seniorInfo`, {
        userId,
        brandId,
    });
}

// 获取董事
export async function getDirector(userId) {
    return await HTTP.get(`/agent/auth/director`, {
        userId,
    });
}

/**
 * 根据userId查询可以新增代理的等级列表
 * @param {string} userId
 */
export function getLevels(userId) {
    return HTTP.get(`/agent/auth/increment/levels`, {
        userId,
        brandId: store.state.global.brandId,
    });
}

export function getJuniorsInfo({ queryUserId, userId, seniorId, brandId, systemId }) {
    return HTTP.get(`/agent/auth/juniors`, { queryUserId, userId, seniorId, brandId, systemId });
}

/**
 * 上级提交申请
 */
export function addAgentFromSenior({
    levelId,
    seniorId,
    phone,
    name,
    mediaIds,
    money,
    referrerPhone,
    identity,
    wechat,
    gender,
    regionId,
    professionId,
    identityTypeCode,
    identityTypeName,
    educationId,
    identityFrontMediaId,
    identityBackMediaId,
}) {
    return HTTP.post(`/agent/auth/increment/from-senior`, {
        levelId,
        seniorId,
        phone,
        name,
        mediaIds,
        identity,
        wechat,
        gender,
        regionId,
        professionId,
        identityTypeCode,
        identityTypeName,
        educationId,
        identityFrontMediaId,
        identityBackMediaId,
        referrerPhone,
    });
}

/**
 * 下级提交申请
 */
export function addAgentFromJunior({
    levelId,
    seniorId,
    phone,
    name,
    mediaIds,
    money,
    referrerPhone,
    identity,
    wechat,
    gender,
    regionId,
    identityTypeCode,
    identityTypeName,
    professionId,
    educationId,
    identityFrontMediaId,
    identityBackMediaId,
}) {
    return HTTP.post(`/agent/auth/increment/from-junior`, {
        levelId,
        seniorId,
        phone,
        name,
        mediaIds,
        identity,
        wechat,
        gender,
        identityTypeCode,
        identityTypeName,
        regionId,
        professionId,
        educationId,
        identityFrontMediaId,
        identityBackMediaId,
        referrerPhone,
    });
}

/**
 * 根据userId查询可以升级的级别列表
 * @param {string} userId
 */
export function getUpgradeLevels({ systemId, juniorId }) {
    return HTTP.get(`/agent/auth/upgrade/levels`, {
        systemId,
        juniorId,
    });
}

/**
 * 升级代理
 * @param {*} param0 seniorId = userId
 *
 */
export function upgradeAgent({ wechat, phone, toLevelId, juniorId, systemId, seniorId }) {
    return HTTP.post(`/agent/auth/upgrade`, {
        wechat,
        phone,
        systemId,
        toLevelId,
        juniorId,
        seniorId,
    });
}

/**
 * 升级自己
 * @param {*} param0 seniorId = userId
 */
export function upgradeMyself({ juniorId, fromLevelId, systemId, mediaIds, money, wehcat, phone }) {
    return HTTP.post(`/agent/auth/upgrade/from-myself`, {
        juniorId,
        fromLevelId,
        systemId,
        mediaIds,
        money,
        wehcat,
        phone,
    });
}

// 获取代理详情
export async function getAgentDetail(userId) {
    return HTTP.get(`/agent/user/${userId}`);
}

//获取用户授权品牌
export async function getSystemsList(userId, seniorId, systemId) {
    return HTTP.get(`/agent/user/${userId}/systems`, {
        brandId: store.state.global.brandId,
        userId: store.state.user.user.id,
        seniorId,
        systemId,
    });
}
/**
 * 获取可升级等级列表
 * @param {string} superiorId 上级ID
 * @param {string} juniorId 下级ID
 */
export async function canUpgrade(systemId, juniorId) {
    return HTTP.get(`/agent/auth/upgrade/judge-can`, {
        systemId,
        juniorId,
    });
}

// 获取学历
export async function getEducationList() {
    return await HTTP.get(`/agent/education`);
}

// 获取职业
export async function getProfessionList() {
    return await HTTP.get(`/agent/profession`);
}

//获取本人可以申请的等级
export async function upgradeAlllevels(params) {
    return HTTP.get(`/agent/auth/upgrade/alllevels`, params);
}

// 【我的审核-列表】
export async function getAgentReview(params) {
    return HTTP.get(`/agent/auth/review`, params);
}

// 获取【我的申请-新增-列表】列表
export async function getIncrementList(params) {
    return HTTP.get(`/agent/auth/increment`, params);
}

// 【我的申请-新增-详情】【我的审核-详情】
export async function getIncrementDetail(params) {
    return HTTP.get(`/agent/auth/increment/${params}`);
}

// 【我的申请-升级-详情】
export async function getUpdateDetail(params) {
    return HTTP.get(`/agent/auth/upgrade/${params}`);
}

// 【我的申请-升级-列表】
export async function getAgentUpdate(params) {
    return HTTP.get(`agent/auth/upgrade`, params);
}

// 修改用户信息
export async function updateUser(id, { name, identity, wechat, gender, regionId, professionId, educationId }) {
    return await HTTP.patch(`/agent/user/${id}`, {
        name,
        identity,
        wechat,
        gender,
        regionId,
        professionId,
        educationId,
    });
}

// 代理是否可以邀请别人
export async function judgeAuth() {
    return HTTP.get(`/agent/auth/judge-auth`, {
        userId: store.state.user.user.id,
    });
}

// 通过用户的新增申请
export async function approveAgent(params) {
    return HTTP.put(`/agent/auth/review/${params}/approve`, {
        operatorId: store.state.user.user.id,
    });
}

// 拒绝用户的新增申请
export async function rejectAgent(params) {
    return HTTP.put(`/agent/auth/review/${params.id}/reject`, {
        operatorId: store.state.user.user.id,
        reason: params.reason,
    });
}

// 通过用户的升级申请
// id:申请记录id
export async function upgradeapproveAgent(params) {
    return HTTP.put(`/agent/auth/upgrade/approve`, {
        operatorId: store.state.user.user.id,
        type: 1,
        id: params,
    });
}

// 拒绝用户的升级申请
// id:申请记录id
export async function upgraderejectAgent(params) {
    return HTTP.put(`/agent/auth/upgrade/reject`, {
        operatorId: store.state.user.user.id,
        reason: params.reason,
        type: 1,
        id: params.id,
    });
}

// 获取所有的证件类型
export async function identityTypes(params) {
    return HTTP.get(`/agent/auth/identity-types`);
}

// 获取用户信息（根据ID）
export async function getUser(id) {
    return HTTP.get(`/agent/user/${id}`);
}

// 获取邀请的链接
export async function getQrcode(id) {
    return HTTP.get(`/agent/auth/invitation/url`, {
        userId: store.state.user.user.id,
        brandId: store.state.global.brandId,
    });
}

// 获取指定用户的所有等级
export async function getallsystemslevel(id) {
    return HTTP.get(`/agent/user/allsystemslevel`, {
        id,
    });
}

//根据id获取升级记录
export async function getUpdateInformation(id) {
    return HTTP.get(`/agent/auth/upgrade/UpdateInformation`, { id });
}

//撤回提交的申请
export async function cancelInformation(id) {
    return;
    // return HTTP.get(`/agent/auth/upgrade/UpdateInformation`, {    id})
}

// 审核下级提交的升级申请
export async function getReviewTheUpgrade(params) {
    return HTTP.get(`/agent/auth/upgrade/reviewTheUpgrade`, params);
}

//获取用户的所有推荐人
export async function getAuthReferrer(params) {
    return HTTP.get(`/agent/auth/referrer`, Object.assign(params, { brandId: store.state.global.brandId }));
}

//查询代理是否存在
export async function getAgentList({ userKeyword }) {
    return HTTP.post(`/agent/auth/authSelect`, { userKeyword });
}

//查询用户授权列表
export async function getAgentSystemAuthList({ userId }) {
    return HTTP.get(`/agent/user/system/`, { userId });
}

//查询用户授权列表
export async function updateCertificate({ userId, systemId }) {
    return HTTP.post(`/agent/certificate/update`, { userId, systemId });
}

// 本月新增推荐列表
export function getNewAddAgency({ systemId, userId, queryUserId, startTime, endTime }) {
    return HTTP.get(`/agent/auth/newAddAgency`, { systemId, userId, queryUserId, startTime, endTime });
}

// 本月升级代理
export function getNewUpgradeAgency({ systemId, userId, queryUserId, startTime, endTime }) {
    return HTTP.get(`/agent/auth/newUpgradeAgency`, { systemId, userId, queryUserId, startTime, endTime });
}

// 本月升级代理
export function getReferrer({ systemId, userId, queryUserId, startTime, endTime }) {
    return HTTP.get(`/agent/auth/referrer`, { systemId, userId, queryUserId, startTime, endTime });
}

//查看用户我的申请
export async function userSystemApply(params) {
    return HTTP.get('/agent/user/system-apply', params);
}

//我的授权
export async function authMystatus(params) {
    return HTTP.get('/agent/auth/my-status', params);
}

//获取所有产品线
export async function getSystems() {
    return HTTP.get('/agent/new-rebate/systems');
}
