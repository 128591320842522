export const StockType = {
  Sell: 0,
  StockIn: 1,
  StockOut: 2
}

export const ProductType = {
  Sold: 0, // 售卖
  Present: 1 // 赠品
}

export const StockOrderType = [
  { code: -1, name: '全部' },
  { code: 1, name: '入库单' },
  { code: 2, name: '出库单' }
]

export const STOCK_ORDER_TYPE_VALUE = {
  ALL: '-1',
  IN: '1',
  OUT: '2'
}

export const StockOrderState = {
  '-1': [
    { code: -1, name: '全部' },
    { code: 0, name: '待审核' },
    { code: 9, name: '已入库' },
    { code: 3, name: '待发货' },
    { code: 4, name: '已发货' },
    { code: 5, name: '已拒绝' },
    { code: 7, name: '已取消' },
    { code: 8, name: '已退货' }
  ],
  '1': [
    { code: -1, name: '全部' },
    { code: 0, name: '待审核' },
    { code: 2, name: '待审核' },
    { code: 9, name: '已入库' },
    { code: 5, name: '已拒绝' },
    { code: 7, name: '已取消' }
  ],
  '2': [
    { code: -1, name: '全部' },
    { code: 0, name: '待审核' },
    { code: 3, name: '待发货' },
    { code: 4, name: '已发货' },
    { code: 5, name: '已拒绝' },
    { code: 7, name: '已取消' },
    { code: 8, name: '已退货' }
  ]
}

export const ORDER_STATE_NAME = {
  // '0': '待审核',
  // '1': '待审核',
  // '2': '待发货',
  // '3': '已发货',
  // '4': '已发货',
  // '5': '已拒绝',
  // '7': '已取消',
  // '8': '已退货'
  '0': '待发货',
  '1': '待收货',
  '2': '待发货',
  '9': '已拒绝',
}

export const ORDER_STATE = {
  REJECTED: 5, // 已拒绝
  CANCELLED: 7, // 已取消
  DISCARDED: 8 // 已退货
}

export const ALL_SYSTEM = {
  name: '全部',
  id: 'all_system'
}

export const POINT_OPERATE_TYPE = {
  '0': '系统处理',
  '1': '订单拒绝',
  '2': '订单退货',
  '3': '订单入库',
  '4': '订单发货',
  '5': '订单取消'
}
