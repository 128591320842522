import { checkMessMinimum } from '@/utils/common'
import { Dialog } from 'vant'
import { StockType } from '@/constants/common'
import { getLipStickCount } from '@/service/lipGuoqingTemp'
import { getFormats } from '@/utils/lipGuoqingTemp'
import { mapState } from 'vuex'

/**
 * 创建预订单
 * [立即购买]和[购物车下单]公用
 */
export default {
    computed: {
        ...mapState({
            brandId: state => state.global.brandId
        })
    },

    methods: {
        // 提交
        async submit(products, gifts = [], totalPrice, chooseGift) {
            const prepayOrder = { products, totalPrice, gifts, chooseGift };

            // 检查混补规则
            // let messChecked
            // try {
            //     messChecked = checkMessMinimum(this.brandId, prepayOrder.products, this.products);
            //     debugger
            // } catch (e) {
            //     await Dialog.alert({
            //         message: e.message
            //     })
            //     return
            // }

            // 检查商品最少下单数量
            // for (const item of prepayOrder.products) {
            //     if (messChecked.indexOf(item.id) >= 0) continue;
            //     if (item.minpurchase[item.userRank] && item.count < item.minpurchase[item.userRank]) {
            //         await Dialog.alert({ message: `亲，${item.name} 的下单数量最少为 ${item.minpurchase[item.userRank]} ${item.unit}~` })
            //         return
            //     }
            // }

            // 检查商品最少下单数量
            for (const product of products) {
                let purchaseNum = 0;
                let productId = '';
                let carts = product.carts || [];
                for (let i = 0; i < carts.length; i++) {
                    let item = carts[i];
                    let nextItem = carts[i + 1];
                    if (item.productId === productId) {
                        purchaseNum = purchaseNum + item.quantity;
                    } else {
                        purchaseNum = item.quantity;
                        productId = item.productId;
                    }
                    if (!nextItem || nextItem.productId !== productId) {
                        if (JSON.parse(item.minpurchase)[item.userRank] && purchaseNum < JSON.parse(item.minpurchase)[item.userRank]) {
                            await Dialog.alert({ message: `亲，${item.productName} 的下单数量最少为 ${JSON.parse(item.minpurchase)[item.userRank]} ${item.unit}~` })
                            return
                        }
                    }
                }
            }

            this.products = products

            // 设置预订单
            await this.$store.dispatch('preorder/reset')
            this.$store.commit('preorder/setField', prepayOrder)
            return 'pre-order'
        }
    }
}
