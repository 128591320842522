import store from '@/store'
import {
  HttpV1 as HTTP
} from '@/request'
// 判断是否能领取
export async function GiftJudgeJoin(queryUserId, userId, seniorId) {
  return await HTTP.get(`/agent/auth/gift-bag/judge-join`, { userId: store.state.user.user.id })
}
// 获取礼包信息
export async function GiftInfo() {
  return await HTTP.get(`/agent/auth/gift-bag/info`)
}
//获取订单信息
export async function GiftOrder() {
  return await HTTP.get(`/agent/auth/gift-bag/order`, { userId: store.state.user.user.id })
}
//领取礼包
export async function preOrder(params) {
  return await HTTP.post(`/agent/auth/gift-bag/pre-order`, params)
}