import {
  HttpV1 as HTTP
} from '@/request'
import axios from 'axios';
// 审核下级提交的升级申请
export async function getguide(params) {
  return HTTP.get(`/agent/guide`)
}
// 获取上传到七牛云的roken
export async function getToken(param) {
  return HTTP.get(`/config/qiniu/getToken `, param)
}
// 获取七牛云的配置
export async function getQiniuConfig(param) {
  return HTTP.get(`/config/qiniu/config`, param)
}
//上传bse64到七牛云上去
export async function sendBase64ToQiniu(domain, base64, token) {
  return await axios.post(`http://${domain}/putb64/-1`, base64.split("base64,")[1],
    {
      headers: { "Content-Type": "application/octet-stream", 'Authorization': `UpToken ${token}` }
    })
}
// 签约接口
export async function contractSignContract(params) {
  return HTTP.post(`/job/agent/contract/sign-contract`, params)
}