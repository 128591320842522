import { mapState, Store } from 'vuex'
import { Dialog, Toast } from 'vant'
import { sleep } from '@/utils/common'
import wx from 'weixin-js-sdk'
import * as giftApi from '@/service/gift/index'
/**
 * 用户登录
 */
export default {
  name: 'regist-gift',
  data() {
    return {
      orderInfo: null,
      address: null,
      GiftInfo: null,
      judgeJoinInfo: true,//是否可以领取
      phone: '',
      code: '',
      resendSmsCodeTimeleft: 0,
      captchaCode: {
        visible: false,
        url: ''
      }
    }
  },
  async  mounted() {
    this.address = this.$route.query.address ? JSON.parse(decodeURI(this.$route.query.address)) : null;
    console.log(this.address);
    this.judgeJoinInfo = await giftApi.GiftJudgeJoin();
    // 获取礼包的信息
    if (this.judgeJoinInfo) {
      this.getGiftInfo();
      this.getGiftOrder();
    }
  },
  computed: {
    ...mapState({
      userId: state => state.user.user.id,
    }),
  },

  methods: {
    // 关闭浏览器窗口
    clsoeWindow() {
      wx.closeWindow()
    },
    async getGiftInfo() {
      this.GiftInfo = await giftApi.GiftInfo();
      console.log(this.GiftInfo);
    },
    async getGiftOrder() {
      this.orderInfo = await giftApi.GiftOrder();
      console.log(this.orderInfo);
    // 吧地址信息放进去
    const {consigneeName,consigneePhone,consigneeProvince,consigneeCity,consigneeRegion,consigneeAddressDetail}=this.orderInfo;
    this.address={
      name:consigneeName,
      phone:consigneePhone,
      fullAddress:`${consigneeProvince} ${consigneeCity} ${consigneeRegion} ${consigneeAddressDetail}`
    }
    },
    // 领取礼包
    async toGetGift() {
      // 没有地址的时候
      if (this.address) {
        await giftApi.preOrder(
          {
            consignee: {
              name: this.address.name,
              phone: this.address.phone,
              province: this.address.region.city.province.name,
              city: this.address.region.city.name,
              region: this.address.region.name,
              detail: this.address.detail
            },
            userId: this.userId
          })
        Toast("申请礼包成功");
        this.getGiftOrder();
      }
      else {
        Toast("请选择地址");
      }

    },
    //跳转到我的地址
    toAddress() {
      //如果当前已选择了，则不允许跳转
      if (this.orderInfo) {
        return false;
      }
      this.$router.replace({
        name: "address-list",
        query: {
          from: "gift/regist_gift",
        }
      });
    }
  }
}
