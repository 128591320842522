import { getParamList } from '@/service/params/index';

export default {
    namespaced: true,

    state: {
        paramsList: []
    },

    mutations: {
        setParamsList(state, paramsList) {
            state.paramsList = paramsList;
        },
    },

    actions: {
        getParamsList({ commit, state }) {
            getParamList().then((res) => {
                commit('setParamsList', res);
            });
        }
    },

}