export const FrequentlyUsed = [
    {
        name: '我的团队',
        link: 'my-team',
        img: require("./../../../assets/images/product/my_team.png"),
    },
    {
        name: '订单审核',
        link: 'order-review',
        img: require("./../../../assets/images/product/order_review.png"),
    },
    {
        name: '订单发货',
        link: 'order-dispatch',
        img: require("./../../../assets/images/product/order_dispatch.png"),
    },
    {
        name: '零售发货',
        link: 'retail-order-dispatch',
        img: require("./../../../assets/images/product/retail_dispatch.png"),
    },
    {
        name: '邀请码',
        link: 'agent-invite',
        img: require("./../../../assets/images/product/invite_man.png"),
    },
    // {
    //     name: '防伪查询',
    //     link: '',
    //     img: require("./../../../assets/images/product/check_code.png"),
    // },
    // {
    //     name: '我的申请',
    //     link: '',
    //     img: require("./../../../assets/images/product/my_apply.png"),
    // },

    {
        name: '我的授权',
        link: 'agent-home',
        img: require("./../../../assets/images/product/my_impower.png"),
    },
    {
        name: "零售管理",
        link: "retail-order-home",
        img: require("./../../../assets/images/product/my_apply.png"),
    }
]