import ProductSelect from '@/components/product/product-select/index.vue';
import Rmb from '@/components/common/rmb/index.vue';
import EmptyList from '@/components/common/empty-list/index.vue';
import IndexTabbar from '@/components/common/index-tabbar/index.vue';
import PromptInformation from '@/components/personal/prompt-information/index.vue';
import DragableBox from '@/components/common/dragable-box/index.vue';
import CustomStepper from "@/components/common/custom-stepper/index.vue";
import { getDiscountPrice } from '@/utils/common';
import { mapState } from 'vuex';
import { Dialog, Toast } from 'vant';
import { getUser } from '@/service/auth';
import * as Service from '@/service/auth';
import { FrequentlyUsed } from './config';

/**
 * 商品列表
 */
export default {
    name: 'product-list',

    components: {
        ProductSelect,
        Rmb,
        IndexTabbar,
        EmptyList,
        PromptInformation,
        DragableBox,
        CustomStepper
    },

    data() {
        return {
            totalPrice: 0,
            cornerNum: 0,//购物车角标
            bannerImages: [
                require("./../../../assets/images/tmp/tmp1.png")
            ],
            isCartStep: true,

            activities: [
                // {
                //     img: require("./../../../assets/images/tmp/tmp1.png"),
                //     name: '活动1',
                //     link: '活动1',
                // },
                {
                    img: require("./../../../assets/images/tmp/tmp2.png"),
                    name: '活动2',
                    link: '活动2',
                },
                {
                    img: require("./../../../assets/images/tmp/tmp3.jpg"),
                    name: '活动3',
                    link: '活动3',
                }
            ]
        }
    },

    computed: {
        ...mapState({
            frequentlyUsed: state => {
                let paramsList = state.params.paramsList;
                paramsList.map(param => {
                    if (param.value == 0) {
                        FrequentlyUsed.map(d => {
                            if (param.name == d.name) {
                                d.link = '';
                                d.errormsg = '该功能暂未开放~';
                            }
                        })
                    }
                })
                let data = [...FrequentlyUsed];
                // //没有授权,或账号没等级
                // // console.log(this.user);
                // if ((!this.user.levelall) || this.user.vaild == 0 || this.user.levelall.length == 0) {
                //   // console.log(123);
                //   frequentlyUse.splice(1, 0, {
                //     name: '订单审核',
                //     link: 'order-review',
                //     isvip: true,
                //     img: require("./../../../assets/images/product/order_review.png"),

                //   })
                // }
                // else {
                //   // 部分用户的首页界面展示不一样
                //   if (!(this.user.levelall[0].rank === 5 || this.user.levelall[0].rank === 6)) {
                //     // console.log();
                //     frequentlyUse.splice(1, 0, {
                //       name: '订单审核',
                //       link: 'order-review',
                //       isvip: true,
                //       img: require("./../../../assets/images/product/order_review.png"),

                //     })
                //   }
                // }
                return data;
            },
            user: status => status.user.user,
            products: state => state.product.products,
            brandId: state => state.global.brandId,
            userId: state => state.user.user.id,
            limitProduct: state => state.product.limitProduct,
            plusFormatList: (state) => {
                const productList = state.product.products || [];
                const formats = [];
                productList.map((p) => {
                    return p.formats.map((f) => {
                        formats.push({ product: p, ...f });
                    });
                });
                return formats;
            },
        })
    },

    async created() {
        //  每次刷新时都去请求用户授权,防止用户页面改变
        await this.$store.dispatch('user/getUserData');
        await this.$store.dispatch('params/getParamsList');
        await this.init();
    },

    methods: {
        getDiscountPrice,

        // 常用功能链接跳转
        async toFunction(item) {
            if (item.errormsg) {
                Toast(item.errormsg);
                return;
            }
            // 如果没有link属性，则不跳转
            if (!item.link) {
                return
            }

            switch (item.link) {
                case 'agent-invite':
                    if (!await Service.judgeAuth()) {
                        Dialog.alert({
                            message: '您还没有授权，不能邀请下级'
                        })
                        return false
                    }
            }

            this.$router.push({
                name: item.link,
                query: { agentId: this.userId }
            })
        },
        /**
         * 初始化页面数据
         */
        async init() {
            const { brandId, userId } = this
            // 水光生：需要先完善个人信息
            if (this.$isSGS()) {
                const userInfo = await getUser(userId)
                const isEmpty = (s) => s === undefined || s === null || s === ''
                const fields = ['name', 'identity', 'wechat', 'gender', 'regionId', 'professionId', 'educationId'].map(key => userInfo[key])
                if (fields.some(isEmpty)) {
                    this.$refs['promptInformation'].show()
                    return
                }
            }

            await this.$store.dispatch('product/getProducts', {
                brandId, userId
            })

            //  获取限购的商品数量
            this.$store.dispatch('product/getLimitProductCount', {
                brandId, userId
            })
        },

        /**
         * 打开 SKU
         */
        showSelectPanel(product, type) {
            this.$refs['selectProduct'].show(product, type)
        },

        /**
         * 点击指引的下一步按钮
         */
        onNext() {
            this.isCartStep = false
        },

        // 点击轮播图
        onClickSwipe(index) {
            // this.$router.push({
            //     name: 'luck-draw-index'
            // })
        },

        getShowPrice(product) {
            var showPrice = 0;
            if (product.formats.length !== 0) {
                // var arr = product.formats.sort((num1, num2) => {
                //     return num1.price - num2.price < 0;
                // })
                let max = product.formats[0];
                product.formats.forEach(item => max = item > max ? item : max);
                var discount = JSON.parse(product.discount);
                showPrice = max.price - discount[product.userRank];
            }
            return showPrice;
        },

        // 判断是否限购商品
        isLimit(format) {
            const { limitProduct } = this;
            const isLimitProduct = limitProduct[format.id];
            if (isLimitProduct === 0) {
                return false;
            }
            return format.inventory > 0;
        },

        // 超出限购数量提示
        onOverLimitProduct(format) {
            const { limitProduct } = this;
            const limit = limitProduct[format.id];
            if (!limit) {
                return;
            }
            Toast(`亲，限购商品最多只能选${limit}箱哦～`);
        },

    }
}
