import PersonalInfo from '@/components/common/personal-info/index.vue'
import schema from 'async-validator'
import descriptor from './descriptor'
import { Toast } from 'vant'
import { updateUser, getUser } from '@/service/auth'
import { mapState } from 'vuex'
import { pick } from 'lodash'

const validator = new schema(descriptor)

/**
 * 完善个人信息
 */
export default {
  name: 'complement-information',

  components: {
    PersonalInfo
  },

  computed: {
    ...mapState({
      userId: state => state.user.user.id
    })
  },

  methods: {
    // 恢复表单
    async restore() {
      const userInfo = await getUser(this.userId)
      this.$refs['personalInfo'].setFormData(userInfo)
    },

    // 提交
    onSubmit() {
      const formData = this.$refs['personalInfo'].getFormData()

      validator.validate(formData, async errors => {
        if (errors && errors.length > 0) {
          Toast(errors[0].message)
          return
        }

        await updateUser(this.userId, {
          name: formData.name.trim(),
          wechat: formData.wechat.trim(),
          ...pick(formData, [
            'identity',
            'gender',
            'regionId',
            'professionId',
            'educationId',
          ])
        })

        Toast('成功完善个人信息，请继续浏览～')
        // 跳转首页
        const homePage = this.$store.state.permission.permission.app['home-page']
        this.$router.replace({
          name: homePage || 'product-list'
        });
      })
    }
  }
}
