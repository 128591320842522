/**
 * 零售订单
 */
import { cloneDeep as clone } from 'lodash'

const PRE_ORDER = {
  addressInfo: {},  //  地址信息
  productList:[], //  要下单的商品
  type: '0',  //  0发给代理，1发给零售
}

export default {
  namespaced: true,

  state: clone(PRE_ORDER),

  mutations: {
    // 总方法，设置字段
    setField (state, keyValueObject) {
      for (const [key, value] of Object.entries(keyValueObject)) {
        if (state.hasOwnProperty(key)) {
          state[key] = value
        }
      }
    },
  },

  actions: {
    // 重置
    reset ({ commit }) {
      commit('setField', clone(PRE_ORDER))
    }
  }
}
