import 'babel-polyfill';
import Vue from 'vue';
import App from './App.vue';
import store from '@/store';
import router from '@/router';
import moment from 'moment';
import '@/request';
import '@/utils/prototype';
import '@/utils/directive';
import '@/utils/event-bus';
import 'vant/lib/index.css';
import {
    Lazyload,
    Loading,
    Cell,
    CellGroup,
    Collapse,
    CollapseItem,
    Popup,
    Picker,
    Checkbox,
    CheckboxGroup,
    DatetimePicker,
    Tabbar,
    TabbarItem,
    Tabs,
    Tab,
    Button,
    NoticeBar,
    Form,
    Field,
    List,
    Dialog,
    Toast,
    Stepper,
    Area,
    Swipe,
    SwipeItem,
    Icon,
    IndexAnchor,
    IndexBar,
    Image,
    ActionSheet,
    Radio,
    RadioGroup,
    Overlay,
    Empty,
} from 'vant';
import MintUI from 'mint-ui';
import 'mint-ui/lib/style.css';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/zh-cn';
import filter from './filters/index'; //引入filter文件
import VueJsonp from 'vue-jsonp';
import LuckDraw from 'vue-luck-draw'; // 大转盘插件

Vue.use(VueJsonp);

Vue.use(MintUI); // 使用mint-ui全部组件
moment.locale('zh-cn');
Vue.config.productionTip = false;
filter(Vue);
Vue.use(Lazyload);
Vue.use(Loading);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(DatetimePicker);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Tabs);
Vue.use(Tab);
Vue.use(Button);
Vue.use(NoticeBar);
Vue.use(Form);
Vue.use(Field);
Vue.use(List);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Stepper);
Vue.use(Area);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Icon);
Vue.use(IndexAnchor);
Vue.use(IndexBar);
Vue.use(Collapse)
    .use(CollapseItem)
    .use(Image)
    .use(ActionSheet)
    .use(RadioGroup)
    .use(Radio)
    .use(Overlay);
Vue.use(LuckDraw);
Vue.use(Empty);

dayjs.locale('zh-cn');
dayjs.extend(customParseFormat);
import VConsole from 'vconsole';
import * as fundebug from 'fundebug-javascript';
import fundebugVue from 'fundebug-vue';

if (process.env.VUE_APP_DEBUG_TOOL === 'VCONSOLE') {
    // 非生产环境，开启VConsole
    new VConsole();
} else if (process.env.VUE_APP_DEBUG_TOOL === 'FUNDEBUG') {
    //  生产环境，开启fundebug
    fundebug.apikey = process.env.VUE_APP_FUNDEBUG_API_KEY;
    fundebugVue(fundebug, Vue);
}
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
