import { Http } from '@/request'

export default {
  namespaced: true,
  state: {
    stockOrderType: -1,
    stockOrderState: -1,
    products: [],
    brand: {}
  },
  mutations: {
    setStockOrderState (state, stockOrderState) {
      state.stockOrderState = stockOrderState
    },
    setStockOrderType (state, stockOrderType) {
      state.stockOrderType = stockOrderType
    },
    setStocks (state, products) {
      state.products = products
    },
    setBrand (state, brand) {
      state.brand = brand
    }
  },
  actions: {
    // 设置当前stock type
    async setStockOrderType ({ commit }, type) {
      commit('setStockOrderType', type)
    },
    // 设置当前stock state
    async setStockOrderState ({ commit }, state) {
      commit('setStockOrderState', state)
    },
    async placeStockInOrder ({ commit }, params) {
      // params: { brandId, totalPrice, products, receipts }
      await Http.post('stock/order/stockIn', params)
    },
    async placeStockOutOrder ({ commit }, params) {
      await Http.post('stock/order/delivery', params)
    },
    async loadOrders ({ commit }, params) {
      // params: { brandId, state, type, limit, offset }
      try {
        const res = await Http.get('stock/order', params)
        return res
      } catch (e) {}
    },
    async getOrderDetail ({ commit }, { orderId }) {
      try {
        const res = await Http.get(`stock/order/${orderId}`)
        return res
      } catch (e) {}
    },
    async cancelOrder ({ commit }, { orderId, cancelReason }) {
      await Http.delete(`stock/order/${orderId}`, {cancelReason})
    },
    async getProducts ({ commit }, { brandId }) {
      try {
        const res = await Http.get('stock/product', {brandId})
        return res
      } catch (e) {}
    },
    async getStocks ({ commit }, { brandId }) {
      try {
        const res = await Http.get('stock/inventory', {brandId})
        commit('setStocks', res)
        return res
      } catch (e) {}
    },
    async getBrand ({ commit }, { brandId }) {
      const res = await Http.get('brand', {brandId})
      commit('setBrand', res)
    }
  }
}
