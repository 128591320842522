import { pick } from 'lodash';
import dayjs from 'dayjs';
import store from '@/store';
import { APP } from '@/constants/system.js';
export function oneIsEmpty() {
    return [...arguments].some(value => (value instanceof Array ? value.length === 0 : !value));
}

export function sleep(milliseconds) {
    return new Promise(resolve => {
        window.setTimeout(() => resolve(), milliseconds);
    });
}

export function getDiscountPrice(discount = {}, userRank, price = 0) {
    if (userRank == undefined) {
        return 0;
    }
    const discountPrice = discount[userRank];
    return price - (discountPrice || 0);
}

export function _checkMessMinimum(products, originProducts, rules) {
    let messChecked = [];
    for (const rule of rules) {
        if (!rule.premise) rule.premise = 0;
        const _checks = [];
        let hit = 0;
        let totalCount = 0;
        for (const product of products) {
            let excludePass = false;
            if (rule.excludes) {
                for (const exclude of rule.excludes) {
                    if (product.name.indexOf(exclude) >= 0) {
                        excludePass = true;
                        break;
                    }
                }
            }
            if (excludePass) continue;
            if (rule.names.indexOf(product.name) >= 0 || rule.names === 'all') {
                _checks.push(product.id);
                hit += 1;
                if (product.name.indexOf('口红') >= 0) {
                    totalCount += product.count / 70;
                    const index = originProducts.findIndex(tmp => tmp.id === product.id);
                    const _product = originProducts[index];
                    if (
                        _product.minpurchase[_product.userRank] &&
                        product.count < _product.minpurchase[_product.userRank]
                    ) {
                        throw new Error(
                            `亲，【${_product.name}】的下单数量最少为${_product.minpurchase[_product.userRank]}${
                                _product.unit
                            }~`
                        );
                    }
                } else {
                    totalCount += product.count;
                }
            }
        }
        if (hit > 1 && totalCount >= rule.premise) {
            if (totalCount < rule.minimum) {
                throw new Error(`亲，混补的下单数量最少为${rule.minimum}哦~`);
            }
            messChecked = messChecked.concat(_checks);
        }
    }
    return messChecked;
}

export function checkMessMinimum(brandId, products, originProducts) {
    const isTingBrand = brandId === 'de054241-4999-11e7-9381-edd722a8af8d';
    let messRules;
    if (isTingBrand) {
        messRules = [
            {
                names: 'all',
                minimum: 1,
                premise: 10,
                excludes: ['汀洗护', '汀低卡棒'],
            },
            {
                names: ['汀低卡棒', '睡眠瘦'],
                minimum: 1,
            },
        ];
    } else {
        messRules = [
            {
                names: ['白皙摇BB', '白皙卸妆水', '白皙眼宝宝'],
                minimum: 2,
            },
            {
                names: ['白皙瀑水乳', '白皙黄金水', '白皙白白霜'],
                minimum: 2,
            },
        ];
    }
    return _checkMessMinimum(products, originProducts || products, messRules);
}

export function wrapOrderProducts(products, selection) {
    const wrappedProducts = [];

    products.forEach(product => {
        if (!product.changed) {
            // 如果商品有效
            const tempProduct = { ...product };
            delete tempProduct.changed;
            tempProduct.count = 0;
            tempProduct.formats = [];

            for (const key of Object.keys(product.formats)) {
                const format = product.formats[key];
                if ((!selection || selection[key]) && !format.changed) {
                    tempProduct.formats.push({
                        id: key,
                        name: format.name,
                        count: format.count,
                        price: format.price,
                        changed: format.changed,
                        userRank: format.userRank,
                        convertCount: format.convertCount,
                        showUnit: format.showUnit,
                    });
                    tempProduct.count += format.count;
                    // product.count -= format.count
                }
            }

            if (tempProduct.formats.length) {
                wrappedProducts.push(tempProduct);
            }
        }
    });

    return wrappedProducts;
}

// 获取选中的商品
export function newWrapOrderProducts(products, selection) {
    const wrappedProducts = [];

    products.forEach(product => {
        var carts = [];
        product.carts.forEach((cart, i) => {
            if (cart.state) {
                const tempCart = { ...cart };
                if (selection[product.systemId].list[i].select) {
                    carts.push(tempCart);
                }
            }
        });
        var obj = {
            carts: carts,
            systemId: product.systemId,
            systemName: product.systemName,
        };
        wrappedProducts.push(obj);
    });

    return wrappedProducts;
}

// 计算选择商品价格
export function newGetTotalPrice(products) {
    let totalPrice = 0;

    products.map(p => {
        for (const carts of p.carts) {
            totalPrice += carts.quantity * getDiscountPrice(JSON.parse(carts.discount), carts.userRank, carts.price);
        }
    });

    return totalPrice;
}

// 计算选择商品总数
export function newGetTotalAmount(products) {
    let totalAmount = 0;

    products.map(p => {
        for (const carts of p.carts) {
            totalAmount += carts.quantity;
        }
    });

    return totalAmount;
}

/**
 * 将出售单位转换为限制单位
 * @param {Object} product 商品
 */
export function convertUnit2LimitUnit(product) {
    if (product.type === 0) {
        return (product.count * product.unitNumber) / product.limitUnit.number;
    }
    return 0;
}

// 判断 format 是否不可用（失效/没有库存）
export function isFormatInvalid(product, format) {
    return product.changed || format.changed || format.inventory < format.count;
}

/**
 * 计算商品的价格
 * @param {*} product
 * @param {*} discount
 * @param {*} userRank
 */
export function getPriceByProduct(product, discount, userRank) {
    return product.formats.reduce(
        (result, item) => result + getDiscountPrice(discount, userRank, item.price) * item.count,
        0
    );
}

/**
 * 计算总价
 */
export function getTotalPrice(products, isPlusProduct = false) {
    let totalPrice = 0;

    if (isPlusProduct) {
        for (const product of products) {
            for (const format of Object.values(product.formats)) {
                if (!isFormatInvalid(product, format)) {
                    totalPrice += format.count * getDiscountPrice(product.discount, format.userRank, format.price);
                }
            }
        }
    } else {
        for (const product of products) {
            for (const format of Object.values(product.formats)) {
                if (!isFormatInvalid(product, format)) {
                    totalPrice += format.count * getDiscountPrice(product.discount, product.userRank, format.price);
                }
            }
        }
    }

    return totalPrice;
}

/**
 * 计算总数
 */
export function getTotalAmount(products) {
    let totalAmount = 0;

    for (const product of products) {
        for (const format of Object.values(product.formats)) {
            if (!isFormatInvalid(product, format)) {
                totalAmount += format.count;
            }
        }
    }

    return totalAmount;
}

/**
 * 获取商品库存
 */
export function getProductStock(product) {
    return product.formats.reduce((total, item) => total + item.stockCount, 0);
}

export function getCurrentSeason(date) {
    let y = '';
    let m = '';
    if (typeof date === 'string') {
        const dateStr = date.split('-');
        y = Number(dateStr[0]);
        m = Number(dateStr[1]);
    } else {
        date = date ? date : new Date();
        y = date.getFullYear();
        m = date.getMonth() + 1;
    }
    if (m <= 3) {
        return [y, '1-3'];
    }

    if (m <= 6) {
        return [y, '4-6'];
    }

    if (m <= 9) {
        return [y, '7-9'];
    }

    if (m <= 12) {
        return [y, '10-12'];
    }
}

export function getCurrentSeason2Q(date) {
    date = date ? date : new Date();
    const y = date.getFullYear();
    const m = date.getMonth() + 1;
    if (m <= 3) {
        return `第一季度`;
    }

    if (m <= 6) {
        return `第二季度`;
    }

    if (m <= 9) {
        return `第三季度`;
    }

    if (m <= 12) {
        return `第四季度`;
    }
}

//  返回检查商品数量对应的结构
export const getCheckProductStuct = products => {
    return [
        {
            products: products.map(product => {
                product.formats &&
                    product.formats.forEach(format => {
                        if (format.inventory) {
                            delete format.inventory;
                        }
                    });
                return pick(product, ['id', 'name', 'count', 'formats', 'isQualityGoods']);
            }),
        },
    ];
};

//  根据指定日期返回本月的开始时间
export const getStartedAt = (currentDate = {}) => {
    let date = null;
    if (typeof currentDate.isAfter === 'function') {
        date = currentDate;
    } else {
        date = dayjs(currentDate);
    }
    //  2019-10-25政策改变
    if (date.isAfter(dayjs('2019-10-01 00:00:00')) && date.isBefore(dayjs('2019-10-25 00:00:00'))) {
        //  如果是2019-10月的话，则返回的date是2019-10-24 23:59:59
        return `2019-10-01 00:00:00`;
    }
    if (date.isAfter(dayjs('2019-10-24 23:59:59')) && date.isBefore(dayjs('2019-12-01 00:00:00'))) {
        return `2019-10-25 00:00:00`;
    }
    return date.startOf('month').format('YYYY-MM-DD HH:mm:ss');
};

//  根据指定日期返回本月的结束时间
export const getEndedAt = (currentDate = {}) => {
    let date = null;
    if (typeof currentDate.isAfter === 'function') {
        date = currentDate;
    } else {
        date = dayjs(currentDate);
    }
    //  2019-10-25政策改变
    if (date.isAfter(dayjs('2019-10-01 00:00:00')) && date.isBefore(dayjs('2019-10-25 00:00:00'))) {
        //  如果是2019-10月的话，则返回的date是2019-10-24 23:59:59
        return `2019-10-24 23:59:59`;
    }
    if (date.isAfter(dayjs('2019-10-24 23:59:59')) && date.isBefore(dayjs('2019-12-01 00:00:00'))) {
        return `2019-11-30 23:59:59`;
    }
    return date.endOf('month').format('YYYY-MM-DD HH:mm:ss');
};

export const getDate = dateStr => {
    if (!dateStr) {
        return new Date();
    }
    return dayjs(dateStr).toDate();
};

const monthMap = {
    '0': '一',
    '1': '二',
    '2': '三',
    '3': '四',
    '4': '五',
    '5': '六',
    '6': '七',
    '7': '八',
    '8': '九',
    '9': '十',
    '10': '十一',
    '11': '十二',
};

export const getMonthStr = currentDate => {
    if (!currentDate) {
        return;
    }
    let date = null;
    if (typeof currentDate.isAfter === 'function') {
        date = currentDate;
    } else {
        date = dayjs(currentDate);
    }
    const mm = date.month();
    return monthMap[mm];
};

export function isDef(val) {
    return val !== undefined && val !== null;
}

export function getFormatList(products) {
    const formats = [];
    products.forEach(p => {
        formats.push(...p.formats);
    });
    return formats;
}
/* 判断当前系统的名字是否是实力瘦
TODO 极度不规范，但是前端这边先这样子弄，后面应该弄成根据后台的返回路由文件路径，
逻辑：判断是否实力瘦，现在实力瘦有部分ui上的不同
2020-05-08 mk
*/
export function ISSHILISHOU() {
    return store.state.permission.permission.app.title.indexOf(APP.SHILISHOU) >= 0;
}

//  同上，判断是否为轻部落系统
export function ISQINGBULUO() {
    return store.state.permission.permission.app.title.indexOf(APP.QINGBULUO) >= 0;
}

export function getUrlParams(url) {
    const paramMap = {};
    if (!url) {
        return paramMap;
    }
    const paramsStr = url.substring(url.indexOf('?') + 1);
    const paramArr = paramsStr.split('&');
    paramArr.forEach(p => {
        const param = p.split('=');
        paramMap[param[0]] = param[1];
    });
    return paramMap;
}

export function toExpress(item, e) {
    e.preventDefault();
    window.location.href = `https://m.kuaidi100.com/result.jsp?nu=${item.trackingNumber}&com=${item.com}`;
}

export function toLogin(brandId) {
    window.location.href = `#/login?brandId=${brandId}`;
}
