import { HttpV1 as HTTP } from '@/request'


/**
 * 判断是否为管理员
 */
export async function getIsManager ({ id }) {
  return await HTTP.get(`/agent/user/is-manager`, { id })
}

/**
 * 发送短信
 * @param {*} param0 
 */
export function sendSms ({ phone, btype = "2", ptype, areacode }) {
  return HTTP.post(`/agent/login/smsaliyun`, { phone, btype, ptype, areacode })
}