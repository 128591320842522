import { getDiscountPrice } from '@/utils/common'
import dayjs from 'dayjs'
import { ORDER_STATE_NAME, ORDER_STATE, StockOrderState } from '@/constants/common'
import UploadImage from '@/components/common/upload-image/index.vue'
import Rmb from '@/components/common/rmb/index.vue'
import FormatItem from '@/components/common/format-item/index.vue'
import CancelOrder from '@/components/order/cancel-order/index.vue'
import Event from '@/constants/event-bus'

/**
 * (出库)订单详情
 */
export default {
  name: 'stock-order-detail',

  data () {
    return {
      ORDER_STATE_NAME,
      ORDER_STATE,
      currentOrder: null, // 当前订单
      cancelDialogVisible: false
    }
  },

  components: {
    UploadImage,
    Rmb,
    FormatItem,
    CancelOrder,
  },

  methods: {
    getDiscountPrice,

    // 初始化
    async init () {
      this.currentOrder = await this.$store.dispatch('stock/getOrderDetail', { orderId: this.$route.query.orderId })
      this.currentState = StockOrderState[this.currentOrder.type].find(item => item.code === this.currentOrder.state)
    },

    // 尝试取消订单
    cancelOrder () {
      this.$refs['cancelOrder'].show()
    },

    // 确定取消订单
    async afterCancelOrder (cancelReason) {
      try {
        await this.$store.dispatch('stock/cancelOrder', {
          orderId: this.currentOrder.id,
          cancelReason
        })
        this.currentOrder.state = ORDER_STATE.CANCELLED // 修改本订单
        this.$bus.$emit(Event.stockOrderList.refresh) // 通知list页面刷新
        this.$router.go(-1) // 后退
      } catch (e) {
        await this.init()
      }
    },

    // 日期格式化
    formatDate (date) {
      return dayjs(date).format('YYYY-MM-DD HH:mm')
    },
  },

  async created () {
    await this.init()
  }
}
