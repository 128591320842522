import { Http, HttpV1 } from '@/request';
import Vue from 'vue';
import { StockType } from '@/constants/common';
import { getToken, setToken } from '@/utils/localStorage';
import { putAddCart, getCart } from '@/service/cart/index';
/**
 * 购物车本地存储
 * 层级：cart[类型(StockType)][品牌] => products
 */
export default {
    namespaced: true,

    state: {
        cart: {
            [StockType.Sell]: {},
            [StockType.StockIn]: {},
            [StockType.StockOut]: {}
        }
    },

    mutations: {
        setCart(state, { cart, type }) {
            state.cart[type] = cart;
        }
    },

    actions: {
        // 添加商品到购物车
        async addToCart({ commit, state }, { userId, brandId, product, type }) {
            // const cart = state.cart[type] || {};
            // const formats = product.formats;

            // if (!cart[brandId]) {
            //     Vue.set(cart, brandId, []);
            // }

            // const productIndex = cart[brandId].findIndex(item => item.id === product.id);
            // if (productIndex !== -1) { // 如果该商品已存在
            //     const cartProduct = cart[brandId][productIndex]
            //     cartProduct.count += product.count
            //     for (const key of Object.keys(formats)) {
            //         if (cartProduct.formats[key]) {
            //             cartProduct.formats[key].count += formats[key].count
            //         } else {
            //             cartProduct.formats[key] = formats[key]
            //         }
            //     }
            //     // 在头部加入
            //     cart[brandId].splice(productIndex, 1)
            //     cart[brandId].unshift(cartProduct)
            // } else { // 如果商品不存在
            //     //把levelid放在format中去
            //     product.formats = formats
            //     cart[brandId].unshift(product) // 在头部加入
            // }

            let list = [];
            Object.keys(product.formats).map(key => {
                let params = {
                    userId: userId,
                    productId: product.id,
                    formatId: key,
                    formatName: product.formats[key].name,
                    productName: product.name,
                    thumb: product.thumb,
                    unit: product.unit,
                    systemId: product.systemId,
                    levelId: product.levelId,
                    userRank: product.userRank,
                    price: product.formats[key].price,
                    discount: product.discount,
                    quantity: product.formats[key].count,
                    decMinStep: product.decMinStep,
                    minstep: product.minstep,
                    minpurchase: product.minpurchase
                };
                list.push(params);
            })

            await putAddCart({ formats: list });

            // try {
            //     await HttpV1.put('/agent/cart', {
            //         contents: JSON.stringify(cart) || '',
            //         token: getToken(),
            //         // type: type
            //     })
            //     commit('setCart', { cart, type })
            // } catch (e) {
            //     console.error(e)
            // }
        },

        // 获取购物车
        async getCart({ commit }, { type, userId }) {
            const { systemCarts: cart } = await getCart(userId);
            commit('setCart', { cart, type });
            // try {
            //     const { content: cart } = await HttpV1.get('/agent/cart', {
            //         token: getToken()
            //     }) || {}
            //     commit('setCart', { cart, type })
            // } catch (e) { }
        },

        // 更改购物车
        async updateCart({ commit, state }, { brandId, products, type }) {
            const cart = state.cart[type]
            cart[brandId] = products

            try {
                await HttpV1.put('/agent/cart', {
                    token: getToken(),
                    contents: JSON.stringify(cart),

                    // type: type
                })
                commit('setCart', { cart, type })
            } catch (e) { }
        },

        // 检查商品是否失效
        async checkValid({ commit }, products) {
            products = products.map(item => {
                const newItem = {}
                for (const key in item) {
                    if (['changed'].indexOf(key) < 0) {
                        newItem[key] = item[key]
                    }
                }
                return newItem
            })
            const data = await HttpV1.post('agent/cart/product/valid', {

                token: getToken(),
                contents: JSON.stringify(products)
            })
            return data
        },

    }
}
