/**
 * 上传图片组
 */
import { chooseImage, previewImage, uploadImage, getLocalImgData } from '@/utils/wx-promise';

export default {
    name: 'upload-image',

    model: {
        prop: 'images',
        event: 'change',
    },

    props: {
        images: {
            // 传入的图片数组: Array<Object>
            type: Array,
            required: true,
        },
        max: {
            // 最大图片数
            type: Number,
            default: 5,
        },
        disabled: {
            // 是否禁用
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            localImages: [], // 当前图片
        };
    },

    watch: {
        // 父 => 子
        images: {
            immediate: true,
            handler(val) {
                this.localImages = this.images;
            },
        },

        // 子 => 父
        localImages(val) {
            this.$emit('change', val);
        },
    },

    filters: {
        getURL(image) {
            return image.localData || image.localId || image.serverId || image.qiniuUrl || image;
        },
    },

    methods: {
        /**
         * 预览图片
         */
        async onPreview(item, index, images) {
            await previewImage(
                item.localId || item,
                images.map(item => item.localId || item.qiniuUrl || item)
            );
        },

        /**
         * 点击添加
         */
        async onAdd() {
            let newLocalIds = await chooseImage(this.max - this.images.length);

            /**
             * 踩坑记录：
             * wx.getLocalImgData函数不能用于并发，
             * 所以在多个图片上传时需要依次调用
             */
            let newImages = [];
            for (const localId of newLocalIds) {
                let ret = { localId };
                if (window.__wxjs_is_wkwebview) {
                    ret.localData = await getLocalImgData(localId);
                }
                ret.serverId = await uploadImage(localId);
                newImages.push(ret);
            }

            //   this.localImages = this.localImages.concat(newImages) // 注：尽量以一种Immutable的方式操作对象类型的数据
            this.localImages = [...this.localImages, ...newImages];
        },

        /**
         * 删除1张图片
         */
        onDelete(item, index) {
            this.localImages = [...this.localImages.slice(0, index), ...this.localImages.slice(index + 1)];
        },
    },
};
