/**
 * [预订单]，用于下单页面：
 * 因为刷新、选择地址后跳回等操作都需要保存状态，
 * 而下单页又不想采用 keep-alive，
 * 所以需要借助 Vuex
 */
import { cloneDeep as clone } from 'lodash'

const PRE_ORDER = {
    lipExperienceList: [], //  用户选的赠品口红列表
    lipCount: 0, // 赠送口红数量
    products: [], // 商品列表
    gifts: [], // 赠品列表
    samples: [], // 体验装列表
    plusProducts: [], // 加购装列表
    totalPrice: 0, // 总价
    coupons: [], // 优惠券
    address: null, // 地址
    images: [], // 凭证
    remark: '', // 备注
    chooseGift: false,   // 是否需要赠品
    userPointValue: null, // 积分抵扣
}

export default {
    namespaced: true,

    state: clone(PRE_ORDER),

    mutations: {
        // 总方法，设置字段
        setField(state, keyValueObject) {
            for (const [key, value] of Object.entries(keyValueObject)) {
                if (state.hasOwnProperty(key)) {
                    state[key] = value
                }
            }
        },
        //  设置用户选中的口红
        setLipExperienceList(state, lipExperienceList) {
            state.lipExperienceList = lipExperienceList
        }
    },

    actions: {
        // 重置
        reset({ commit }) {
            commit('setField', clone(PRE_ORDER))
        }
    }
}
