/**
 * 订单发货
 */
import { cloneDeep as clone } from 'lodash';

const PRE_ORDER = {
    junior: {},   // 下单人
    addressInfo: {},  //  地址信息
    productList: [], //  要下单的商品
    totalPrice: '',  //  总价格
}

export default {
    namespaced: true,

    state: clone(PRE_ORDER),

    mutations: {
        // 总方法，设置字段
        setField(state, keyValueObject) {
            for (const [key, value] of Object.entries(keyValueObject)) {
                if (state.hasOwnProperty(key)) {
                    state[key] = value
                }
            }
        },
    },

    actions: {
        // 重置
        reset({ commit }) {
            commit('setField', clone(PRE_ORDER))
        }
    }
}