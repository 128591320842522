/**
 * 水光生
 */
import { Http, HttpV1 } from '@/request'
import { sumBy } from 'lodash'

export default {
  namespaced: true,

  state: {
    gifts: [], // 赠品
    samples: [], // 体验装
    plusProducts: [], // 加购商品
  },

  mutations: {
    setGifts (state, gifts) {
      state.gifts = gifts || []
    },

    setSamples (state, val) {
      state.samples = val || []
    },

    setPlusProducts (state, val) {
      state.plusProducts = val || []
    }
  },

  getters: {
    totalGiftCount: state => sumBy(state.gifts, product => sumBy(product.formats, format => format.count)),
    totalSampleCount: state => sumBy(state.samples, product => sumBy(product.formats, format => format.count)),
    totalPlusProductCount: state => sumBy(state.plusProducts, product => sumBy(product.formats, format => format.count)),
  },

  actions: {
    // 获取赠品
    async getGifts ({ commit, state, rootState }) {
      const products = await Http.get('/products/gift', {
        brandId: rootState.global.brandId
      })
      for (const p of products) {
        for (const f of p.formats) {
          if (!f.hasOwnProperty('count')) {
            f.count = 0
          }
        }
      }
      commit('setGifts', products)
    },

    // 获取试用装
    async getSamples ({ commit, state, rootState }) {
      const samples = await Http.get('/products/experience', {
        brandId: rootState.global.brandId
      })
      for (const p of samples) {
        for (const f of p.formats) {
          if (!f.hasOwnProperty('count')) {
            f.count = 0
          }
        }
      }
      commit('setSamples', samples)
    },

    // 获取加购商品
    async getPlusProducts ({ commit, state, rootState }, { formats }) {
/*       const plusProducts = await Http.get('/products/plus-product', {
        brandId: rootState.global.brandId
      }) */
      //  2019-11-11加价购商品 不只是水光生的
      const { products: plusProducts } = await HttpV1.post('/agent/national/plus-products', {
        brandId: rootState.global.brandId,
        formats
      })
      for (const p of plusProducts) {
        for (const f of p.formats) {
          if (!f.hasOwnProperty('count')) {
            f.count = 0
          }
        }
      }
      commit('setPlusProducts', plusProducts)
    },
  }
}
