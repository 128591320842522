 export const Menus = [
    {
        to: "product-list",
        tos: ["product-list", "home-index"],
        icon: "iconshouye",
        label: "首页",
    },
    {
        to: "rebate-sum",
        tos: [
            "shilishou-share-holders-rebate-home",
            "shilishou-strategy-rebate-home",
            "shilishou-co-founder-rebate-home",
            "nov-new-rebate-home"
        ],
        label: "业绩",
        icon: "iconyeji",
    },
    {
        to: "enroll-list",
        label: "活动",
        icon: "iconhuodong",
    },
    {
        to: "order-list",
        label: "订单",
        icon: "icondingdan",
    },
    {
        to: "personal-center",
        label: "我的",
        icon: "iconwode",
    },
];