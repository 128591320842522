import ax from 'axios'
import { Toast, Dialog } from 'vant'
import wx from 'weixin-js-sdk'
import store from '@/store'
import { removeToken, removePersisternStorage } from '@/utils/localStorage'
import { interceptRequest, getResponseInterceptor, getInterface } from './common'

const axios = ax.create({
    baseURL: process.env.VUE_APP_API_ROOT_V1,
    headers: {
        'Content-Type': 'application/json'
    }
})

interceptRequest(axios)

// v1接口返回的body结构：
//
// response = {
//   data: {
//     data: {},
//     code: 200,
//     message: 'succeed'
//   }
// }
axios.interceptors.response.use(
    async response => {
        store.commit('memory/removeReqCount')

        const body = response.data
        const { data, code, message } = body
        // if (code == 401) {
        //     removeToken()
        //     removePersisternStorage()
        //     wx.closeWindow()
        // }
        if (message && message !== 'succeed') {
            Toast(message)
        }
        return data
    },
    getResponseInterceptor()
)

export default getInterface(axios)
