import { HttpV1 as HTTP } from "@/request";

/**
 * 查询零售的商品列表
 */
export async function getRetailProducts({ token }) {
  const data = await HTTP.get(`/agent/retail`, { token });
  return data;
}

/**
 * 发货
 * sourceCode = {'code':'type'}
 */
export async function deliverRetailOrder({
  code,
  trackCompany,
  trackingNumber,
  sourceCode,
  token,
  juniorId,
}) {
  const data = await HTTP.put(`/agent/retail/deliver`, {
    code,
    trackCompany,
    trackingNumber,
    sourceCode,
    token,
    juniorId,
  });
  return data;
}

/**
 * 提交零售订单
 */
export async function submitRetailOrder({
  token,
  type,
  addressInfo,
  productList,
  juniorId,
}) {
  const data = await HTTP.post(`/agent/retail/formSuborder`, {
    token,
    type,
    addressInfo,
    productList,
    juniorId,
  });
  return data;
}

/**
 * 获取零售客户列表
 */
export async function getRetailCustomerList({ token }) {
  const data = await HTTP.get(`/agent/retail/junior`, {
    token,
  });
  return data;
}

/**
 * add零售地址
 */
export async function addRetailAddress({ token, name, phone, addressInfo }) {
  const data = await HTTP.post(`/agent/retailUser/insertRetail`, {
    token,
    name,
    phone,
    addressInfo,
  });
  return data;
}

/**
 * uodate零售地址
 */
export async function updateRetailAddress({
  id,
  name,
  phone,
  addressInfo,
  factorId,
  token,
}) {
  const data = await HTTP.post(`/agent/retailUser/updateRetail`, {
    id,
    name,
    phone,
    addressInfo,
    factorId,
    token,
  });
  return data;
}

/**
 * 删除零售地址
 */
export async function deleteRetailAddress({ retailId }) {
  const data = await HTTP.get(`/agent/retailUser/deleteRetail`, {
    retailId,
  });
  return data;
}

/**
 * 获取零售订单列表
 */
export async function getRetailOrderList({ state, token }) {
  const data = await HTTP.get(`/agent/retail/retailList`, {
    token,
    state,
  });
  return data;
}

/**
 * 获取零售订单列表
 */
export async function cancelOrder({ code, token }) {
  const data = await HTTP.get(`/agent/retail/cancelSuborder`, {
    token,
    code,
  });
  return data;
}

/**
 * 获取零售订单详情
 */
export async function getRetailOrderDetail({ code, token }) {
  const data = await HTTP.get(`/agent/retail/retailDetail`, {
    token,
    code,
  });
  return data;
}

/**
 * 生成零售订单信息并直接发货
 */
export async function createOrDeliverSuborder({ userId, addressInfo, productList, sourceCode}) {
  const data = await HTTP.post(`/agent/retail/createOrDeliverSuborder`, {
    userId,
    addressInfo,
    productList,
    sourceCode
  });
  return data;
}
