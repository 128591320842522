import { HttpV1 } from '@/request'

export default {
  namespaced: true,
  actions: {
    // 获取微信jssdk配置
    async getJsConfig({ commit }) {
      try {
        const { data } = await HttpV1.get('wechat/config')
        return data;
      } catch (e) { }
    }
  }
}
