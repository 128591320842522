/**
 * 试用装模块
 * 分享链接中的参数，放在Vuex中
 */
export default {
  namespaced: true,

  state: {
    openId: null,
    productId: null
  },

  mutations: {
    setOpenId (state, val) {
      state.openId = val
    },

    setProductId (state, val) {
      state.productId = val
    }
  }
}
