/**
 * 个人中心
 */
import wx from "weixin-js-sdk";
import { Dialog, Toast } from "vant";
import { removeToken, removePersisternStorage } from "@/utils/localStorage";
import { mapState } from "vuex";
import IndexTabbar from "@/components/common/index-tabbar/index.vue";
import { ISSHILISHOU } from "@/utils/common.js";
import { getRouteNameByLevel, SHILISHOU_LEVEL_MAP } from "@/constants/rebate";
import { TeamMages, Tools } from "./config";

export default {
    name: "personal-center",

    components: {
        IndexTabbar,
    },

    data() {
        return {
            showManage: false,   //是否有查看管理员的权限
            // countStates: {}   //  含有数目的订单状态
        };
    },

    computed: {
        ...mapState({
            user: (state) => state.user.user,
            userId: (state) => state.user.user.id,
            brandId: (state) => state.global.brandId,
            permission: (state) => state.permission.permission["personal-center"],
            userLevel: (state) => (state.user.user.levelall && state.user.user.levelall[0]) || {},
            params: state => state.params.paramsList,
        }),

        orderStates() {
            // const { countStates } = this
            const statesWithPermission = this.permission["my-order"].contents;
            const oStates = [
                {
                    key: "waiting-delivery",
                    name: "待发货",
                    value: 3,
                    icon: require("@/assets/images/profile/sending.png"),
                    count: "",
                },
                {
                    key: "delivered",
                    name: "待收货",
                    value: 1,
                    icon: require("@/assets/images/profile/receiving.png"),
                    count: "",
                },
                {
                    key: "refused",
                    name: "已拒绝",
                    value: 9,
                    icon: require("@/assets/images/profile/rejected.png"),
                    count: "",
                },
            ];

            let states = oStates.filter(
                (item) => statesWithPermission[item.key] === true
            );

            return states;
        },

        //  发货订单
        deliveryOrderStates() {
            const oStates = [
                {
                    key: "waiting-delivery",
                    name: "待发货",
                    value: 0,
                    icon: require("@/assets/images/profile/sending.png"),
                    count: "",
                },
                {
                    key: "delivered",
                    name: "待收货",
                    value: 1,
                    icon: require("@/assets/images/profile/receiving.png"),
                    count: "",
                },
            ];
            return oStates;
        },

        //  营销订单
        retailOrderStates() {
            const oStates = [
                {
                    key: "marketing",
                    name: "营销单",
                    value: 0,
                    icon: require("@/assets/images/profile/marketing.png"),
                    count: "",
                },
                {
                    key: "aftermarket",
                    name: "售后单",
                    value: 1,
                    icon: require("@/assets/images/profile/aftermarket.png"),
                    count: "",
                },
            ];
            return oStates;
        },

        teamMages() {
            const { params } = this;
            const toolswithPermission = this.permission["my-tool"].contents;
            params.map(param => {
                if (param.value == 0) {
                    TeamMages.map(d => {
                        if (param.name === d.name) {
                            d.to = '';
                            d.errormsg = '该功能暂未开放~';
                        }
                    })
                }
            })
            let data = [...TeamMages];
            return data;
            // return tools.filter(item => item.key && toolswithPermission[item.key] === true)
        },

        tools() {
            const { params } = this;
            // const stocksWithPermission = this.permission['my-warehouse'].contents;
            params.map(param => {
                if (param.value == 0) {
                    Tools.map(d => {
                        if (param.name === d.name) {
                            d.to = '';
                            d.errormsg = '该功能暂未开放~';
                        }
                    })
                }
            })
            let data = [...Tools];
            return data;
            // return stocks.filter(item => item.key && stocksWithPermission[item.key] == true)
        },
    },

    methods: {
        // 退出登录
        async logout() {
            try {
                await Dialog.confirm({ message: "确认要退出当前账号吗？" });
            } catch (e) {
                return false;
            }
            // await this.$store.dispatch('user/logout')
            removeToken();
            removePersisternStorage();
            location.reload();
            wx.closeWindow();
        },

        // 跳转页面
        toRouter(item) {
            const { userId } = this;
            // 判断是否已经在管做了限制不显示
            if (item.errormsg) {
                Toast(item.errormsg);
                return;
            };
            if (!item.to) {
                return;
            }
            let routeName = item.to;

            // 跳到实力瘦的业绩
            if (routeName === "rebate-sum") {
                //  业绩目前只有实力瘦有，所以在这里做一个判断
                // if (ISSHILISHOU()) {
                //     const { levelId } = this.userLevel;
                //     //  根据当前等级用户的等级，跳转到对应的页面
                //     routeName = getRouteNameByLevel(String(levelId));
                //     if (!routeName) {
                //         let levelName = SHILISHOU_LEVEL_MAP[levelId]
                //             ? SHILISHOU_LEVEL_MAP[levelId]
                //             : "无授权";
                //         Toast(`当前用户等级为${levelName}，暂不支持查看业绩～`);
                //         return;
                //     }
                // } else {
                //     Toast("功能开发中～");
                //     return;
                // }
                const { levelId } = this.userLevel;
                routeName = getRouteNameByLevel(String(levelId));
            }

            this.$router.push({
                name: routeName,
                query: { agentId: userId },
            });
        },

        // 初始化
        async init() {
            await this.$store.dispatch("user/getUserData");
        },

        // 跳转订单列表
        toOrderList(state) {
            this.$router.push({
                name: "order-list",
                query: {
                    state,
                },
            });
        },

        toDeliveryOrderList(state) {
            this.$router.push({
                name: "retail-order-list",
                query: {
                    state,
                },
            });
        },

        toRetailOrderList(state) {
            if (state === 0) {
                this.$router.push({
                    name: "enroll-marketing-order-list"
                });
            } 
            else if (state === 1) {
                this.$router.push({
                    name: "enroll-aftermarket-order-list"
                });
            }
        },

        // 处理我的工具跳转
        handleToolsClick(item) {
            const { user: { id } } = this;
            // 判断是否已经在管做了限制不显示
            if (item.errormsg) {
                Toast(item.errormsg);
                return;
            };
            if (!item.to) {
                return;
            }
            let routeName = item.to;
            let query = item.query;
            if (item.key === 'auth') query = { agentId: id };
            this.$router.push({
                name: routeName,
                query
            });
        },
    },

    async created() {
        await this.init();
    },
};
