import Vue from 'vue'

Vue.directive('noRubberband', {
  bind (el) {
    el.noRubberbandHandler = () => {
      if (el.scrollTop === 0) {
        el.scrollTop = 1
      } else if (el.scrollTop + el.offsetHeight === el.scrollHeight) {
        el.scrollTop = el.scrollTop - 1
      }
    }
    el.addEventListener('touchstart', el.noRubberbandHandler)
  },

  unbind (el) {
    el.removeEventListener('touchstart', el.noRubberbandHandler)
    el.noRubberbandHandler = null
  }
})
