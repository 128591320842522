import { mapState } from 'vuex'
import dayjs from 'dayjs'
import { difference } from 'lodash'
import { Dialog } from 'vant'
import { getDiscountPrice } from '@/utils/common'
import EmptyList from '@/components/common/empty-list/index.vue'

/**
 * （下单时）选择优惠券
 */
export default {
  name: 'use-coupon',

  components: {
    EmptyList
  },

  data () {
    return {
      getDiscountPrice,
      current: 'valid',
      coupons: [],
      selectCoupons: []
    }
  },

  watch: {
    current: 'loadCoupons'
  },

  computed: {
    ...mapState({
      order: state => state.preorder,
      brandId: state => state.global.brandId
    }),
    totalPrice () {
      let money = 0
      this.selectCoupons.forEach(item => {
        money += item.coupon.discount
      })
      return money
    }
  },

  methods: {
    // 初始化
    async init () {
      this.selectCoupons = this.order.coupons || []
      await this.loadCoupons()
    },

    // 获取优惠券
    async loadCoupons () {
      try {
        const selectIds = this.selectCoupons.map(item => item.id)
        const data = await this.$http.post(`orders/coupon/${this.current}`, {
          brandId: this.brandId,
          products: this.order.products.map(item => {
            return {
              id: item.id,
              price: this.getPriceByProduct(item)
            }
          })
        })
        const coupons = data.coupons.map(record => {
          record.selected = selectIds.indexOf(record.id) !== -1
          record.startedAt = dayjs(record.startedAt, 'YYYY-MM-DD HH:mm:ss').format('YYYY.MM.DD HH:mm')
          record.endedAt = dayjs(record.endedAt, 'YYYY-MM-DD HH:mm:ss').format('YYYY.MM.DD HH:mm')
          const message = record.coupon.products.map(item => item.name).join('、')
          record.coupon.productsNames = record.coupon.products.map(i => i.name)
          record.coupon.productsIds = record.coupon.products.map(i => i.id)
          record.coupon.products = `仅限${message}产品使用`
          record.showDetail = false
          return record
        })
        this.coupons = coupons
      } catch (e) {}
    },

    // 重置选择
    _resetSelectedCoupon () {
      for (const item of this.selectCoupons) {
        item.selected = !item.selected
      }
      this.selectCoupons.splice(0, this.selectCoupons.length)
    },

    // 比较适用商品是否一致，双向比较
    _isDiffArray (item, record) {
      if (difference(item, record).length || difference(record, item).length) {
        return true
      }
      return false
    },

    // 反选
    async toggleSelect (record) {
      if (this.current === 'invalid') return
      // 订单中适用优惠券商品总价
      let productTotalPrice = 0
      const filterProducts = this.order.products.filter(i => record.coupon.productsIds.indexOf(i.id) > -1)
      for (const product of filterProducts) {
        productTotalPrice += this.getPriceByProduct(product)
      }
      if (record.selected) {
        const index = this.selectCoupons.findIndex(item => item.id === record.id)
        if (index !== -1) {
          this.selectCoupons.splice(index, 1)
        }
      } else {
        // 1.检查优惠券适用范围是否相同
        let flag = false
        for (const item of this.selectCoupons) {
          // 奖券适用商品不一致
          // if (item && item.coupon.products !== record.coupon.products) {
          if (item && this._isDiffArray(item.coupon.productsNames, record.coupon.productsNames)) {
            flag = true
          }
        }
        if (flag) {
          this._resetSelectedCoupon()
        }
        // 2.检查优惠券叠加类型是否相同
        let typeFlag = false
        const couponType = this.selectCoupons.map(i => i.coupon.type)
        if (couponType) {
          couponType.map(item => {
            if (item !== record.coupon.type) {
              typeFlag = true
            }
          })
        }
        if (typeFlag) {
          this._resetSelectedCoupon()
          this.selectCoupons.push(record)
        }
        // 2.1 检查单张适用
        if (record.coupon.type === 0) {
          if (this.totalPrice + record.coupon.discount > productTotalPrice) {
            await Dialog.alert({ message: '所选抵用券总金额不能超过订单中适用商品总金额' })
            return false
          }
          if (this.totalPrice + record.coupon.discount > this.order.totalPrice) {
            await Dialog.alert({ message: '现金券总额不能超过订单总额' })
            return false
          }
          // 清空之前选中的优惠券
          this._resetSelectedCoupon()
          this.selectCoupons.push(record)
        } else if (record.coupon.type === 1) {
          // 2.2 检查单种叠加
          if (this.totalPrice + record.coupon.discount > productTotalPrice) {
            await Dialog.alert({ message: '所选抵用券总金额不能超过订单中适用商品总金额' })
            return false
          }
          if (this.totalPrice + record.coupon.discount > this.order.totalPrice) {
            await Dialog.alert({ message: '现金券总额不能超过订单总额' })
            return false
          }
          const couponCount = this.selectCoupons.filter(i => {
            return i.couponId === record.couponId
          }).length
          if (couponCount >= record.coupon.overlay) {
            await Dialog.alert({ message: `所选现金券不得超过最大叠加数${record.coupon.overlay}张` })
            return false
          }
          this.selectCoupons.push(record)
        } else {
          // 2.3 检查混合叠加
          if (this.totalPrice + record.coupon.discount > productTotalPrice) {
            await Dialog.alert({ message: '所选抵用券总金额不能超过订单中适用商品总金额' })
            return false
          }
          if (this.totalPrice + record.coupon.discount > this.order.totalPrice) {
            await Dialog.alert({ message: '现金券总额不能超过订单总额' })
            return false
          }
          let maxOverlay = 0
          const overlayArr = this.selectCoupons.map(i => i.coupon.overlay)
          overlayArr.push(record.coupon.overlay)
          maxOverlay = overlayArr.reduce((a, b) => {
            return a > b ? a : b
          })
          if (this.selectCoupons.length >= maxOverlay) {
            await Dialog.alert({ message: `所选现金券不得超过最大叠加数${maxOverlay}张` })
            return false
          }
          this.selectCoupons.push(record)
        }
      }
      record.selected = !record.selected
    },

    // 提交
    submit () {
      this.$store.commit('preorder/setField', {
        coupons: this.selectCoupons
      })
      this.$router.go(-1)
    },

    // 获取每种 product 总价
    getPriceByProduct (product) {
      return product.formats.reduce((result, item) => result + getDiscountPrice(product.discount, product.userRank, item.price) * item.count, 0)
    },

  },

  async created () {
    await this.init()
  }
}
