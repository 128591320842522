import { REBATE_ID } from './rebate'

// 品牌ID（正式服）
export const BRAND = {
  SGS: '15215b36-60b0-4491-b8f5-017a2cca6958', // 水光生
  T: 'de054241-4999-11e7-9381-edd722a8af8d', // 汀
  BX: 'de054240-4999-11e7-9381-edd722a8af8d', // 白皙
  UNDERWEAR: '8a88f325-70d1-4fc7-b882-6439bc76e629', // 能量内衣
}

export const BRAND_NAME_MAP = {
  [BRAND.SGS]: "水光生",
  [BRAND.T]: "汀",
  [BRAND.UNDERWEAR]: "能量内衣",
  [BRAND.BX]: "白皙",
}

// 根据品牌id设置是哪个rebateId
export const getRebateId = brandId => {
  if (brandId === BRAND.SGS) {
    return REBATE_ID.SHUI_GUANG_SHENG
  }

  if (brandId === BRAND.BX) {
    return REBATE_ID.BAI_XI
  }
}